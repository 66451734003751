import {
  CategoryOutlined,
  FindInPageRounded,
  InventoryOutlined,
  NoAccountsRounded,
  PersonAddAlt1Rounded,
  SettingsOutlined,
  RecentActors,
  Security,
  Groups,
  PermMedia,
  QueryStats,
  Assistant,
  Business,
  Dashboard,
  People,
  LibraryBooksOutlined,
  ThreeP,
  AccountCircle,
  Event,
  Search,
  Notifications,
  Email,
  Update,
  Inventory,
  ShoppingCart,
  BarChart,
  Group,
  PriceChangeOutlined,
  SmartToyOutlined
} from '@mui/icons-material';

import { getTimeZoneFromUser } from '../utils/timeZoneParser';
import { FormState, NavSections, Caracteristicas } from '../interfaces/interfaces';
import type { MesesKeys } from '../types/types';
import { CSSProperties } from 'react';

const timeZoneData = getTimeZoneFromUser();

export const USR_PLAN = {
  FREETRIAL : 'FREETRIAL',
  MENSUAL : 'MENSUAL',
  QUINCENAL : 'QUINCENAL',
  BIMESTRAL : 'BIMESTRAL',
  SEMANAL : 'SEMANAL',
  CUATRIMESTRAL : 'CUATRIMESTRAL',
  SEMESTRAL : 'SEMESTRAL',
  ANUAL : 'ANUAL'
}

export const fieldsSuperAdmin = {
  nombreEmpresa: '',
  email: '',
  phoneNumber: '',
  direccion: '',
  fechaNacimiento: '',
  timeZone: {
    identifier: timeZoneData.userTimeZone,
    offset: timeZoneData.userOffset
  },
}

export const fieldsAdmin = {
  nombre: '',
  apellidoPaterno: '',
  apellidoMaterno: '',
  email: '',
  phoneNumber: '',
  direccion: '',
  salarioMensual: '',
  fechaNacimiento: '',
  timeZone: {
    identifier: timeZoneData.userTimeZone,
    offset: timeZoneData.userOffset
  },
}

export const initialFormConfig: Record<string, FormState> = {
  "ADMIN": fieldsAdmin,
  "EXPERTO_EN_EL_AREA": fieldsAdmin,
  "SUPER_ADMIN": fieldsSuperAdmin,
  "MANAGEMENT-SERVICE-ROLE": fieldsSuperAdmin
};

export const adminMainSections: NavSections[] = [
  {
    title: 'Dashboard',
    icon: Dashboard,
    path: '/dashboard',
  },
  {
    title: 'Eventos',
    icon: LibraryBooksOutlined,
    path: '/user/eventos',
  },
  {
    title: 'Clientes',
    icon: People,
    path: '/user/clientes',
  },
  {
    title: 'Inactivos',
    icon: NoAccountsRounded,
    path: '/user/inactivos',
  },
  {
    title: 'Buscar',
    icon: FindInPageRounded,
    path: '/search',
  },
  {
    title: 'Registro',
    icon: PersonAddAlt1Rounded,
    path: '/user/registro',
  },
  {
    title: 'Empresa',
    icon: Business,
    path: '/configuracion',
    subSection: [
      /*{
        title: 'Contratos',
        icon: RecentActors,
        path: '/contratos',
      },*/
      {
        title: 'Definición de precios',
        icon: PriceChangeOutlined,
        path: '/priceDefinitions'
      }/*,
      {
        title: 'Alertas de seguridad',
        icon: Security,
        path: '/security',
      },
      {
        title: 'General',
        icon: ThreeP,
        path: '/general'
      },
      {
        title: 'Grupo de empleados',
        icon: Groups,
        path: '/employees'
      },
      {
        title: 'Asistente artificial',
        icon: Assistant,
        path: '/assistant'
      }*/,
      {
        title: 'Analitica',
        icon: SmartToyOutlined,
        path: '/analitica'
      }
    ]
  }
]

export const secondSections: NavSections[] = [
  {
    title: 'Categorias',
    icon: CategoryOutlined,
    path: '/user/categorias',
  },
  {
    title: 'Productos',
    icon: InventoryOutlined,
    path: '/user/productos',
  },
  {
    title: 'Configuraciones',
    icon: SettingsOutlined,
    path: '/user/configuraciones',
  }
]

export const superAdminMainSections: NavSections[] = [
  {
    title: 'Dashboard',
    icon: Dashboard,
    path: '/dashboard',
  },
  {
    title: 'Eventos',
    icon: LibraryBooksOutlined,
    path: '/user/eventos',
  },
  {
    title: 'Usuarios',
    icon: People,
    path: '/user/clientes',
  },
  {
    title: 'Inactivos',
    icon: NoAccountsRounded,
    path: '/user/inactivos',
  },
  {
    title: 'Buscar',
    icon: FindInPageRounded,
    path: '/search',
  },
  {
    title: 'Registro',
    icon: PersonAddAlt1Rounded,
    path: '/user/registro',
  },
  {
    title: 'Mi empresa',
    icon: Business,
    path: '/configuracion',
    subSection: [      
      {
        title: 'Definición de precios',
        icon: PriceChangeOutlined,
        path: '/priceDefinitions'
      },
      {
        title: 'Analitica',
        icon: SmartToyOutlined,
        path: '/analitica'
      }
      /*{
        title: 'Contratos',
        icon: RecentActors,
        path: '/contratos',
      },
      {
        title: 'Alertas de seguridad',
        icon: Security,
        path: '/security',
      },
      {
        title: 'General',
        icon: ThreeP,
        path: '/general'
      },
      {
        title: 'Grupo de empleados',
        icon: Groups,
        path: '/employees'
      },
      {
        title: 'Asistente artificial',
        icon: Assistant,
        path: '/assistant'
      }*/
    ]
  }
]

export const expertoMainSections: NavSections[] = [
  {
    title: 'Dashboard',
    icon: Dashboard,
    path: '/dashboard',
  },
  {
    title: 'Eventos',
    icon: LibraryBooksOutlined,
    path: '/user/eventos',
  },
  {
    title: 'Clientes',
    icon: People,
    path: '/user/clientes',
  },
  {
    title: 'Analítica de progreso',
    icon: QueryStats,
    path: '/analytics',
  },
  {
    title: 'Multimedia',
    icon: PermMedia,
    path: '/multimedia'
  }
]

export const numeroAMes: Record<MesesKeys, string> = {
  '1': 'Enero',
  '2': 'Febrero',
  '3': 'Marzo',
  '4': 'Abril',
  '5': 'Mayo',
  '6': 'Junio',
  '7': 'Julio',
  '8': 'Agosto',
  '9': 'Septiembre',
  '10': 'Octubre',
  '11': 'Noviembre',
  '12': 'Diciembre'
};

export const caracteristicas: Caracteristicas[] = [
  {
    title: 'Creación de usuarios ilimitada',
    icon: AccountCircle,
    description: 'Crea tantos usuarios como necesites, sin restricciones.'
  },
  {
    title: 'Registro ilimitado de sesiones o eventos',
    icon: Event,
    description: 'No te pierdas ni un solo detalle con la capacidad de registrar eventos sin límites.'
  },
  {
    title: 'Búsqueda múltiple en tu negocio',
    icon: Search,
    description: 'Encuentra lo que necesitas al instante con nuestro potente servicio de búsqueda.'
  },
  {
    title: 'Notificaciones en tiempo real',
    icon: Notifications,
    description: 'Mantente al tanto de todo lo que sucede en tu negocio con notificaciones instantáneas en el sistema.'
  },
  {
    title: 'Notificaciones de planes vía email',
    icon: Email,
    description: 'Aumenta la fidelización de tus clientes manteniéndolos informados con notificaciones de planes directamente en sus correos electrónicos.'
  },
  {
    title: 'Actualización automática de planes',
    icon: Update,
    description: 'Olvídate de los problemas de pagos pasados con nuestras actualizaciones automáticas de planes.'
  },
  {
    title: 'Control de inventario granular',
    icon: Inventory,
    description: 'Gestiona tu inventario de manera eficiente y precisa, todo desde un solo lugar.'
  },
  {
    title: 'Venta de productos y servicios',
    icon: ShoppingCart,
    description: 'Capitaliza al máximo tus productos y servicios con nuestra plataforma integrada de ventas.'
  },
  {
    title: 'Análisis de ventas avanzado',
    icon: BarChart,
    description: 'Toma decisiones informadas con datos precisos y reportes de ventas detallados.'
  },
  {
    title: 'Acceso multiusuario',
    icon: Group,
    description: 'Mide el rendimiento de tu equipo y mejora la fidelización de tus clientes con acceso multiusuario.'
  }
]

export const formContainerStyles : {[key : string] : string | number } = {
  display: 'flex', 
  flexWrap: 'wrap', 
  flexDirection: 'column', 
  width: '100%'
}

export const basicColorsPalette : {[key : string] : string | number }= {
  fontFamily : 'Montserrat',
  color      : '#1976D2',
  tableHeafontWeight : 500,
  fontSize : 14,
  subTitle : 16,
  revenueColor : 'rgb(0, 255, 127)',
  costColor : 'rgb(139, 0, 0)',
  colorChanged : '#66bb6a',
  colorNotChanged : '#888',
  beforeColor : '#ef5350',
  titleSize : 22,
  redColor : '#FF6B6B',
  weigth : 600,
  highlightColor : '#FF6B6B',
  blueColor : '#1976D2'
}

export const formsStyles : {[key : string] : string | number | {} } = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 'auto',
    sm: 700,   
  },
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
}

export const textFieldStyle : {[key : string] : string | number } = {
  fontFamily : 'Montserrat',
  marginBottom: '20px',
  marginTop: '10px',
  width: '100%',
};

export const btnGralStyles : CSSProperties= {
  fontFamily : 'Montserrat',
  textTransform: 'none',
  //backgroundColor : 'white',
  //border: '1px solid black',
  //boxShadow : 'none',
  //color : 'black',
  marginBottom: '20px',
  marginTop: '10px',
  width: '100%'
};
export const selectStyle = {
  marginBottom: '20px',
  marginTop: '40px'
};

export const membershipDescription = {
  'MEMBRESIA_GENERAL': 'Aplicable a todos los planes generales como Mensual, Semestral y Anual. Úsala para planes sin membresía específica.',
  'MEMBRESIA_QUINCENAL': 'Diseñada para pagos quincenales, ideal para quienes prefieren pagar cada dos semanas.',
  'MEMBRESIA_SEMANAL': 'Diseñada para pagos semanales, ideal para quienes prefieren pagar cada semana.',
};
