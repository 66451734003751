import React, { useEffect } from 'react';
import { config } from './config';

export const Chatwoot: React.FC = () => {
  useEffect(() => {
    const BASE_URL: string = config?.REACT_APP_CHAT_WOOT as string;
    const loadChatwootSDK = (d: Document, t: string) => {
      const g: HTMLScriptElement = d.createElement(t);
      const s: HTMLScriptElement | null = d.getElementsByTagName(t)[0];

      g.src = `${BASE_URL}/packs/js/sdk.js`;
      g.defer = true;
      g.async = true;
      s?.parentNode?.insertBefore(g, s);

      g.onload = () => {
        (window as any).chatwootSDK.run({
          websiteToken: config?.REACT_APP_CHAT_WOOT_CHAT_TOKEN,
          baseUrl: BASE_URL
        });
      };
    };

    loadChatwootSDK(document, "script");

    return () => {
      const script = document.querySelector<HTMLScriptElement>(`script[src="${BASE_URL}/packs/js/sdk.js"]`);
      if (script) {
        script.remove();
      }
    };
  }, []);

  return null;
};
