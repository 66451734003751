import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Categorias } from '../components/Categorias'
import { AnalysisTable } from '../analitica/components/AnalysisTable';





const rolesHash : {[key:string] : string}= {
    admin : 'ADMIN',
    super_admin : "SUPER_ADMIN",
    coatch : "EXPERTO_EN_EL_AREA",
    maintenance : "MANAGEMENT-SERVICE-ROLE"
}
/*const bussinessType = {
    gym
} */

export const Analitica = () => {
  
  //const roles = clientUsers.usuarios.map((user) => user.role);
  const role = useSelector((state: RootState) => state.auth.role);
  if (role === rolesHash['admin'] || role === rolesHash['super_admin']) {
    return <AnalysisTable/>;
  } else if (role === rolesHash.maintenance ) {
    return 'Se hará algo bueno para este rol más adelante';
  } else {
    return <div>No se ha seleccionado un rol válido.</div>;
  }
}