import {  useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { FormState, GET_PRODUCT_BY_ID } from "../interfaces/interfaces";


export const usePromotion = () => {
    const productById = useSelector((state: RootState) => state?.productById?.producto);
    const inscription = useSelector((state: RootState) => state?.getInscriptionRecords?.inscriptions);
    const inscriptionPrice = inscription?.[0];
    
    const [amount, setAmount] = useState(0);
    const [pay, setPay] = useState('');
    const [totalConDescuento, setTotalConDescuento] = useState<number>(0);
    const [totalSinDescuento, setTotalSinDescuento] = useState<number>(0);
    const [discountedUnitPrice, setDiscountedUnitPrice] = useState<number>(productById?.precio || 0);

    const [tmpPagoInscripcion, setTmpPagoInscripcion] = useState( inscriptionPrice?.price );
    const [tmpPlanMonto, setTmpPlanMonto] = useState(0);
    
    const applypromotionHandler = (productById: GET_PRODUCT_BY_ID, quantity: number, promotionId: string, promotionsList: any[], applyPromotion: boolean) => {
        let unitPrice = productById?.precio;
        let discount = 0;
        const calculatedAmount = Number(quantity) * unitPrice;
        setAmount(calculatedAmount);
        if (applyPromotion && promotionId) {
            const selectedPromotion = promotionsList?.find((promo) => promo?._id === promotionId);
            if (selectedPromotion && typeof selectedPromotion?.discount === 'number') {
                setTotalSinDescuento( Number(quantity) * unitPrice );
                discount = selectedPromotion?.discount;
                unitPrice = productById?.precio * (1 - discount / 100);        
                setAmount( unitPrice * Number(quantity) )
            }
        }
        setDiscountedUnitPrice(unitPrice);
    };

    const applyPromotionOnUserRegistration = (applyPromotion: boolean, promotion: any, promotions: any[], pagoDeInscripcion: number, planMensualMonto: number) => {
        if (applyPromotion && promotion) {
            const selectedPromotion = promotions.find((promo) => promo._id === promotion);
            if (selectedPromotion && typeof selectedPromotion.discount === 'number') {
                const discount = selectedPromotion?.discount;
                const pagoDeInscripcionConDescuento = parseFloat(String(pagoDeInscripcion) || '0') * (1 - discount / 100);
                const planMensualMontoConDescuento = parseFloat(String(planMensualMonto) || '0') * (1 - discount / 100);
                const totalWithDiscount = pagoDeInscripcionConDescuento + planMensualMontoConDescuento;
                setTmpPagoInscripcion(pagoDeInscripcionConDescuento);
                setTmpPlanMonto(planMensualMontoConDescuento);
                setTotalConDescuento(totalWithDiscount);
            }
        } else {
            setTotalConDescuento(parseFloat(String(pagoDeInscripcion) || '0') + parseFloat(String(planMensualMonto) || '0'));
            setTmpPagoInscripcion(pagoDeInscripcion);
            setTmpPlanMonto(planMensualMonto);
        }
    };


    const handlerSetPromotion = (event: any, promotions: any[] , setFormState : React.Dispatch<React.SetStateAction<FormState>>) => {        
        const selectedPromotion = promotions.find((promotion) => promotion?._id === event);        
        if (selectedPromotion) {
            setFormState((prevState) => ({
                ...prevState,
                discountCaptured: selectedPromotion?.discount as number,
                promotionStatus: selectedPromotion?.status as boolean,
                promotion: selectedPromotion?._id as string,
            }));
        }
    };


    return {
        applypromotionHandler,
        setPay,
        applyPromotionOnUserRegistration,
        handlerSetPromotion,
        discountedUnitPrice,
        totalConDescuento,
        totalSinDescuento,
        amount,
        pay,
        tmpPagoInscripcion,
        tmpPlanMonto
    };
};

