export const formatText = (texto: string): string => {
  // Convierte el texto a minúsculas y reemplaza guiones bajos con espacios
  let textoFormateado = texto.toLowerCase().replace(/_/g, ' ');

  // Convierte la primera letra de cada palabra a mayúscula
  textoFormateado = textoFormateado.split(' ').map(palabra => {
    return palabra.charAt(0).toUpperCase() + palabra.slice(1);
  }).join(' ');

  return textoFormateado;
}