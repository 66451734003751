import React, { useState, ChangeEvent, useEffect } from 'react';
import Logo from '../img/LOGO.png';
import userImage from '../img/usuario 2.svg';
import candado from '../img/candado.svg';
import emailImage from '../img/email.svg';
import licenciaImage from '../img/licencia.svg';
import ojoClosed from '../img/ojoClosed.png';
import ojoOpen from '../img/ojoOpen.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { renewTenantPlanThunk } from '../store/usuarios';
import { useForm } from '../customhooks';
import { Notifications } from '../atoms/Notifications';
import { StripePaymentCard } from './StripePaymentCard';
import { useNavigate } from 'react-router-dom';

export const RenewPlan = () => {
  const [passwordInputType, setPasswordInputType] = useState('password');
  const dispatch = useDispatch<AppDispatch>();
  const tenantPay = useSelector((state: RootState) => state.tenantPay);
  const renewTenantPlan = useSelector((state: RootState) => state.renewTenantPlan);
  const navigate = useNavigate();

  const { onResetForm , formState , onInputChange} = useForm({
    email: '',
    contrasena: '',
    planMensualTipo: 'MENSUAL',
    licencia:'BASICO',
  })

  const handleRenewPlan = async () => {
    const success = await dispatch(renewTenantPlanThunk(formState));
    if (success?.ok === true) {
      onResetForm();
    }
  }

  useEffect(()=>{
    if(renewTenantPlan?.status === "success"){
      setTimeout(()=>{
        navigate("/login");
      },2500)
    }
  },[renewTenantPlan]);

  return (
    <div className="w-full p-10 bg-white" style={{ height: '100%' }}>
      <div className='flex flex-col justify-between mt-5 w-8/12 sm:w-full md:w-full mx-auto'>
        <div className='bg-mainBlue w-2/12 p-3'>
          <a href="/">
            <img src={Logo} alt="" className='w-full' />
          </a>
        </div>
        <div className='w-full mt-5 '>
          <p className='font-bold text-5xl text-mainBlue'>Renueva tu plan</p>
        </div>
        <div className='mt-8 w-full md:w-full sm:w-full'>
          <p className='text-mainBlue font-bold text-xl'>Datos personales</p>
          <div className='flex flex-col mt-2 md:w-full'>
            <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
              <img src={emailImage} alt="" className='mr-2 w-6 h-6' />
              <input
                className='w-full bg-transparent outline-0 placeholder-italic placeholder-text-sm text-lightGray'
                type="email"
                placeholder='Dirección de correo'
                name="email"
                value={formState?.email as string}
                onChange={onInputChange}
                required
              />
            </div>
            <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
              <img src={candado} alt="" className='mr-2 w-6 h-6' />
              <input
                className='w-full bg-transparent outline-0 placeholder-italic placeholder-text-sm text-lightGray'
                type={passwordInputType}
                placeholder='Contraseña'
                name="contrasena"
                value={formState?.contrasena as string}
                onChange={onInputChange}
                required
              />
              <img onClick={() => {
                setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
              }} src={passwordInputType === 'password' ? ojoClosed : ojoOpen} alt="" className='w-6' />
            </div>
            <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
              <img src={licenciaImage} alt="" className='mr-2 w-6 h-6' />
              <select id="licencia" name="licencia" value={formState?.licencia as string} onChange={onInputChange} className='w-full bg-transparent text-lightGray outline-0 placeholder:italic placeholder:text-sm bg-white'>
                <option value="BASICO" className='text-lightGray'>Básico</option>
                {/* <option value="ESENCIAL" className='text-lightGray'>Esencial</option>
                <option value="ELITE" className='text-lightGray'>Elite</option> */}
              </select>
            </div>
            <div className='flex items-center border-b border-lightBlue pb-1 mb-6'>
              <img src={licenciaImage} alt="" className='mr-2 w-6 h-6' />
              <select onChange={onInputChange} value={formState?.planMensualTipo as string} id="licencia" name="planMensualTipo" className='w-full bg-transparent text-lightGray outline-0 placeholder-italic placeholder-text-sm bg-white'>
                <option value="MENSUAL" className='text-lightGray'>Mensual</option>
                <option value="SEMESTRAL" className='text-lightGray'>Semestral</option>
                <option value="ANUAL" className='text-lightGray'>Anual</option>
              </select>
            </div>
            {/* datos bancarios de stripe */}
            <StripePaymentCard 
              custumerEmail={ formState?.email as string }
              licencia={formState?.licencia as string }
              planMensualTipo={formState?.planMensualTipo as string }
              nombreEmpresa={formState?.nombreEmpresa as string }
              password={formState?.contrasena as string }
              nuevoRegistro={false}
            />
          </div>
        </div>
        <button className='bg-mainBlue px-6 py-2 rounded-sm self-end disabled:opacity-75' onClick={handleRenewPlan} disabled={tenantPay?.status !== 'success'}>
          <p className='text-white font-bold text-center'>Renovar</p>
        </button>
      </div>

      <Notifications handler={tenantPay} actionType='tenantPay' actionName='pay'/>

      <Notifications handler={renewTenantPlan} actionType='renewTenantPlan' actionName='renew'/>

    </div>
  );
};
