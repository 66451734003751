import { TOOL_ITEM } from "../interfaces/interfaces";

export const HomeToolsItem = ({ image, title, text }: TOOL_ITEM) => {
  return (
    <div className="flex flex-col items-center w-full mb-6 sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12">
      <div className="w-32 h-32 mb-2 bg-mainBlue rounded-full flex justify-center items-center shadow-xl">
        <img className="w-2/3 h-2/3 object-contain" src={image} alt="" />
      </div>
      <p className="text-center mt-2 font-semibold text-mainBlue text-lg">{title}</p>
      <p className="text-center mt-2 font-light w-10/12 text-lightGray text-base leading-relaxed">{text}</p>
    </div>
  );
};
