import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../customhooks";
import { closeModal } from "../../store/modal";
import { AppDispatch, RootState } from "../../store";
import { FormEvent } from "react";
import Title from "../../atoms/Title";
import { Box, Button, Modal, TextField } from "@mui/material";
import { MODAL_INSCRIPTION_PAYMENT_PROPS } from "../../interfaces/interfaces";
import { formsStyles } from "../../constants/ConstantsFlexSolutionManager";
import { createTenantInscriptionPriceThunk, updateTenantInscriptionThunk } from "../../store/usuarios";

export const InscriptionModal = ({ modalType, action, idEdit }: MODAL_INSCRIPTION_PAYMENT_PROPS) => {
    const dispatch = useDispatch<AppDispatch>();

    const open = useSelector((state: RootState) => state?.modal.openModal);
    const modalTypeStore = useSelector((state: RootState) => state?.modal?.modalType);
    const inscription = useSelector((state: RootState) => state?.getInscriptionRecords?.inscriptions);
    const inscriptionToEdit = inscription?.find((inscription: any) => inscription?._id === idEdit);
    
    const { onInputChange, formState, onResetForm } = useForm({
        price: inscriptionToEdit?.price || 0
    });
    
    const handleClose = () => {
        dispatch(closeModal());
        onResetForm();
    }


    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if( inscriptionToEdit && idEdit){
            const editInscription = await dispatch(updateTenantInscriptionThunk(formState));
            if (editInscription?.ok === true) {
                onResetForm();
                dispatch(closeModal());
            } 
        }else{
            const useToResetForm = await dispatch(createTenantInscriptionPriceThunk(formState));
            if (useToResetForm?.ok === true) {
                onResetForm();
                dispatch(closeModal());
            }
        }
    }

    return (
        <>
            {
                modalType === modalTypeStore && (
                    <Modal open={open} onClose={handleClose} aria-labelledby="Crear promoción" aria-describedby="crea una promoción nueva">
                        <form onSubmit={handleSubmit}>
                            <Box sx={formsStyles}>
                                <Title>Definir precio de inscripción</Title>

                                <TextField
                                    label="Precio"
                                    name="price"
                                    value={formState?.price}
                                    onChange={onInputChange}
                                    fullWidth
                                    margin="normal"
                                    required
                                    type="number"
                                    inputProps={{ min: 0 }}
                                />


                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={handleClose}
                                    >
                                        Cancelar
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        {action}
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Modal>
                )
            }
        </>
    )

}