import { Link } from 'react-router-dom';

import { caracteristicas } from '../constants/ConstantsFlexSolutionManager';

export const FreeTrial = () => {
  return (
    <div className='flex flex-col justify-center items-center gap-5'>
      <div className='mt-10 w-full text-center flex flex-col items-center'>
        <p className='text-mainBlue font-bold text-4xl sm:text-2xl'>¿Aún no te convences?</p>
        <p className='w-5/12 text-center text-2xl text-lightGray mt-5 sm:w-full sm:text-lg md:w-full md:text-xl'>
          ¡Descubre el poder de nuestro servicio con nuestra prueba gratuita de 20 días!
        </p>
      </div>

      <div className="grid gap-4 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 grid-cols-3">
        {
          caracteristicas.map((caracteristica, index) => (
            <div key={ index } className={`bg-white shadow-md text-center rounded-lg p-4 flex flex-col items-center justify-center gap-2 ${index === caracteristicas.length - 1 && 'sm:col-start-1 col-start-2'}`}>
              <div className="bg-gray-100 dark:bg-gray-800 rounded-full p-3">
                <caracteristica.icon className="h-5 w-5 text-mainBlue" />
              </div>
              <strong>{caracteristica.title}</strong>
              <p>{caracteristica.description}</p>
            </div>
          ))
        }
      </div>

      <Link to='/freetrial'>
        <button className='bg-mainBlue px-10 py-2 mt-5 text-white font-bold hover:bg-blue-700 transition duration-300 sm:w-60 w-96'>
          Prueba gratuita
        </button>
      </Link>

    </div>
  )
}