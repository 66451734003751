import { createSlice } from "@reduxjs/toolkit"

export const createPromotionSlice = createSlice({
    name: "createCustomPromotion",
    initialState: {
        status: "initial",
        msg: null,
        promotion: null,
        BackendErrorMessage: null,
    },
    reducers: {
        failedCreatePromotion: (state, { payload }) => {
            state.status = "failed"
            state.promotion = null
            state.BackendErrorMessage = payload
        },
        createPromotion: (state, { payload }) => {
            state.status = "success"
            state.msg = payload.msg
            state.promotion = payload.promotion
            state.BackendErrorMessage = null
        },
        creatingPromotion: (state) => {
            state.status = "in-progress"
        },
    },
})

export const { failedCreatePromotion, createPromotion, creatingPromotion } = createPromotionSlice.actions