import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { closeModal } from "../store/modal";

export const useHandlerModal =()=>{
    const dispatch = useDispatch<AppDispatch>();

    const handleClose = () => {
        dispatch(closeModal());
    }

    return {
        handleClose
    }
}