import React, { FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Modal,
  TextField,
  Button,
} from '@mui/material';
import { closeModal } from '../store/modal';
import { AppDispatch, RootState } from '../store';
import { useForm } from '../customhooks';
import Title from '../atoms/Title';
import { editPromotionThunk } from '../store/usuarios';

interface CustomPromotionEditModalProps {
  modalType: string;
  idEdit: string;
}

const style = {
  gap: 2,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 'auto',
    sm: 800,   
  },
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

export const CustomPromotionEditModal = ({ modalType, idEdit }: CustomPromotionEditModalProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const open = useSelector((state: RootState) => state?.modal.openModal);
  const modalTypeStore = useSelector((state: RootState) => state?.modal.modalType);
  const promotions = useSelector((state: RootState) => state?.getPromotion?.promotions);

  const promotionToEdit = promotions.find((promotion: any) => promotion._id === idEdit);

  const { onInputChange, formState, onResetForm, setFormState } = useForm({
    name: '',
    discount: '',
    startDay: '',
    expirationDate: '',
  });

  useEffect(() => {
    if (promotionToEdit) {
      setFormState({
        name: promotionToEdit?.name as string,
        discount: promotionToEdit?.discount as number,
        startDay: promotionToEdit?.startDay ? promotionToEdit.startDay.split('T')[0] : '',
        expirationDate: promotionToEdit?.expirationDate ? promotionToEdit?.expirationDate.split('T')[0] : '',
      });
    }
  }, [promotionToEdit, setFormState]);

  const handleClose = () => {
    dispatch(closeModal());
    onResetForm();
  }

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      onInputChange(e);
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if(promotionToEdit?.name === formState?.name){
      delete formState?.name
    }
    const updatePromtion = {
        ...formState
    }
    
    const useToResetForm = await dispatch(editPromotionThunk(updatePromtion, idEdit))

    if (useToResetForm?.ok === true) {
      onResetForm();
      dispatch(closeModal());
    }
  }
  
  return (
    <>
      {
        modalType === modalTypeStore && (
          <Modal open={ open } onClose={ handleClose } aria-labelledby="Editar promoción" aria-describedby="edita una promoción existente">
            <form onSubmit={ handleSubmit }>
              <Box sx={ style }>
                <Title>Editar Promoción</Title>

                <TextField
                  label="Nombre"
                  name="name"
                  value={ formState?.name }
                  onChange={ onInputChange }
                  fullWidth
                  margin="normal"
                  required
                />

                <TextField
                  label="Descuento"
                  name="discount"
                  value={ formState?.discount }
                  onChange={ handleDiscountChange }
                  fullWidth
                  margin="normal"
                  required
                  type="number"
                  inputProps={ { min: 0 } }
                />

                <TextField
                  label="Fecha de Inicio"
                  type="date"
                  name="startDay"
                  value={ formState?.startDay }
                  onChange={ onInputChange }
                  fullWidth
                  margin="normal"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  label="Fecha de Expiración"
                  type="date"
                  name="expirationDate"
                  value={ formState?.expirationDate }
                  onChange={ onInputChange }
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <Box sx={ { display: 'flex', justifyContent: 'space-between' } }>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={ handleClose }
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Actualizar
                  </Button>
                </Box>
              </Box>
            </form>
          </Modal>
        )
      }
    </>
  )
}
