import { config } from "../config";
import { developmentConfig } from "../developmentConfig";

export const stagingController =()=>{
    try {
        let localhost;
        let production;
        let host = window.location.hostname;
        if(host.startsWith('localhost')|| host.startsWith('127.0.0.1')){
            localhost = developmentConfig?.REACT_APP_DEVELOPMENT_SERVER;
            return {
                localhost 
            }
        }else{
            production = config?.REACT_APP_PRODUCTIONSERVER;
            return{
                production
            }
        }
    }catch(error){
        console.log(`${error}`)
    }
}