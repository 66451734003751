
import React from 'react';

export const useLoadFile = () => {
    const [file, setFile] = React.useState<File | null>(null);
    const [fileType, setFileType] = React.useState<string | null>(null);
    
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files && files.length > 0) {
            const selectedFile = files[0];
            setFile(selectedFile);
            setFileType(selectedFile.type); 
        }
    };

    return {
        file,
        fileType,
        onInputChange
    }
};

