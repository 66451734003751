import { createSlice } from "@reduxjs/toolkit";
import { INSCRIPTION_PAYMENT } from "../../../interfaces/interfaces";

export const getTenantInscriptionPriceSlide = createSlice({
    name: "getInscriptionPrices",
    initialState: {
        status: "initial",
        msg: null,
        inscriptions: [] as INSCRIPTION_PAYMENT[],
        BackendErrorMessage: null,
    },
    reducers: {
    failedGetInscriptions: (state, { payload }) => {
        state.status = "failed";
        state.inscriptions = [];
        state.BackendErrorMessage = payload;
    },
    getAllInscriptions: (state, { payload }) => {
        state.status = "success";
        state.msg = payload.msg;
        state.inscriptions = payload.inscription;
        state.BackendErrorMessage = null;
    },
    gettingInscriptions: (state) => {
        state.status = "in-progress";
    },
    },
});

export const { failedGetInscriptions, getAllInscriptions, gettingInscriptions } = getTenantInscriptionPriceSlide.actions;