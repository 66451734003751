import { createSlice } from "@reduxjs/toolkit";
import { promotionsDefinitions } from "../../../interfaces/interfaces";

export const getPromotionSlice = createSlice({
    name: "getPromotion",
    initialState: {
        status: "initial",
        msg: null,
        promotions: [] as promotionsDefinitions[],
        BackendErrorMessage: null,
    },
    reducers: {
    failedGetPromotions: (state, { payload }) => {
        state.status = "failed";
        state.promotions = [];
        state.BackendErrorMessage = payload;
    },
    getAllPromotions: (state, { payload }) => {
        state.status = "success";
        state.promotions = payload.promotions;
        state.BackendErrorMessage = null;
    },
    gettingPromotions: (state) => {
        state.status = "in-progress";
    },
    },
});

export const { failedGetPromotions, getAllPromotions, gettingPromotions } = getPromotionSlice.actions;