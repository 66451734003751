export const Posting = () => {
    return (
        <div className="flex flex-col md:flex-row mt-20 items-start">
            <div className="md:w-6/12 w-full mb-10 md:mb-0">
                <div className="relative h-0 pb-[56.25%]">
                    <iframe
                        className="absolute top-0 left-0 w-full h-full"
                        src="https://www.youtube.com/embed/qnS75ZJJSBU?si=oWEcIk8ZR5hKgeYR"
                        title="Qué hacemos por tu negocio ?"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
            <div className="md:w-6/12 w-full md:pl-10">
                <article className="w-full">                
                    <p className="text-lightGray font-semibold tracking-wide uppercase text-sm">Promoción especial por tiempo indefinido</p>
                    
                    <p className="text-7xl font-extrabold bg-gradient-to-r from-mainBlue to-blue-500 text-transparent bg-clip-text mt-5 sm:text-6xl md:text-5xl leading-tight">
                        Plan Básico desde <span className="text-green-500">$199 MXN</span> por mes !
                        . Sin pago de inscripción
                    </p>
                </article>
            </div>
        </div>
    );
}