import React from 'react'
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

import { Home } from '../screens/Home';
import { Auth } from '../components/Auth';
import { Signin } from '../components/Signin';
import {Plans} from '../components/Plans';
import { ForgotPassword } from '../components/ForgotPassword';
import { RenewPlan } from '../components/RenewPlan';
import ErrorRouterMessage from '../atoms/ErrorRouteMessage';

// define rutas de la aplicaciòn con createBrowserRouter

export const router = createBrowserRouter([
    {
        path: "/*", 
        element: <Home />,
    },
    {
        path: "/Home",
        element: <Home />,
        errorElement: <ErrorRouterMessage/>
    },
    {
        path : '/login',
        element : <Auth/>
    },
    {
        path: '/registrar',
        element: <Signin/>
    },
    {
        path: '/freetrial',
        element: <Signin />
    },
    {
        path: '/plans',
        element: <Plans/>
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassword/>
    },
    {
        path: '/renewplan',
        element: <RenewPlan/>
    },
    
    // {
    //     path : '/dashboard',
    //     element: <Dashboard/>
    // }
    /*NOTE : 
        dashboard 
        settings
        sign in
    */
    
]); 

  
