import { useDispatch } from "react-redux";
import { useUsersActions } from "../../customhooks/useUsersActions";
import { AppDispatch } from "../../store";
import { openModal } from "../../store/modal";
import { config } from "../../config";
import { Box, Card, CardContent, CardHeader, Typography , IconButton } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Edit as EditIcon } from '@mui/icons-material';
import { INSCRIPTION_CARD_PROPS, INSCRIPTION_PAYMENT } from "../../interfaces/interfaces";

export const InscriptionCard = ({ inscriptionsList , setIdEdit }: INSCRIPTION_CARD_PROPS ) => {
    const dispatch = useDispatch<AppDispatch>();
    const { handleTenantIncriptionPDelete } = useUsersActions();
    
    const handleEdit = (id: string) => {
        if (setIdEdit) setIdEdit(id);
      dispatch({
        type: openModal?.type,
        payload: config?.MODAL_UPDATE_INSCRIPTION_PAYMENT
      })
    }
    const handleDelete = (_id: string) => {
      return handleTenantIncriptionPDelete( _id )
    }
    return (
      <>
        {inscriptionsList ? (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {inscriptionsList?.map(( inscription : INSCRIPTION_PAYMENT ) => {
              return (
                <Card
                  key={ inscription?._id }
                  sx={{
                    width: { xs: '100%', sm: 'calc(50% - 16px)', md: 'calc(33.333% - 16px)' },
                    boxShadow: 1,
                    borderRadius: 2,
                    position: 'relative', 
                  }}
                >
                  <CardContent>
                    <CardHeader
                      title={
                        <Typography
                          variant="h5"
                          color="primary"
                          sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            mb: 2
                          }}
                        >
                          Costo de pago de Inscripción
                        </Typography>
                      }
                      subheader={
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: 'black',
                          }}
                        >
                          { inscription?.price }
                        </Typography>
                      }
                      sx={{ textAlign: 'center' }}
                      action={
                        <>
                          <IconButton
                            onClick={() => handleEdit( inscription?._id as string )}
                            sx={{ color: '#8BC34A' }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDelete( inscription?._id as string)}
                            sx={{ color: '#FF6F6F' }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </>
                      }
                    />                   
                  </CardContent>
                </Card>
              );
            })}
          </Box>
        ) : (
          <Typography>Aún no se ha definido el costo de inscripción de su servicio</Typography>
        )}
      </>
    )
  }