import React, { useState, ChangeEvent, useEffect } from 'react';
import { DateTime } from 'luxon';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Chip, Grid, Paper, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { useForm } from '../customhooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import { startRegisterClientThunk, startRegisterAdminThunk, getAllCustomMembershipThunk, getAllPromotionsThunk, getAllTenantInscriptionThunk } from '../store/usuarios/index';
import { TimeZone } from '../interfaces/interfaces';
import { SnackBar } from '../atoms/SnackBar';
import { useFormStateValidation } from '../customhooks/useFormStateValidation';
import { getTimeZoneFromUser } from '../utils/timeZoneParser';

import { usePriceDefination } from '../customhooks/usePriceDefination';
import { usePromotion } from '../customhooks/usePromotion';

const paperStyle = {
  padding: '20px',
  margin: 'auto auto auto 30px',
  borderRadius: '10px',
  width: '100%',
  marginTop: '25px',
  //maxWidth: '500px',
};
const textFieldStyle = {
  marginBottom: '20px',
  marginTop: '10px',
  width: '100%'
};

const selectStyle = {
  marginBottom: '20px',
  marginTop: '40px'
};

const buttonStyle = {
  marginTop: '20px',
  width: '40%'
};
export const RegisterClientsAdmins = () => {
  const now = DateTime.now();
  const [activeStep, setActiveStep] = useState(0);
  const [errorCustomMembership, setErrorCustomMembership] = useState(false);
  const [applyPromotion, setApplyPromotion] = useState(false);

  const {
    tmpPagoInscripcion,
    tmpPlanMonto,
    totalConDescuento,
    applyPromotionOnUserRegistration,
    handlerSetPromotion
  } = usePromotion();
  
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const timeZoneData = getTimeZoneFromUser()
  const adminHandler = useSelector((state: RootState) => state?.adminRegister);
  const clientsHandler = useSelector((state: RootState) => state?.clientsRegister);
  const customMembership = useSelector((state: RootState) => state?.customMembership?.customMembership);
  const promotions = useSelector((state: RootState) => state?.getPromotion?.promotions);
  const inscription = useSelector((state: RootState) => state?.getInscriptionRecords?.inscriptions);
  const inscriptionPrice = inscription?.[0];
  
  const { handleSelectChange, onInputChange, formState, onResetForm, setFormState } = useForm({
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    email: '',
    fechaNacimiento: '',
    direccion: '',
    fechaIngreso: '',
    planMensual: 'MENSUAL',
    planStatusActivo: 'true',
    planMensualTipo: '',
    salarioMensual: 0,
    planMensualMonto: 0,
    contrasena: '',
    role: '',
    phoneNumber: '',
    fechaInicioPlan: '',
    pagoDeInscripcion: inscriptionPrice?.price || 0,
    promotionStatus: applyPromotion,
    discountCaptured: 0,
    promotion: '',
    timeZone: {
      identifier: timeZoneData.userTimeZone,
      offset: timeZoneData.userOffset
    },
  })
  const {
    error,
    errorField,
    errorPassword,
    fieldFormatError,
    passwordFormatError,
    dateBorn,
    dateBornError,
    errorNumber,
    handleEmail,
    handlePassword,
    dateValidation,
    validateField,
    inscriptionPaymentValidator,
    gralError,
    validateNumber
  } = useFormStateValidation();
  const { selectPlan } = usePriceDefination();

  const handleChangeSelectorPlan = (event: SelectChangeEvent<string | number | Function | Boolean | TimeZone | null>) => {
    selectPlan(event, customMembership, setFormState, setErrorCustomMembership);
  }

  /* Srepper */
  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const conditionHandler = () => {
    if (formState?.planStatusActivo !== 'true' || Object.keys(formState).length <= 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    dispatch(getAllPromotionsThunk());
    dispatch(getAllCustomMembershipThunk());
    dispatch( getAllTenantInscriptionThunk() );
  }, [ dispatch , inscription?.length === 0 , promotions?.length === 0 , customMembership?.length === 0 ]);

  useEffect(() => {
    const email = formState?.email
    const password = formState?.contrasena
    const dateBorn = formState?.fechaNacimiento
    handleEmail(email as string)
    handlePassword(password as string)
    dateValidation(dateBorn as string)
    validateNumber(formState?.phoneNumber as any, 'phoneNumber');
    validateField('nombre', formState?.nombre as string);
    validateField('apellidoPaterno', formState?.apellidoPaterno as string);
    validateField('apellidoMaterno', formState?.apellidoMaterno as string);
    validateField('direccion', formState?.direccion as string);
    inscriptionPaymentValidator('pagoDeInscripcion' , parseFloat(formState?.pagoDeInscripcion as string))
  }, [
    formState.email,
    formState.contrasena,
    formState.fechaNacimiento,
    formState?.nombre,
    formState?.apellidoPaterno,
    formState?.apellidoMaterno,
    formState?.direccion,
    formState?.phoneNumber
  ]);
  const handlePromotionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setApplyPromotion(event?.target?.checked);
  };

  const handlerSelectedPromotion = (event: SelectChangeEvent<string>) => {
    handlerSetPromotion(event.target.value, promotions, setFormState);
  }

  useEffect(() => {
    applyPromotionOnUserRegistration(
      applyPromotion,
      formState?.promotion,
      promotions,
      formState?.pagoDeInscripcion as number,
      formState?.planMensualMonto as number
    );
  }, [applyPromotion, formState.promotion, formState.pagoDeInscripcion, formState.planMensualMonto]);



  const handleSubmit = async () => {
    const email = formState?.email;
    handleEmail(formState.email as string)
    if (formState?.role === 'CLIENTE') {

      const updatedFormState = {
        ...formState,
        pagoDeInscripcion: tmpPagoInscripcion,
        planMensualMonto: tmpPlanMonto,
      };

      const success = await dispatch(startRegisterClientThunk(updatedFormState, navigate));
      if (success?.ok === true) {
        onResetForm()
      }
    } else {
      const success = await dispatch(startRegisterAdminThunk(formState, navigate));
      if (success?.ok === true) {
        onResetForm()
      }
    }
  };
  const steps = [
    'Datos Personales',
    'Dirección',
    'Detalles del Plan',
    'Revisar y Enviar',
  ];

  return (
    <Paper style={paperStyle}>
      <Typography variant="h6" align="center" mb={3}>
        Registro de Empleados y/o Clientes
      </Typography>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }} noValidate autoComplete="off">
        {activeStep === 0 && (
          <div>
            <TextField
              name="nombre"
              label="Nombre"
              value={formState?.nombre}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              required
              error={errorField?.nombre?.error}
              helperText={errorField?.nombre?.textMessage}
            />
            <TextField
              name="apellidoPaterno"
              label="Apellido Paterno"
              value={formState?.apellidoPaterno}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              error={errorField?.apellidoPaterno?.error}
              helperText={errorField?.apellidoPaterno?.textMessage}
            />
            <TextField
              name="apellidoMaterno"
              label="Apellido Materno"
              value={formState?.apellidoMaterno}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              error={errorField?.apellidoMaterno?.error}
              helperText={errorField?.apellidoMaterno?.textMessage}
            />
            <TextField
              name="email"
              label="Email"
              value={formState?.email}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              error={fieldFormatError}
              helperText={
                fieldFormatError && error
              }
              required
            />
            <TextField
              name="phoneNumber"
              label="Número de teléfono"
              value={formState?.phoneNumber}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              required
              type='number'
              error={errorNumber?.phoneNumber?.error}
              helperText={errorNumber?.phoneNumber?.textMessage}
            />
            <TextField
              name="fechaNacimiento"
              label="Fecha de Nacimiento"
              value={formState?.fechaNacimiento}
              onChange={onInputChange}
              type="date"
              style={textFieldStyle}
              error={dateBornError}
              helperText={
                dateBornError && dateBorn
              }
              InputLabelProps={{
                shrink: true, 
              }}
              fullWidth
            />
          </div>
        )}
        {activeStep === 1 && (
          <div>
            <TextField
              name="direccion"
              label="Dirección"
              value={formState?.direccion}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              required
              error={errorField?.direccion?.error}
              helperText={errorField?.direccion?.textMessage}
            />
          </div>
        )}
        {activeStep === 2 && (
          <div>
            <FormControl style={selectStyle} fullWidth>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                name="role"
                value={formState?.role}
                onChange={handleSelectChange}
              >
                <MenuItem value="CLIENTE">CLIENTE</MenuItem>
                <MenuItem value="ADMIN">ADMIN</MenuItem>
                <MenuItem value="EXPERTO_EN_EL_AREA">EXPERTO DEL ÁREA</MenuItem>
              </Select>
            </FormControl>
            {
              (formState?.role === 'ADMIN' || formState?.role === 'EXPERTO_EN_EL_AREA') ? (<TextField
                name="salarioMensual"
                label="Salario mensual"
                value={formState?.salarioMensual}
                onChange={onInputChange}
                style={textFieldStyle}
                fullWidth
                type='number'
              />) : <>
                <FormControl style={selectStyle} fullWidth>
                  <InputLabel id="activarPlan-label">Activar Plan de suscripción</InputLabel>
                  <Select
                    labelId="activarPlan-label"
                    name="planStatusActivo"
                    value={formState?.planStatusActivo}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="true">Si</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
                <TextField          
                  error = { errorField?.pagoDeInscripcion?.error === true }
                  name="pagoDeInscripcion"
                  label="Pago de inscripción"
                  value={formState?.pagoDeInscripcion}
                  onChange={onInputChange}
                  style={textFieldStyle}                  
                  helperText = { errorField?.pagoDeInscripcion?.textMessage}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  type='number'
                />
                <TextField
                  name="fechaInicioPlan"
                  label="Fecha de inicio del plan"
                  value={formState?.fechaInicioPlan}
                  type="date"
                  onChange={onInputChange}
                  style={textFieldStyle}
                  fullWidth
                  InputLabelProps={{
                    shrink: true, // Ajusta la posición de la etiqueta
                  }}
                />
              </>
            }
            {
              (formState?.role === 'CLIENTE') ? (<>
                <FormControl style={selectStyle} fullWidth>
                  <InputLabel id="planMensual-label">Tipo de plan</InputLabel>
                  <Select
                    label="Tipo de plan"
                    labelId="planMensual-label"
                    name="planMensualTipo"
                    value={formState?.planMensualTipo}
                    onChange={handleChangeSelectorPlan}
                  >
                    <MenuItem value="mensual">Mensual</MenuItem>
                    <MenuItem value="bimestral">Bimestral</MenuItem>
                    <MenuItem value="semanal">Semanal</MenuItem>
                    <MenuItem value="semestral">Semestral</MenuItem>
                    <MenuItem value="quincenal">Quincenal</MenuItem>
                    <MenuItem value="anual">Anual</MenuItem>
                    <MenuItem value="cuatrimestral">Cuatrimestral</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  error={errorCustomMembership}
                  name="planMensualMonto"
                  label="Monto del Plan"
                  value={formState?.planMensualMonto}
                  onChange={onInputChange}
                  style={textFieldStyle}
                  fullWidth
                  type='number'
                  helperText={errorCustomMembership ? 'No se encontró un plan de membresía personalizado. Por favor, cree uno en "Mi empresa" -> "Definiciones de precios".' : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <FormControlLabel
                  control={<Checkbox checked={applyPromotion} onChange={handlePromotionChange} />}
                  label="Aplicar promoción"
                  style={{ marginTop: '20px' }}
                />
                {applyPromotion && (
                  <FormControl fullWidth required style={selectStyle}>
                    <InputLabel id="promotion-label">Promoción</InputLabel>
                    <Select
                      labelId="promotion-label"
                      name="promotion"
                      value={formState.promotion as any}
                      onChange={handlerSelectedPromotion}
                    >
                      {promotions
                      .filter((promotion) => {
                        const expirationDate = DateTime.fromISO(promotion?.expirationDate as string);
                        return expirationDate > now;
                      })
                      .map((promotion) => (
                        <MenuItem key={promotion._id} value={promotion._id}>
                          {promotion.name} ({promotion.discount}%)
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <Typography variant="h6" align="center" style={{ marginTop: '20px' }}>
                  Total: ${totalConDescuento.toFixed(2)}
                </Typography>
              </>
              ) : (
                <TextField
                  name="contrasena"
                  label="Contraseña"
                  value={formState?.contrasena}
                  onChange={onInputChange}
                  type="password"
                  style={textFieldStyle}
                  fullWidth
                  error={passwordFormatError}
                  helperText={
                    passwordFormatError ? errorPassword : ''
                  }
                />)
            }
          </div>
        )}
        {activeStep === 3 && (
          <div style={{ marginTop: '10px' }}>
            <Typography variant="h6">Resumen del Formulario</Typography>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', marginBottom: '20px', width: '100%' }}>
              <Typography key="nombre" variant="body1">
                {`Nombre: ${formState?.nombre}`}
              </Typography>
              <Typography key="apellidoPaterno" variant="body1">
                {`Apellido Paterno: ${formState?.apellidoPaterno}`}
              </Typography>
              <Typography key="apellidoMaterno" variant="body1">
                {`Apellido Materno: ${formState?.apellidoMaterno}`}
              </Typography>
              <Typography key="email" variant="body1">
                {`Email: ${(fieldFormatError) ? <Chip color='error' label={error} /> : formState?.email}`}
              </Typography>
              <Typography key="fechaNacimiento" variant="body1">
                {`Fecha de Nacimiento: ${formState?.fechaNacimiento}`}
              </Typography>
              <Typography key="direccion" variant="body1">
                {`Dirección: ${formState?.direccion}`}
              </Typography>
              <Typography key="phoneNumber" variant="body1">
                {`Número de teléfono: ${formState?.phoneNumber}`}
              </Typography>
              {
                (formState?.role && formState?.role !== "CLIENTE") ? <>
                  <Typography key="contrasena" variant="body1">
                    {`Contraseña: ${formState?.contrasena}`}
                  </Typography>
                  <Typography key="salarioMensual" variant="body1">
                    {`Salario Mensual ${formState?.salarioMensual}`}
                  </Typography>
                </> : <>
                  <Typography key="pagoDeInscripcion" variant="body1">
                    {`Pago único de inscripción: ${formState?.pagoDeInscripcion}`}
                  </Typography>
                  <Typography key="fechaInicioPlan" variant="body1">
                    {`Fecha de inicio del plan: ${formState?.fechaInicioPlan}`}
                  </Typography>
                  <Typography key="planMensualTipo" variant="body1">
                    {`Tipo de Plan: ${formState?.planMensualTipo}`}
                  </Typography>
                  <Typography key="planMensualMonto" variant="body1">
                    {`Monto del Plan: ${formState?.planMensualMonto}`}
                  </Typography>
                </>
              }
              <Typography key="role" variant="body1">
                {`Role: ${formState?.role}`}
              </Typography>

              <Typography key="planStatusActivo" variant="body1">
                {`Estado del plan: ${(formState?.planStatusActivo !== 'false') ? 'ACTIVO' : <Chip color='error' label='ATENCIÓN , FALTA ACTIVAR EL PLAN EN CAMPO ACTIVAR PLAN DE SUSCRIPCIÓN!!!' />}`}
              </Typography>
            </div>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {activeStep !== 0 && (
            <Button
              variant="contained"
              color="inherit"
              style={buttonStyle}
              onClick={handleBackStep || gralError}
            >
              Atrás
            </Button>
          )}
          {activeStep < steps.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={handleNextStep}
              disabled={gralError.includes(true)}
            >
              Siguiente
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={handleSubmit}
              disabled={conditionHandler()}
            >
              Enviar
            </Button>)
          }
        </div>
        {
          (adminHandler?.status === 'un-register-user') && <SnackBar severity='error' errorMsg={adminHandler?.BackendErrorMessage}></SnackBar>
        }
        {
          (adminHandler?.status === 'registering') && <SnackBar severity='info' errorMsg='Registrando usuario...'></SnackBar>
        }
        {
          (adminHandler?.status === 'usuario-registrado') && <SnackBar severity='success' errorMsg='Usuario registrado con éxito, visible en sección de búsqueda'></SnackBar>
        }

        {
          /** cliente */
          (clientsHandler?.status === 'un-register-user') && <SnackBar severity='error' errorMsg={clientsHandler?.BackendErrorMessage}></SnackBar>
        }
        {
          /** cliente */
          (clientsHandler?.status === 'registering') && <SnackBar severity='info' errorMsg='Registrando cliente...'></SnackBar>
        }
        {
          /** cliente */
          (clientsHandler?.status === 'user-registered') && <SnackBar severity='success' errorMsg='Cliente registrado éxitosamente !!!'></SnackBar>
        }
      </form>
    </Paper>
  );
}
