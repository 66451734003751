export const config = {
    REACT_APP_PUBLISHABLE_STRIPE_KEY : process.env.REACT_APP_PUBLISHABLE_STRIPE_KEY,
    REACT_APP_PRODUCTIONSERVER : process.env.REACT_APP_PRODUCTIONSERVER,
    REACT_APP_PROD_VOLCANOTECH_URL : process.env.REACT_APP_PROD_VOLCANOTECH_URL,
    REACT_APP_CHAT_WOOT : process.env.REACT_APP_CHAT_WOOT,
    REACT_APP_CHAT_WOOT_CHAT_TOKEN : process.env.REACT_APP_CHAT_WOOT_CHAT_TOKEN,
    MODAL_TYPE_ADD_USER : 'addUser',
    MODAL_TYPE_CREATE_EVENT : 'createEvent',
    MODAL_TYPE_CHANGED_PASSWORD : 'changePassword',
    MODAL_TYPE_EDIT_USER : 'editUser',
    MODAL_TYPE_EDIT_PRODUCT: 'editProduct',
    MODAL_TYPE_CREATE_PRODUCT: 'createProduct',
    MODAL_TYPE_SELL_PRODUCT: 'sellProduct',
    MODAL_CREATE_CATEGORY : 'createCategory',
    MODAL_EDIT_EXPENSE : 'expenseEdit',
    MODAL_ADD_NEW_EXPENSE : 'createExpense',
    MODAL_CREATE_CUSTOM_MEMBERSHIP : 'createCustomMembership',
    MODAL_PROMOTION_DEFINITION : 'createPromotion',
    MODAL_EDIT_PROMOTION_DEFINITION : 'editPromotion',
    MODAL_DELETE_PROMOTION : 'deletePromotion',
    MODAL_EDIT_CUSTOM_MEMBERSHIP : 'editCustomMembership',
    MODAL_CREATE_INSCRIPTION_PAYMENT : 'createInscription',
    MODAL_UPDATE_INSCRIPTION_PAYMENT : 'updateInscription',
    MODAL_DELETE_INSCRIPTION_PAYMENT : 'deleteInscription',
    AllOWED_MODAL_TYPE : ['createEvent','addUser' ,'createCategory'],
    MEMBRESIA_TYPE: {
        SEMANAL: 'MEMBRESIA_SEMANAL',
        GENERAL: 'MEMBRESIA_GENERAL',
        QUINCENAL: 'MEMBRESIA_QUINCENAL'
    },
    INSCRIPTION_PAYMENT : 0 ,
    CURRENCY : "MXN",
    MONTHLY_PLAN : "MENSUAL",
    SIX_MONTHLY_PLAN : "SEMESTRAL",
    ANUAL_PLAN : "ANUAL",
    PLAN_BASICO : "BASICO",
    PLAN_ESENCIAL : "ESENCIAL",
    PLAN_ELITE : "ELITE",
    USER_BASICO_COST : 199,
    USER_ESENCIAL_COST : 999,
    USER_ELITE_COST : 1399,
    REQUEST_ORIGIN_REGISTER : 'registrar',
    REQUEST_ORIGIN_RENEW : "renewPlan",
    CONST_ADMIN : "ADMIN",
    CONST_SUPER_ADMIN : "SUPER_ADMIN",
    CONST_EXPERTO : "EXPERTO_EN_EL_AREA",
    CONST_CLIENTE : "CLIENTE",
    CONST_MANAGEMENT_SERVICE : "MANAGEMENT-SERVICE-ROLE"
}
