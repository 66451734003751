import { createSlice } from "@reduxjs/toolkit";

export const deleteTenantInscriptionSlice = createSlice({
    name: "deleteInscriptionPayment",
    initialState: {
        status: "initial",
        msg: null,
        BackendErrorMessage: null,
    },
    reducers: {
    failedDeleteDefinition: (state, { payload }) => {
        state.status = "failed";
        state.msg = null;
        state.BackendErrorMessage = payload;
    },
    deleteInscription: (state, { payload }) => {
        state.status = "success";
        state.msg = payload.msg;
        state.BackendErrorMessage = null;
    },
    deletingInscription: (state) => {
        state.status = "in-progress";
    },
},
});

export const { failedDeleteDefinition, deleteInscription, deletingInscription } = deleteTenantInscriptionSlice.actions;