import { createSlice } from "@reduxjs/toolkit"
import type { customMembership } from "../../../interfaces/interfaces"

export const customMembershipSlice = createSlice({
  name: "customMembership",
  initialState: {
    status: "initial",
    msg: null,
    customMembership: [] as customMembership[],
    BackendErrorMessage: null,
  },
  reducers: {
    failedCustomMembership: (state, { payload }) => {
      state.status = "failed"
      state.customMembership = []
      state.BackendErrorMessage = payload
    },
    getAllCustomMembership: (state, { payload }) => {
      state.status = "success"
      state.msg = payload.msg
      state.customMembership = payload.customMemberships
      state.BackendErrorMessage = null
    },
    gettingCustomMembership: (state) => {
      state.status = "in-progress"
    },
  },
})

export const { failedCustomMembership, getAllCustomMembership, gettingCustomMembership } = customMembershipSlice.actions