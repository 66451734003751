import React, { useCallback } from 'react'
import { useState, ChangeEvent, useEffect } from 'react';
import { FormState, FormActions, FormValidations } from '../interfaces/interfaces';
import { useFormValidation } from './FormValidation';

export const useForm = (initialForm: FormState = {}, formValidations: FormValidations = {}): {
    formState: FormState;
    formErrors: { [key: string]: string };
    setFormState: React.Dispatch<React.SetStateAction<FormState>>;
    onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
    //handleSelectChange:(event: ChangeEvent<HTMLSelectElement>)=>any;
    onResetForm: () => void
} => {

    const [formState, setFormState] = useState(initialForm);
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    // const [formValidation, setFormValidation] = useState({})

    const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [name]: value
        });
    }
    /** update databaseName based on tenantName and nombreEmpresa*/
    const updateDatabaseName = useCallback(() => {
        const updatedDatabaseName = `${formState?.tenantName}-${formState?.nombreEmpresa}`;
        setFormState((prevState) => ({
            ...prevState,
            databaseName: updatedDatabaseName,
        }));
    }, [formState?.tenantName, formState?.nombreEmpresa]);

    useEffect(() => {
        updateDatabaseName();
    }, [formState?.tenantName, formState?.nombreEmpresa, updateDatabaseName]);


    /** args f=of function before 
     * event: React.ChangeEvent<{ name?: string; value: unknown }>
    */
    const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        //const { name, value } = event.target;
        setFormState({
            ...formState,
            [event.target.name as string]: event.target.value as string,
        });
    };
    const onResetForm = (): void => {
        setFormState(initialForm);
    }
    
    return {
        ...formState,
        formState,
        setFormState,
        onInputChange,
        onResetForm,
        handleSelectChange,
        formErrors,
    }
}
