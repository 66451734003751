import { EXPENSE_RECORDS, SALES_RECORDS } from "../../interfaces/interfaces";
import { useExportToExcel } from "../../customhooks/useExportToExcel";

export const useHandlerExport =()=> {
    const { exportToExcel } = useExportToExcel(); 
    const exportXlsx =( sales : SALES_RECORDS [] , fileName : string, sheetName : string)=>{
        if( sales && sales?.length ){
            const data = sales.map((sale) => ({
                "Fecha de venta": sale?.saleDate,
                "Nombre del producto": sale?.product?.nombre,
                "Precio por unidad": sale?.unitPrice,
                "Cantidad de productos vendidos": sale?.quantity,
                "Tipo de venta": sale?.serviceIdentifier,
                "Vendido por": sale?.user?.nombre || sale?.user?.email || sale?.user?.phoneNumber,
                "Se aplico promoción" : ( sale?.promotionStatus === true ) ? 'Promoción aplicada' : 'No',
                "% descuento aplicado" : sale?.discountCaptured || 0,
                "Ingresos generados por venta": sale?.amount,
            }));
            exportToExcel({ 
                data, 
                fileName,
                sheetName
            });
        }else{
            alert("No hay ventas disponibles");
        }
    }
    const expenseXlsx = (expense : EXPENSE_RECORDS [], fileName : string, sheetName : string)=>{
        if( expense && expense?.length ){
            const data = expense.map((expense) => ({
                "ID Del gasto": expense?._id,
                "Identificador del gasto": expense?.expensiveName,
                "Descripción": expense?.description,
                "Fecha de captura": expense?.registerDate,
                "Categoría a la que pertenece": expense?.category?.nombre,
                "Quíen lo capturó? ": expense?.registeredBy?.nombre || expense?.registeredBy?.email || expense?.registeredBy?.nombreEmpresa || expense?.registeredBy?.phoneNumber,
                "Fecha desde última modificación": expense?.lastEditDate,
                "Quíen modificó el registro ?": expense?.editedBy?.nombre || expense?.editedBy?.nombreEmpresa || expense?.editedBy?.email || expense?.editedBy?.phoneNumber,
                "Total del gasto": expense?.total,
            }));
            exportToExcel({ 
                data, 
                fileName,
                sheetName
            });
        }else{
            alert("No hay data disponible que exportar");
        }
    }
    return{
        exportXlsx,
        expenseXlsx
    }
}