import { configureStore } from '@reduxjs/toolkit'
import { AuthSlice } from './slides/Auth/index'
import { Register } from './slides/Auth/Register';
import {Clients} from './slides/index'
import { allUsersSlice } from './slides/allUsersSlice';
import purgeReducer from './slides/Auth/PurgeSlice'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux';
import { detailsInfoSlice } from './slides/detailsInfoSlice';
import { managementServiceSlice } from './slides/managementServiceSlice';
//import { getAllCategoriasSlice,getCategoriesByIdSlice } from './categorias';
import {getAllCategoriasSlice, getCategoriesByIdSlice} from './categorias/index'
import { getEventSlice ,deleteEventSlice} from './eventos/index'
import { deleteProductsSlice, productosSlice, sellProductSlice } from './productos/index';
import { updateEventSlice } from './eventos/index'
import { adminRegisterSlice, clientRegisterSlide, createCustomMembership, createCustomMembershipSlice, createPromotionSlice, deletePromotionSlice, deleteTenantInscriptionSlice, editCustomMembershipSlice, editPromotionSlice, getPromotionSlice, getTenantInscriptionPriceSlide, renewMembership, renewMembershipSlide, renewPlanSlice, tenantInscriptionPriceSlide, updateTenantInscriptionSlice} from './usuarios/index'
import { deleteUsersSlice } from './clientes/index';
import { postCategorySlide } from './categorias/slides/postCategorySlide';
import { deleteCategorySlice } from './categorias/index';
import { modalSlice } from './modal/slide/modalSlide';
import { updateCategorySlice } from './categorias/index';
import { postProductSlide } from './productos/slides/postProductSlide';
import { postEventSlide } from './eventos/slides';
import { saveTermAndSectionSlide } from './search/slides/saveTermAndSectionSlide';
import { getSearchResultsSlide } from './search/slides/getSearchResultsSlide';
//import { getSalesRecordsSlide } from './sales/slides/getSalesRecordsSlide';
import { saveUserIdSlide } from './usuarios/slides/saveUserIdSlide';
import { editUserSlice } from './usuarios/slides/editUserSlide';
import { eventsAddAnUser } from './eventos/slides';
import { tenantPaymentSlide , getSalesRecordsSlide} from './sales/slides/index';
import { recoverPasswordSlice } from './slides/index';
import { updateProductSlice } from './productos/slides/updateProductSlice';
import { changePasswordSlice } from './usuarios/index';
import { changeLicenseSlice, getUserAdminTrafficReportSlide } from './usuarios/index';
import { getProductByIdSlice } from './productos/index';
import { notificationSlice } from './notifications/index';
import { createExpenseRecordSlide, getExpensiveRecordsSlide } from './expenses';
import { updateExpenseSlice } from './expenses/slides/updateExpenseSlide';
import { customMembershipSlice } from './usuarios/slides/customMembershipSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth','eventos','detailsinfo','getClients','getUserAdminTrafficReportSlide'],
}
const rootReducer = combineReducers({
  auth:AuthSlice.reducer,
  register: Register.reducer,
  getClients : Clients.reducer,
  getAllUserSlice : allUsersSlice.reducer,
  detailsinfo : detailsInfoSlice.reducer,
  managementService : managementServiceSlice.reducer,
  categories : getAllCategoriasSlice.reducer,
  categoriesById : getCategoriesByIdSlice.reducer,
  eventos : getEventSlice.reducer,
  productos : productosSlice.reducer,
  purge: purgeReducer,
  clientsRegister : clientRegisterSlide.reducer,
  adminRegister : adminRegisterSlice.reducer,
  deletedEvent : deleteEventSlice.reducer,
  deletedUsers : deleteUsersSlice.reducer,
  deleteProduct : deleteProductsSlice.reducer,
  updateEvent : updateEventSlice.reducer,
  updateProduct : updateProductSlice.reducer,
  productById: getProductByIdSlice.reducer,
  editUser : editUserSlice.reducer,
  postCategory : postCategorySlide.reducer,
  sellProduct : sellProductSlice?.reducer,
  deleteCategory : deleteCategorySlice.reducer,
  updateCategory : updateCategorySlice.reducer,
  modal : modalSlice.reducer,
  saveSearchTerm : saveTermAndSectionSlide.reducer,
  postProduct : postProductSlide.reducer,
  postEvent : postEventSlide.reducer,
  searchResults : getSearchResultsSlide.reducer,
  getSalesRecords : getSalesRecordsSlide.reducer,
  saveUserId : saveUserIdSlide.reducer,
  renewMembership : renewMembershipSlide.reducer,
  eventosUsuarios : eventsAddAnUser.reducer,
  tenantPay : tenantPaymentSlide.reducer,
  recoverPasswordSlice : recoverPasswordSlice.reducer,
  changePassword : changePasswordSlice.reducer,
  changeLicense: changeLicenseSlice.reducer,
  renewTenantPlan : renewPlanSlice.reducer,
  getUserAdminTrafficReportSlide: getUserAdminTrafficReportSlide.reducer,
  notification : notificationSlice.reducer,
  getExpenses : getExpensiveRecordsSlide.reducer,
  updateExpense : updateExpenseSlice.reducer,
  createExpenseRecord : createExpenseRecordSlide.reducer,
  customMembership: customMembershipSlice.reducer,
  createCustomMembership : createCustomMembershipSlice.reducer,
  editCustomMembership : editCustomMembershipSlice.reducer,
  createPromotion : createPromotionSlice.reducer,
  deletePromotion : deletePromotionSlice.reducer,
  editPromotion : editPromotionSlice.reducer,
  getPromotion : getPromotionSlice.reducer,
  postInscriptionPayment : tenantInscriptionPriceSlide.reducer,
  deleteInscriptionPayment : deleteTenantInscriptionSlice.reducer,
  getInscriptionRecords : getTenantInscriptionPriceSlide.reducer,
  updateTenantInscription : updateTenantInscriptionSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
//NEW ONES REDUCERS AND STATES 

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch