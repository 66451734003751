import { useCallback } from 'react';
import * as XLSX from 'xlsx';
import { CUSTOM_HOOK_EXPORT_EXCEL } from '../interfaces/interfaces';


export const useExportToExcel = () => {
  const exportToExcel = useCallback( ( { data , fileName, sheetName}: CUSTOM_HOOK_EXPORT_EXCEL ) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }, []);

  return { exportToExcel };
};
