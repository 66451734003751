import { SelectChangeEvent } from "@mui/material";
import { customMembership, FormState, TimeZone } from "../interfaces/interfaces";

import { config } from "../config";

export const usePriceDefination = () => {
  const selectPlan = (
    event: SelectChangeEvent<string | number | Function | Boolean | TimeZone | null>,
    customMembership: customMembership[],
    setFormState: React.Dispatch<React.SetStateAction<FormState>>,
    setErrorCustomMembership: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    const selectedPlan = event.target.value as string;
    let newPlanMensualMonto = '';

    switch (selectedPlan) {
      case 'semanal':
        customMembership.find((item) => item?.typePlan === config.MEMBRESIA_TYPE.SEMANAL) ? setErrorCustomMembership(false) : setErrorCustomMembership(true);

        newPlanMensualMonto = String(
          customMembership.find((item) => item?.typePlan === config.MEMBRESIA_TYPE.SEMANAL)?.price
        ) || '0';
        break;
      case 'quincenal':
        customMembership.find((item) => item?.typePlan === config.MEMBRESIA_TYPE.QUINCENAL) ? setErrorCustomMembership(false) : setErrorCustomMembership(true);

        newPlanMensualMonto = String(
          customMembership.find((item) => item?.typePlan === config.MEMBRESIA_TYPE.QUINCENAL)?.price
        ) || '0';
        break;
      default:
        customMembership.find((item) => item?.typePlan === config.MEMBRESIA_TYPE.GENERAL) ? setErrorCustomMembership(false) : setErrorCustomMembership(true);
        const newPrice = customMembership.find((item) => item?.typePlan === config.MEMBRESIA_TYPE.GENERAL)?.price;
        if (newPrice) {
          if (selectedPlan === 'mensual') {
            newPlanMensualMonto = String(newPrice * 1);
          }
          if (selectedPlan === 'bimestral') {
            newPlanMensualMonto = String(newPrice * 2);
          }
          if (selectedPlan === 'semestral') {
            newPlanMensualMonto = String(newPrice * 6);
          }
          if (selectedPlan === 'anual') {
            newPlanMensualMonto = String(newPrice * 12);
          }
          if (selectedPlan === 'cuatrimestral') {
            newPlanMensualMonto = String(newPrice * 4);
          }
        }
        break;
    }

    setFormState((prevState) => ({
      ...prevState,
      planMensualTipo: selectedPlan,
      planMensualMonto: newPlanMensualMonto,
    }));
  };

  return {
    selectPlan
  }
}