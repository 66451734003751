import { createSlice } from "@reduxjs/toolkit"

export const createCustomMembershipSlice = createSlice({
  name: "createCustomMembership",
  initialState: {
    status: "initial",
    msg: null,
    price: null,
    typePlan: null,
    BackendErrorMessage: null,
  },
  reducers: {
    failedCreateCustomMembership: (state, { payload }) => {
      state.status = "failed"
      state.price = null
      state.typePlan = null
      state.BackendErrorMessage = payload
    },
    createCustomMembership: (state, { payload }) => {
      state.status = "success"
      state.msg = payload.msg
      state.price = payload.price
      state.typePlan = payload.typePlan
      state.BackendErrorMessage = null
    },
    creatingCustomMembership: (state) => {
      state.status = "in-progress"
    },
  },
})

export const { failedCreateCustomMembership, createCustomMembership, creatingCustomMembership } = createCustomMembershipSlice.actions