import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Modal,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';

import { closeModal } from '../store/modal';
import { AppDispatch, RootState } from '../store';
import { useForm } from '../customhooks';

import Title from '../atoms/Title';
import { membershipDescription } from '../constants/ConstantsFlexSolutionManager';

import { FormEvent } from 'react';
import { createCustomMembershipThunk } from '../store/usuarios';

interface CustomMembershipModalProps {
  modalType: string;
}

const style = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  gap: 2,
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

export const CustomMembershipModal = ({ modalType }: CustomMembershipModalProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const open = useSelector((state: RootState) => state?.modal.openModal);
  const modalTypeStore = useSelector((state: RootState) => state?.modal.modalType);

  const validTypePlans = ['MEMBRESIA_GENERAL', 'MEMBRESIA_QUINCENAL', 'MEMBRESIA_SEMANAL'] as const;

  const { onInputChange, formState, onResetForm } = useForm({
    price: '',
    typePlan: '',
  });

  const handleClose = () => {
    dispatch(closeModal());
    onResetForm();
  }

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      onInputChange(e);
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    
    const useToResetForm = await dispatch(createCustomMembershipThunk(formState))

    if (useToResetForm?.ok === true) {
      onResetForm();
      dispatch(closeModal());
    }
  }
  
  return (
    <>
      {
        modalType === modalTypeStore && (
          <Modal open={ open } onClose={ handleClose } aria-labelledby="Crear precio de membresía" aria-describedby="crea un precio nuevo">
            <form onSubmit={ handleSubmit }>
              <Box sx={ style }>
                <Title>Nueva definición de membresía</Title>
                <FormControl fullWidth>
                  <InputLabel id="typePlan">Tipo de plan</InputLabel>
                  <Select
                    labelId="typePlan"
                    id="typePlan"
                    value={ formState.typePlan }
                    label="Tipo de plan"
                    name="typePlan"
                    onChange={ onInputChange as any }
                    required
                  >
                    <MenuItem value={ 'MEMBRESIA_GENERAL' }>Membresía general</MenuItem>
                    <MenuItem value={ 'MEMBRESIA_QUINCENAL' }>Membresía quincenal</MenuItem>
                    <MenuItem value={ 'MEMBRESIA_SEMANAL' }>Membresía semanal</MenuItem>
                  </Select>
                  <FormHelperText>
                    { formState.typePlan !== '' && validTypePlans.includes(formState.typePlan as typeof validTypePlans[number]) && membershipDescription[formState.typePlan as keyof typeof membershipDescription] }
                  </FormHelperText>
                </FormControl>

                <TextField
                  label="Precio"
                  name="price"
                  value={ formState.price }
                  onChange={ handlePriceChange }
                  fullWidth
                  margin="normal"
                  required
                  type="number"
                  inputProps={ { min: 0 } }
                />

                <Box sx={ { display: 'flex', justifyContent: 'space-between' } }>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={ handleClose }
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Añadir
                  </Button>
                </Box>
              </Box>
            </form>
          </Modal>
        )
      }
    </>
  )

}
