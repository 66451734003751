
import { HomeMainSection } from '../components/HomeMainSection';
import { HomeTools } from '../components/HomeTools';
import { HomeTutorial } from '../components/HomeTutorial';
import { HomeNavBar } from '../components/HomeNavBar';
import { HomeFooter } from '../components/HomeFooter';
import { IdealForSection } from '../components/IdealForSection';

import '../styles/home.css';
import { FreeTrial } from '../components/FreeTrial';
import { Posting } from '../components/Posting';

export const Home = () => {
    return (
        <div className='flex flex-col justify-center relative bg-white'>
            <HomeNavBar/>
            <div className='pl-10 pr-10 overflow-x-hidden max-w-[2000px]'>  
                <HomeMainSection/>
                <Posting />
                <div className='w-full flex justify-center mt-32'>
                    <div className='flex flex-col items-center'>
                        <p className='text-mainBlue font-bold text-4xl sm:text-2xl'>¿Qué ofrece volcano admin?</p>
                        <p className='w-5/12 text-center text-2xl text-lightGray mt-5 sm:w-full sm:text-lg md:w-full md:text-xl'>Conoce todos todo lo que volcano admin puede ofrecer a tu negocio, comienza a a horrar tiempo y esfuerzo con nuestras herramientas.</p>
                    </div>
                </div>
                <HomeTools/>
                <div className='w-full flex flex-col justify-center items-center mt-32 sm:mt-12'>
                    <p className='font-bold text-4xl text-mainBlue sm:text-center sm:text-3xl'>Conoce los planes de volcano flex admin</p>
                    <button className='bg-mainBlue px-10 py-2 mt-4'>
                        <a href='/plans' className='text-white text-xl font-semibold'> Ver planes </a>
                    </button>
                </div>
                <div className='w-full flex justify-center mt-32'>
                    <div className='flex flex-col items-center'>
                        <p className='text-mainBlue font-bold text-4xl sm:text-center sm:text-3xl'>¿No sabes cómo comenzar?</p>
                        <p className='w-5/12 text-center text-2xl text-lightGray mt-5 sm:w-full sm:text-lg md:w-full md:text-xl'>Conoce todo a cerca de como comenzar a usar volcano admin, como crear una cuenta y disfrutar de todos los beneficios!</p>
                    </div>
                </div>
                <HomeTutorial/>
                <IdealForSection/>
                <FreeTrial />
            </div>
            <HomeFooter/>
        </div>
    )
}
