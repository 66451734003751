import { Grid, Paper } from "@mui/material";
import { CustomMembership } from "./CustomMembership";
import { CustomPromotions } from "./CustomPromotions";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Notifications } from "../atoms/Notifications";
import { InscriptionPayment } from "../tenants/screens/InscriptionPayment";

export const PriceDefinitions = () => {
  
  const createPromotion = useSelector((state : RootState)=> state?.createPromotion );
  const updatePromotion = useSelector((state : RootState)=> state?.editPromotion);
  const deletePromotion = useSelector((state : RootState)=> state?.deletePromotion);

  const deleteInscriptionPayment = useSelector(( state : RootState)=> state?.deleteInscriptionPayment);
  const updateInscription = useSelector( ( state : RootState )=> state?.updateTenantInscription);
  const postInscription = useSelector( (state : RootState)=> state?.postInscriptionPayment );
  
  return (
    <Grid item width={'100%'}>
      <Paper sx={{ p: 2, gap: 2, display: 'flex', flexDirection: 'column' }}>
        <CustomMembership />
        <CustomPromotions />
        <InscriptionPayment/>
      </Paper>
      <Notifications handler={ createPromotion } actionName='createPromotion' actionType='createIt'/>
      <Notifications handler={ updatePromotion } actionName='updatePromotion' actionType='updateIt'/>
      <Notifications handler={ deletePromotion } actionName='deletePromotion' actionType='deleteIt'/>
      
      <Notifications handler={ postInscription } actionName='inscription' actionType='createIt'/>
      <Notifications handler={ updateInscription } actionName='inscription' actionType='updateIt'/>
      <Notifications handler={ deleteInscriptionPayment } actionName='inscription' actionType='deleteIt'/>
    </Grid>
  );
};