import { useEffect, useState } from 'react';

import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField } from '@mui/material';
import Title from '../atoms/Title';

import { closeModal } from '../store/modal';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';

import { useUsersActions } from '../customhooks/useUsersActions';
import { useForm } from '../customhooks';
import { getAllCustomMembershipThunk, getAllPromotionsThunk } from '../store/usuarios';
import { PromotionForm } from '../promotions/atoms/PromotionForm';
import { usePriceDefination } from '../customhooks/usePriceDefination';
import { TimeZone } from '../interfaces/interfaces';


const style = {
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,

};

const btnStyle = {
  minWidth: '100px',
  fontSize: '13px',
}
const selectStyle = {
  marginBottom: '3px',
  marginTop: '1px'
};

export const FormReNewMembership = () => {
  const [errorCustomMembership, setErrorCustomMembership] = useState(false);
  const { selectPlan } = usePriceDefination();

  const customMembership = useSelector((state: RootState) => state?.customMembership?.customMembership);
  const promotions = useSelector((state: RootState) => state?.getPromotion?.promotions);

  const { onInputChange, formState, onResetForm, setFormState } = useForm({
    planMensualTipo: '',
    planMensualMonto: 0,
    promotion: 0,
  })
  const open = useSelector((state: RootState) => state?.modal?.openModal);
  const userId = useSelector((state: RootState) => state?.saveUserId?.userId);
  const usr = useSelector((state: RootState) => state?.auth);
  const dispatch = useDispatch<AppDispatch>()
  const [ tmpPlanMonto, setTmpPlanMonto ] = useState(0);
   
  useEffect(() => {
    if (promotions?.length === 0) {
      dispatch(getAllPromotionsThunk());
    }
    if (customMembership?.length === 0) {
      dispatch(getAllCustomMembershipThunk());
    }
  }, [dispatch, promotions?.length, customMembership?.length]);

  const { handleRenewMembership } = useUsersActions();
  
  const selectOptions = usr?.role === 'MANAGEMENT-SERVICE-ROLE' ?
    ["mensual", "semestral", "anual"] :
    ["semanal", "quincenal", "mensual", "semestral", "anual", "cuatrimestral","bimestral"];

  const handleClose = () => {
    dispatch(closeModal());
    onResetForm();
  };

  const handleChangeSelectorPlan = (event: SelectChangeEvent<string | number | Function | Boolean | TimeZone | null>) => {
    selectPlan(event, customMembership, setFormState, setErrorCustomMembership);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedFormState = {
      ...formState,
      planMensualMonto: tmpPlanMonto,
    };
    handleRenewMembership(updatedFormState as any, userId)
    dispatch(closeModal())
  }

  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="Renovar membresía" aria-describedby="añade un producto a stock">
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' }}>
          <Box sx={style}>
            <Title>Renovar membresía</Title>
            <FormControl style={selectStyle} fullWidth>
              <InputLabel id="activarPlan-label">Activar Plan de suscripción</InputLabel>
              <Select
                labelId="Plan mensual tipo"
                name="planMensualTipo"
                value={formState?.planMensualTipo}
                onChange={handleChangeSelectorPlan}
              >
                {selectOptions.map(option => (
                  <MenuItem key={option} value={ option }>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              error={errorCustomMembership}
              label="Monto del plan"
              name="planMensualMonto"
              type='number'
              value={formState?.planMensualMonto}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              helperText={errorCustomMembership ? 'No se encontró un plan de membresía personalizado. Por favor, cree uno en "Mi empresa" -> "Definiciones de precios".' : ''}
              InputProps={{
                readOnly: true,
              }}
              required
            />

            <PromotionForm
              promotionState={formState?.promotion as number}
              planMensualMontoSate={formState?.planMensualMonto as number}
              setFormState = { setFormState }
              setTmpPlanMonto ={ setTmpPlanMonto }
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleClose}
                style={btnStyle}
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                style={btnStyle}
              >
                Renovar
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  )
}