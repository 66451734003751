//nueva implementaciòn 

import React, { useMemo, useState } from 'react'
import emailValidator from 'email-validator';
import _ from 'lodash';
import { ERROR_OBJECT, FormState } from '../interfaces/interfaces';

import { DateTime, Duration } from "luxon";
import { ErrorState } from '../types/types';

import { validatePassword } from '../utils/validatefields';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

/**
 * Hook personalizado que maneja la validación de formularios.
 * @param {Object} formState - Estado del formulario.
 * @returns {Object} - Objeto que contiene información sobre errores y funciones de validación.
 * @property {string} error - Captura errores en la dirección de correo electrónico.
 * @property {string} errorPassword - Captura errores en la contraseña.
 * @property {boolean} fieldFormatError - Indica si hay errores en el formato de la dirección de correo electrónico.
 * @property {boolean} passwordFormatError - Indica si hay errores en el formato de la contraseña.
 * @property {string} dateBorn - Mensaje de error si la fecha de nacimiento es futura.
 * @property {boolean} dateBornError - Indica si hay un error en la fecha de nacimiento (futura).
 * @property {Object} errorNumber - Estado de un campo de formulario con el nombre del campo, error: true y un mensaje de error.
 * @property {Function} handleEmail - Función para validar una dirección de correo electrónico.
 * @property {Function} handlePassword - Función para validar una contraseña.
 * @property {Function} dateValidation - Función para validar si una fecha es futura.
 * @property {Function} validateNumber - Función que valida la presencia de números negativos.
 * @property {boolean[]} gralError - Arreglo de booleanos que guarda todos los valores booleanos en true de todos los valores del estado mencionados anteriormente. Se utiliza para deshabilitar botones de envío en formularios.
 */

export const useFormStateValidation =()=>{
  const tenantInscriptionPrice = useSelector((state : RootState)=> state?.getInscriptionRecords?.inscriptions);
  const [gralError, setGralError] = useState<boolean[]>([]);
  const [error, setError] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [diffPass, setDiffPass] = useState('');
  const [passwordFormatError, setpasswordFormatError] = useState(false);
  const [comparePasswordError, setComparePasswordError] = useState(false);
  const [fieldFormatError, setfieldFormatError] = useState(false);
  const [dateBornError, setDateBornError] = useState(false);
  const [dateBorn, setDateBorn] = useState('');
  // const [handleMaxValuesAllowed, sethandleMaxValuesAllowed] = useState<boolean>(false);
  // const [maxAllowedCharAllowed, setmaxAllowedCharAllowed] = useState<string>('');
  const [errorNumber, setErrorNumber] = useState<ErrorState>({
    pagoDeInscripcion: { field: '', error: false, textMessage: '' },
    planMensualMonto: { field: '', error: false, textMessage: '' },
    salarioMensual: { field: '', error: false, textMessage: '' },
    phoneNumber: { field: '', error: false, textMessage: '' },
    total : { field: '', error: false, textMessage: '' }
  });
  const [errorField, setErrorField] = useState<ErrorState>({
    nombre: { field: '', error: false, textMessage: '' },
    descripcion: { field: '', error: false, textMessage: '' },
    nombreEmpresa: { field: '', error: false, textMessage: '' },
    apellidoPaterno: { field: '', error: false, textMessage: '' },
    apellidoMaterno: { field: '', error: false, textMessage: '' },
    direccion: { field: '', error: false, textMessage: '' },
    categoria: { field: '', error: false, textMessage: '' },
    tenantName: { field: '', error: false, textMessage: '' },
    databaseName: { field: '', error: false, textMessage: '' },
    expensiveName : { field : '', error : false , textMessage : ''},
    description : { field: '', error: false, textMessage: '' },
    pagoDeInscripcion : { field: '', error: false, textMessage: '' }
  }); 
  
  const updateGralError = React.useCallback(() => {
    const newGralError: boolean[] = [
      fieldFormatError,
      passwordFormatError,
      dateBornError,
      ...Object.values(errorNumber).map(fieldError => fieldError.error),
      ...Object.values(errorField).map(fieldError => fieldError.error)
    ];
    if (JSON.stringify(newGralError) !== JSON.stringify(gralError)) {      
      setGralError(newGralError);
    }
  }, [fieldFormatError, passwordFormatError, dateBornError, errorNumber, errorField, gralError]);
  
  React.useEffect(() => {
    updateGralError();
  }, [updateGralError]);
  
  const handleEmail = React.useCallback((email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|mx|net|es|org|gov|edu|co|uk|fr|de|app|blog|guru)$/;
    
    if (email && email?.length > 0 && !emailValidator.validate(email)) {
      setfieldFormatError(true);
      setError('La dirección de correo electrónico no es válida !!! ');
    } else if (email && email?.length > 0 && !emailRegex.test(email)) {
      setfieldFormatError(true);
      setError('La dirección de correo electrónico no es válida !!!');
    } else {
      setfieldFormatError(false);
      setError('');
    }
  },[fieldFormatError])

  const handlePassword = React.useCallback((password: string) => {
    const passwordError = validatePassword(password);

    if (passwordError && password.length >1) {
      setpasswordFormatError(true);
      setErrorPassword(passwordError);
    } else {
      setpasswordFormatError(false);
      setErrorPassword('');
    }
  },[passwordFormatError,passwordFormatError])

  const comparePassword = React.useCallback((password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      setComparePasswordError(true);
      setDiffPass('Las contraseñas no coinciden');
    } else {
      setComparePasswordError(false);
    }
  },[comparePasswordError]) 

  const dateValidation = React.useCallback(( date: string ) => {
    const fechaActual = DateTime.now();
    const fechaNacimientoObj = DateTime.fromISO(date);

    if (fechaNacimientoObj.isValid && fechaNacimientoObj.toISODate() === fechaActual.toISODate()) {
      setDateBornError(true);
      setDateBorn('La fecha de nacimiento no puede ser el dia de hoy')
    } else if (fechaNacimientoObj.isValid && fechaNacimientoObj > fechaActual) {
      setDateBornError(true);
      setDateBorn('La fecha de nacimiento no puede ser futura');
    } else {
      setDateBornError(false);
      setDateBorn('');
    }
  },[dateBornError]) 

  /** 
   * @param number 
   * @param field 
   * Toma el estado anterior y actualiza el estado por cada campo sin quitar 
   * el estado anterior
   */
  const validateNumber = React.useCallback( (number: string, field: string): void => {
    const parsedNumber = parseFloat(number);
    setErrorNumber(prevErrors => {
      const newErrors: ErrorState = { ...prevErrors };

      if (_.isNumber(parsedNumber) && parsedNumber < 0) {
        newErrors[field] = {
          field: field,
          error: true,
          textMessage: 'El número debe ser positivo'
        };
      } else {
        newErrors[field] = {
          field: field,
          error: false,
          textMessage: ''
        };
      }

      return newErrors;
    });
  },[errorNumber,gralError])

  const validateField = React.useCallback((field: string, value: string) => {
    setErrorField(prevErrors => {
      const newErrorsField: ErrorState = { ...prevErrors };

      if (value?.length === 1 && value.length <= 2 || value?.length > 256) {
        newErrorsField[field] = {
          field: field,
          error: true,
          textMessage: 'La cantidad de caracteres no debe ser menor de 2 y no mayor a 256 caracteres.',
        };
      } else {
        newErrorsField[field] = {
          field: field,
          error: false,
          textMessage: ''
        };
      }
      return newErrorsField;
    });
  },[errorField,gralError]) 
  
  const inscriptionPaymentValidator = React.useCallback((field: string, value: number) => {
    setErrorField(prevErrors => {
      const newErrorsField: ErrorState = { ...prevErrors };

      if ( value === 0 || value === undefined ) {
        newErrorsField[field] = {
          field: field,
          error: true,
          textMessage: 'No existe la definición del pago de inscripción, Puedes agregar el precio desde la sección Mi empresa >> Definnición de precios >> Pago de inscripción.',
        };
      } else if( tenantInscriptionPrice.length > 0 ){
        newErrorsField[field] = {
          field: field,
          error: false,
          textMessage: ''
        };
      }
      return newErrorsField;
    });
  },[errorField,gralError, tenantInscriptionPrice]) 

  return {
    error,
    errorPassword,
    diffPass,
    comparePassword,
    comparePasswordError,
    fieldFormatError,
    passwordFormatError,
    dateBorn,
    dateBornError,
    errorNumber,
    handleEmail,
    handlePassword,
    dateValidation,
    validateNumber,
    gralError,
    validateField,
    errorField,
    inscriptionPaymentValidator
  }
} 
