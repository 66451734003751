import axios, { AxiosResponse } from 'axios';
import { config } from '../../config';
import { POST_CATEGORY } from '../../interfaces/interfaces';
import { stagingController } from '../../utils/stagingController';
import { developmentConfig } from '../../developmentConfig';

let host = stagingController();
export const getCategorias = async ()=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost)?developmentConfig.REACT_APP_DEVELOPMENT_SERVER : config.REACT_APP_PRODUCTIONSERVER}/api/categoria/all`, { headers })
        .then((response:AxiosResponse) => {            
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{            
            return {
                ok: false,
                error : error?.response?.data?.msg
            };
            
        }) 
}

export const getCategoriaById = async (id:string)=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost)?developmentConfig.REACT_APP_DEVELOPMENT_SERVER : config.REACT_APP_PRODUCTIONSERVER}/api/categoria/${id}`, { headers })
        .then((response:AxiosResponse) => {
            const data = response.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{            
            return {
                ok: false,
                error : error.response.data.msg
            };
            
        }) 
}

export const createCategoryProvider = async (nombre :POST_CATEGORY)=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    const data = {
        nombre
    }
    return await axios.post(`${(host?.localhost)?developmentConfig.REACT_APP_DEVELOPMENT_SERVER : config.REACT_APP_PRODUCTIONSERVER}/api/categoria/create`, JSON.stringify(data),{ headers })
        .then((response:AxiosResponse) => {            
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{                        
            return {
                ok: false,
                error : error.response.data.msg
            };
            
        }) 
}

export const deleteCategoryProvider = async (id :string) => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };

    return await axios.delete(`${(host?.localhost)?developmentConfig.REACT_APP_DEVELOPMENT_SERVER : config.REACT_APP_PRODUCTIONSERVER}/api/categoria/${id}`, { headers })
        .then((response:AxiosResponse) => {
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{
            return {
                ok: false,
                error : error?.response?.data?.msg
            };
            
        })
}

export const updateCategoryProvider = async (id :string, nombre :string) => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    const data = {
        nombre
    }
    return await axios.put(`${(host?.localhost)?developmentConfig.REACT_APP_DEVELOPMENT_SERVER : config.REACT_APP_PRODUCTIONSERVER}/api/categoria/${id}`, JSON.stringify(data),{ headers })
    .then((response:AxiosResponse) => {
        const data = response?.data;
        return {
            ok: true,
            data
        };
    })
    .catch((error) =>{            
        return {
            ok: false,
            error : error?.response?.data?.msg
        };
        
    })
}