import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { DateTime } from 'luxon';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import React, { FormEvent, useState, useEffect, ChangeEvent } from "react";

import { AppDispatch, RootState } from "../store";
import { closeModal } from "../store/modal";
import { sellProductThunk } from "../store/productos/index";

import Title from "../atoms/Title";
import { useForm } from "../customhooks";
import { usePromotion } from "../customhooks/usePromotion";
import { useTicket } from "../sales/customhooks/useTicket";

const style = {
  display: 'flex',
  gap: '15px',
  flexDirection: 'column',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

const btnStyle = {
  minWidth: '100px',
  fontSize: '13px',
}

interface ModalSellProductProps {
  modalType: string
}

const ModalSellProduct = ({ modalType }: ModalSellProductProps) => {
  const { generateTicket } = useTicket();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const [applyPromotion, setApplyPromotion] = useState(false);
  const now = DateTime.now();
  const open = useSelector((state: RootState) => state?.modal?.openModal);
  const currentModalType = useSelector((state: RootState) => state?.modal?.modalType)
  const productById = useSelector((state: RootState) => state?.productById?.producto);
  const promotionsList = useSelector((state: RootState) => state?.getPromotion?.promotions)

  const { onInputChange, formState, onResetForm, setFormState } = useForm({
    serviceIdentifier: 'producto',
    product: productById?._id,
    quantity: '',
    unitPrice: Number(productById?.precio),
    promotionStatus: false,
    discountCaptured: 0,
    promotion: '',
  });

  const disponibilidad = Number(formState?.quantity) > productById?.stock
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    applypromotionHandler,
    setPay,
    handlerSetPromotion,
    totalSinDescuento,
    amount,
    pay
  } = usePromotion();

  const lessThanZero = Number(pay) < amount
  const responsiveStyle = {
    ...style,
    width: isXSmall ? '300px' : isSmall ? '300px' : '600px',
  };


  const handleClose = () => {
    onResetForm()
    setPay('')
    dispatch(closeModal())
  }

  const handleSumbit = async (e: FormEvent) => {
    e.preventDefault();

    if (disponibilidad) return
    if (lessThanZero) return
    if (Number(formState?.quantity) === 0) return

    const success = await dispatch(sellProductThunk(formState, amount))
    if (success?.ok === true) {      
      const userWantsTicket = window.confirm('¿Desea generar un ticket de la venta?');
      if( userWantsTicket ){
        generateTicket(
          productById?.nombre,
          Number(productById?.precio),
          Number(formState?.quantity),
          applyPromotion ? Number( formState?.discountCaptured ) : undefined,
          amount,
          Number(pay),
          Number(pay) - amount
        );
      }
      onResetForm()
      setPay('')
      handleClose()
    }
  }

  const handlerSelectedPromotion = (event: SelectChangeEvent<string>) => {
    handlerSetPromotion(event.target.value, promotionsList, setFormState);
  }

  const handlePromotionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setApplyPromotion(event.target.checked);
  };

  useEffect(() => {
    if (productById && formState.quantity) {
      applypromotionHandler(productById, Number(formState.quantity), formState.promotion as string, promotionsList, applyPromotion);
    }
  }, [applyPromotion, formState.promotion, formState.quantity, productById?.precio, promotionsList]);

  useEffect(() => {
    if (productById) {
      onResetForm();
    }
  }, [productById])


  return (
    <>
      {
        modalType === currentModalType && (
          <Modal open={open} onClose={handleClose} aria-labelledby="Vender producto" aria-describedby="vende un producto">
            <form onSubmit={handleSumbit} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' }}>
              <Box sx={responsiveStyle}>
                <Title>VENDER {productById?.nombre}</Title>

                <TextField
                  error={disponibilidad}
                  fullWidth
                  label="Cantidad del producto a vender"
                  name='quantity'
                  type='text'
                  helperText={disponibilidad ? 'Excede el stock disponible. Ajusta la cantidad.' : ''}
                  value={formState?.quantity}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    // Permitir solo números con hasta dos decimales y no negativos.
                    if (value === '' || /^[0-9]*\.?[0-9]{0,2}$/.test(value)) {
                      onInputChange(e);
                    }
                  }}
                  variant="outlined"
                  required
                  InputProps={{
                    inputProps: {
                      min: 0
                    },
                    onKeyDown: (event) => {
                      // Permitir solamente números y el punto
                      if (
                        (event.key === '-' || event.key === 'e' || event.key === '+') || // No permitir 'e', '+', '-'
                        (event.key === '.' && event.currentTarget.value.includes('.')) // No permitir múltiples puntos
                      ) {
                        event.preventDefault();
                      }
                    }
                  }}
                />

                <TextField
                  error={pay.length > 0 && lessThanZero}
                  fullWidth
                  label="Cantidad con la que pagará"
                  name="pay"
                  type='text'
                  variant='outlined'
                  value={pay}
                  helperText={(pay.length > 0 && lessThanZero) ? 'La cantidad pagada no puede ser menor al total a pagar.' : ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    if (value === '' || /^(\d+\.?\d{0,2}|\.\d{0,2})$/.test(value)) {
                      setPay(value);
                    }
                  }}
                  onBlur={() => {
                    if (pay !== '') {
                      setPay(Number(pay).toFixed(2));
                    }
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />

                <Divider />

                <Box>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography>Precio unitario</Typography>
                    <Typography>${productById?.precio}</Typography>
                  </Box>
                  <Box display='flex' justifyContent='space-between'>
                    {(applyPromotion && formState?.discountCaptured !== 0) ? <Typography
                      sx={{ textDecorationLine: 'line-through' }}
                    >Total sin descuento
                    </Typography> :
                      <Typography>
                        Total
                      </Typography>}

                    {(applyPromotion && formState?.discountCaptured !== 0) ? <Typography sx={{ textDecorationLine: 'line-through', color: 'red' }}>${totalSinDescuento?.toFixed(2)}</Typography> : <Typography>${amount?.toFixed(2)}</Typography>}
                  </Box>
                  <Box display='flex' justifyContent='space-between'>
                    {(applyPromotion && formState?.discountCaptured !== 0) && <Typography sx={{ color: 'green', fontWeight: '600' }}> - {formState?.discountCaptured} % de descuento paga solo :</Typography>}
                    {(applyPromotion && formState?.discountCaptured !== 0) && <Typography sx={{ color: 'green', fontWeight: '600' }}>${amount?.toFixed(2)}</Typography>}
                  </Box>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography>Cambio</Typography>
                    {(applyPromotion && formState?.discountCaptured !== 0) ? <Typography>${(pay ? Number(pay) - amount : 0).toFixed(2)}</Typography> : <Typography>${(pay ? Number(pay) - amount : 0).toFixed(2)}</Typography>}
                  </Box>
                </Box>


                <FormControlLabel
                  control={<Checkbox checked={applyPromotion} onChange={handlePromotionChange} />}
                  label="Aplicar promoción"
                  style={{ marginTop: '20px' }}
                />
                {applyPromotion && (
                  <FormControl fullWidth required style={{ marginTop: '20px' }}>
                    <InputLabel id="promotion-label">Promoción</InputLabel>
                    <Select
                      labelId="promotion-label"
                      name="promotion"
                      value={formState.promotion as any}
                      onChange={handlerSelectedPromotion}
                    >
                      {promotionsList
                      .filter((promotion) => {
                        const expirationDate = DateTime.fromISO(promotion?.expirationDate as string);
                        return expirationDate > now;
                      })
                      .map((promotion) => (
                        <MenuItem key={promotion?._id} value={promotion?._id}>
                          {promotion?.name} ({promotion?.discount}%)
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <Divider />

                <Box display='flex' justifyContent='space-between'>
                  <Button
                    variant="outlined"
                    color="error"
                    size="large"
                    style={btnStyle}
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={btnStyle}
                    disabled={disponibilidad || lessThanZero}
                  >
                    Vender
                  </Button>
                </Box>
              </Box>
            </form>
          </Modal>
        )
      }
    </>
  )
}

export default ModalSellProduct;