import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { useEffect, useState } from "react";
import { getAllProductosThunk } from "../../store/productos";
import { getDetailsInfo } from "../../store/slides/Auth";
import { config } from "../../config";
import { ADMIN_USER, PRODUCTOS } from "../../interfaces/interfaces";

export const useRevenueProyection = () => {
    let notValidUsers = [config?.CONST_SUPER_ADMIN, config?.CONST_CLIENTE, config.CONST_MANAGEMENT_SERVICE]
    const [employeeCost, setEmployeeCost] = useState<number>(0);
    const [ expenseRecordCost , setExpenseRecordValue ] = useState<number>(0);
    const [stockRevenue, setStockRevenue] = useState<number>(0);
    const [totalRevenue, setTotalRevenue] = useState<number>(0);
    const [totalStock, setTotalStock] = useState<number>(0);

    const employees = useSelector((state: RootState) => state?.detailsinfo?.usuarios);
    const products = useSelector((state: RootState) => state?.productos?.productos);
    const expenseRecords = useSelector((state : RootState)=> state?.getExpenses?.expense);
    const employeeActions = useSelector((state: RootState) => state?.adminRegister?.status);
    const deleteUser = useSelector((state: RootState) => state?.deletedUsers?.status);
    const updateUser = useSelector( (state: RootState) => state?.editUser?.status );
    useEffect(() => {
        getDetailsInfo();
        getAllProductosThunk();
    }, [updateUser === "success", employeeActions === "success", employees?.length, products?.length]);
    useEffect(() => {
        if (products?.length) {
            calculateRevenue(products);
        }
    }, [products]);

    useEffect(() => {
        if (employees?.length) {
            calculateEmployeeCosts(employees);
        }
    }, [employees?.length, employeeActions, deleteUser, updateUser]);

    useEffect(() => {
        if (expenseRecords?.length) {
            calculateExpenseRecordsCost( expenseRecords );
        }
    }, [expenseRecords?.length]);

    useEffect(() => {
        calculateTotalRevanue();
    }, [stockRevenue, employeeCost]);

    const calculateRevenue = (products: PRODUCTOS[]) => {
        let total = 0;
        let totalStock = 0;
        const productFiltered = products.filter(product => product?.stock > 0);
        for (let product of productFiltered) {
            total += product?.precio * product?.stock
            totalStock += product?.stock
        }
        setStockRevenue(total);
        setTotalStock(totalStock);
    }
    const calculateEmployeeCosts = (employees: any) => {
        let total: number = 0;
        let users = employees.filter((user: ADMIN_USER) => !notValidUsers.includes(user?.role));        
        for (let employee of users) {
            let salarioMensualStr = Number(employee?.salarioMensual);            
            let salarioMensual = salarioMensualStr !== undefined && salarioMensualStr !== null ? parseFloat(salarioMensualStr) : NaN;

            if (!isNaN(salarioMensual)) {
                total += salarioMensual;                
            } else {
                console.error(`El salario mensual no es un número válido para el empleado`, employee);
            }
        }

        setEmployeeCost(total);
    }
    const calculateExpenseRecordsCost =( expenseRecords : any)=>{
        const total = expenseRecords.reduce((acc : number, expense : any) => {
            return acc + (expense?.total ?? 0);
        }, 0);
        setExpenseRecordValue(total);
    }
    const calculateTotalRevanue = () => {        
        const totalRevenue = stockRevenue ?? 0;
        const totalEmployeeCost = employeeCost ?? 0;
        const totalExpenses = expenseRecordCost ?? 0;

        const total = totalRevenue - totalEmployeeCost - totalExpenses;
        setTotalRevenue(total);
    }

    return {
        calculateEmployeeCosts,
        calculateRevenue,
        calculateTotalRevanue,
        calculateExpenseRecordsCost,
        employeeCost,
        stockRevenue,
        totalRevenue,
        totalStock,
        expenseRecordCost
    }
}