import { createSlice } from "@reduxjs/toolkit";
import { NOTIFICATIONS } from "../../../interfaces/interfaces";

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: [] as NOTIFICATIONS[],
  reducers: {
    addNotification: (state, { payload }) => {
      state.push(payload);
    },
    updateNotificationStatus: (state, { payload }) => {
      const { id, status } = payload
      const notificationUpdate = state.find(notification => notification?.id === id)
      if (notificationUpdate) { notificationUpdate.status = status }
    }
  }
})

export const { addNotification, updateNotificationStatus } = notificationSlice.actions