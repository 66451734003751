import { createSlice } from "@reduxjs/toolkit"

export const tenantInscriptionPriceSlide = createSlice({
  name: "tenantInscriptionPrice",
  initialState: {
    status: "initial",
    msg: null,
    price: null,
    BackendErrorMessage: null,
  },
  reducers: {
    failedCreateInscription: (state, { payload }) => {
      state.status = "failed";
      state.price = null;
      state.BackendErrorMessage = payload;
    },
    createdInscriptionRecord: (state, { payload }) => {
      state.status = "success";
      state.msg = payload.msg;
      state.price = payload.price;
      state.BackendErrorMessage = null;
    },
    creatingInscription: (state) => {
      state.status = "in-progress";
    },
  },
})

export const { failedCreateInscription, createdInscriptionRecord, creatingInscription } = tenantInscriptionPriceSlide.actions