import { createSlice } from '@reduxjs/toolkit';

export const createExpenseRecordSlide = createSlice({
    name : "postExpense",
    initialState : {
        status : 'initial-state',
        msg : null,
        expensiveName : null,
        category: null,
        description:null,
        registerDate:null,
        total:null,
        img : null,
        BackendErrorMessage : null
    },
    reducers : {
        failedCreateExpense: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.msg = null;
            state.expensiveName = null;
            state.category = null;
            state.description = null;
            state.registerDate = null;
            state.total = null;
            state.img = null;
            state.BackendErrorMessage = payload;            
        },
        expenseCreated: ( state, { payload } ) => {
            state.status = 'success';
            state.msg = payload.message;
            state.expensiveName = payload.expensiveName;
            state.category = payload.category;
            state.description = payload.description;
            state.registerDate = payload.registerDate;
            state.total = payload.total;
            state.img = payload.img;
            state.BackendErrorMessage = null;
        },
        creatingExpense : ( state )=>{
            state.status = 'in-progress';
        }
    }
});

export const { failedCreateExpense,expenseCreated,creatingExpense} = createExpenseRecordSlide.actions;