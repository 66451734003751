import { Box, Button, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';

import type { CustomMembershipProps } from '../interfaces/interfaces';

import { formatText } from '../utils/formatText';

import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { openModal } from '../store/modal';
import { config } from '../config';

export const CustomMembershipCard = ({ customMembership, setIdEdit }: CustomMembershipProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleAdd = () => {
    dispatch({
      type: openModal.type,
      payload: config.MODAL_CREATE_CUSTOM_MEMBERSHIP
    })
  }

  const handleEdit = (id: string) => {
    setIdEdit(id);
    dispatch({
      type: openModal.type,
      payload: config.MODAL_EDIT_CUSTOM_MEMBERSHIP
    })
  }

  return (
    <Box sx={ { display: 'flex', flexDirection: 'column', p: 3 } }>
      <Box sx={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 } }>
        <Typography variant="h5" component="div" color="primary">Definición de membresías</Typography>
        <Button
          variant="outlined"
          color="primary"
          startIcon={ <AddIcon /> }
          onClick={ handleAdd }
        >
          Añadir
        </Button>
      </Box>

      { customMembership.length > 0 ? (
        <Box sx={ { display: 'flex', flexWrap: 'wrap', gap: 2 } }>
          { customMembership.map((membership, index) => (
            <Card
              key={ index }
              sx={ {
                width: { xs: '100%', sm: 'calc(50% - 16px)', md: 'calc(33.333% - 16px)' },
                boxShadow: 1,
                borderRadius: 2,
              } }
            >
              <CardContent>
                <CardHeader 
                  title={ 
                    <Typography
                      variant="h5"
                      color="primary"
                      sx={ {
                        fontWeight: 'bold',
                        textAlign: 'center',
                        mb: 2
                      } }
                    >
                      { formatText(membership?.typePlan) }
                    </Typography>
                  }
                  subheader={
                    <Typography
                      variant="h4"
                      sx={ {
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: 'black',
                      } }
                    >
                      ${ membership?.price }
                    </Typography>
                  }
                  sx={ { textAlign: 'center' } }
                  action={
                    <IconButton
                      onClick={ () => handleEdit(membership._id) }
                      sx={ { color: '#8BC34A' } }
                    >
                      <EditIcon />
                    </IconButton>
                  }
                />
              </CardContent>
            </Card>
          )) }
        </Box>
      ) : (
        <Typography>No hay membresías definidas</Typography>
      ) }
    </Box>
  )
}
