import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';

import { HomeToolsItem } from './HomeToolsItem';
import seguimiento from '../img/seguimiento.svg';
import gestion from '../img/gestion.svg';
import analisis from '../img/analisis.svg';
import seguridad from '../img/seguridad.png';
import report from '../img/report.svg';
import decrease from '../img/decimal_decrease.svg';
import robot from '../img/robot.svg';
import bussiness from '../img/bussiness.svg';
import notifications from '../img/notifications.svg';
export const HomeTools = () => {
  return (
    <div className="mt-20">
      <Swiper
        spaceBetween={25}
        slidesPerView={1}
        autoplay={{
          delay: 3000, 
          disableOnInteraction: false, 
        }}
        loop={true}
        modules={[Autoplay]}
        className="w-full"
        breakpoints={{
          640: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 4 },
        }}
      >
        <SwiperSlide>
          <HomeToolsItem
            image={seguimiento}
            title="Seguimiento y control de clientes"
            text="Controla el acceso de tus clientes a tu negocio y recibe notificaciones de planes vencidos y próximos a vencer"
          />
        </SwiperSlide>
        <SwiperSlide>
          <HomeToolsItem
            image={gestion}
            title="Gestión y control de empleados"
            text="Permite a tus empleados la creación de sesiones de entrenamiento o eventos para tus clientes"
          />
        </SwiperSlide>
        <SwiperSlide>
          <HomeToolsItem
            image={analisis}
            title="Análisis de datos"
            text="Todo el desempeño de tu negocio en la palma de tu mano."
          />
        </SwiperSlide>
        <SwiperSlide>
          <HomeToolsItem
            image={ robot }
            title="Reporte de ventas"
            text="Análiza cuáles son tus 10 productos más vendidos y mide la cantidad de ingresos que tendrás al vender tu stock de productos después de gastos."
          />
        </SwiperSlide>
        <SwiperSlide>
          <HomeToolsItem
            image={seguridad}
            title="Seguridad"
            text="Tu información está segura con nosotros. Toda la comunicación está cifrada bajo los mejores estándares de seguridad en el mercado."
          />
        </SwiperSlide>
        <SwiperSlide>
          <HomeToolsItem
            image={decrease}
            title="Rastreo de gastos de la empresa"
            text="Registra cada uno de tus gastos y mide el rendimiento de tu negocio para ver tu retorno de inversión."
          />
        </SwiperSlide>
        <SwiperSlide>
          <HomeToolsItem
            image={ bussiness }
            title="Promociones"
            text="Registra y aplica promociones sobre cualquier venta de producto o servicio."
          />
        </SwiperSlide>
        <SwiperSlide>
          <HomeToolsItem
            image={ report }
            title="Reportes de ventas y gastos"
            text="Exporta desde el sistema toda tu data de ventas y gastos, permitiéndote manipularla posteriormente desde un archivo excel."
          />
        </SwiperSlide>
        <SwiperSlide>
          <HomeToolsItem
            image={ notifications }
            title="Notificaciones"
            text="No importa dónde estés. Recibe notificaciones en el sistema sobre todo lo que pasa en tu negocio, así como cualquier intento de acceso sospechoso a tus cuentas."
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
