import React, { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Modal,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';

import { closeModal } from '../store/modal';
import { AppDispatch, RootState } from '../store';
import { useForm } from '../customhooks';

import Title from '../atoms/Title';
import { createPromotionThunk } from '../store/usuarios'; 

interface CustomPromotionModalProps {
  modalType: string;
}

const style = {
  display: 'flex',
  flexDirection: 'column' as const,
  gap: 2,
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 'auto',
    sm: 800,   
  },
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

export const CustomPromotionModal = ({ modalType }: CustomPromotionModalProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const open = useSelector((state: RootState) => state?.modal.openModal);
  const modalTypeStore = useSelector((state: RootState) => state?.modal.modalType);

  const { onInputChange, formState, onResetForm } = useForm({
    name: '',
    discount: '',
    startDay: '',
    expirationDate: '',
    status: true,
  });

  const handleClose = () => {
    dispatch(closeModal());
    onResetForm();
  }

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      onInputChange(e);
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    
    const useToResetForm = await dispatch(createPromotionThunk(formState))

    if (useToResetForm?.ok === true) {
      onResetForm();
      dispatch(closeModal());
    }
  }
  
  return (
    <>
      {
        modalType === modalTypeStore && (
          <Modal open={ open } onClose={ handleClose } aria-labelledby="Crear promoción" aria-describedby="crea una promoción nueva">
            <form onSubmit={ handleSubmit }>
              <Box sx={ style }>
                <Title>Nueva Promoción</Title>

                <TextField
                  label="Nombre"
                  name="name"
                  value={ formState.name }
                  onChange={ onInputChange }
                  fullWidth
                  margin="normal"
                  required
                />

                <TextField
                  label="Descuento"
                  name="discount"
                  value={ formState.discount }
                  onChange={ handleDiscountChange }
                  fullWidth
                  margin="normal"
                  required
                  type="number"
                  inputProps={ { min: 0 } }
                />
                 <TextField
                  label="Fecha de Inicio"
                  type="date"
                  name="startDay"
                  value={ formState.startDay }
                  onChange={ onInputChange }
                  fullWidth
                  margin="normal"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  label="Fecha de Expiración"
                  type="date"
                  name="expirationDate"
                  value={ formState.expirationDate }
                  onChange={ onInputChange }
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <Box sx={ { display: 'flex', justifyContent: 'space-between' } }>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={ handleClose }
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Añadir
                  </Button>
                </Box>
              </Box>
            </form>
          </Modal>
        )
      }
    </>
  )

}
