import * as React from 'react';
import { Outlet } from "react-router-dom"
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';

import { adminSection, superAdminSection, expertoSection, secondaryListItems } from '../atoms/ListItem';
import { AppBar } from '../atoms/AppBar'
import { Copyright } from '../atoms/Copyright'
import { Drawer } from '../atoms/Drawer';
import { Popover } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { logout } from '../store/slides/authSlice';
import { getAllUsersThunk, getManagementService } from '../store/slides/Auth';
import { toUpperCamelCase } from '../utils/upperCaseTransform';
import Title from '../atoms/Title';
import { DateTime } from 'luxon';

import { NOTIFICATIONS } from '../interfaces/interfaces';

<>
  <Copyright />
  <AppBar />
  <Drawer />
</>

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Navbar() {
  /**Notification service */
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<HTMLElement | null>(null);

  const users = useSelector(( state : RootState )=> state?.getAllUserSlice?.usuarios);

  // Ordenar las notificaciones por fecha
  const { role, nombreEmpresa, tenant } = useSelector((state: RootState) => state.auth);

  const notificationsSocket: NOTIFICATIONS[] = useSelector((state: RootState) => state?.notification);
  const tenantNotifications = notificationsSocket.filter(notification => notification?.content?.tenant === tenant || !notification.content?.tenant);
  const sortedNotifications = notificationsSocket
    .filter(notification => notification?.content?.tenant === tenant || !notification.content?.tenant)
    .slice()
    .sort((a, b) => {
      const dateA = DateTime.fromISO(a.date);
      const dateB = DateTime.fromISO(b.date);
      return dateB.toMillis() - dateA.toMillis();
    });

  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
  };
  /**notification */
  const handleNotificationsClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationsAnchorEl(event.currentTarget);
    setNotificationsOpen(!notificationsOpen);
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
    setNotificationsOpen(false);
  };
  const handleNotificationsOpen = () => {
    /**load notification from sockets io or API rest here */
    // Ejemplo:
    // const fetchedNotifications = await fetchNotifications(); 
    // setNotifications(fetchedNotifications);
  };
  React.useEffect(() => {
    dispatch(getAllUsersThunk);
    handleNotificationsOpen();
  }, [ users?.length ])
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {
                `${nombreEmpresa && toUpperCamelCase(nombreEmpresa)}`
              }
            </Typography>
            <IconButton color="inherit" onClick={(e) => handleNotificationsClick(e)}>
              <Badge badgeContent={ tenantNotifications?.length } color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton style={{ marginLeft: 2 }} onClick={handleClick}>
              {/* logout */}
              <Badge color="secondary">
                <LogoutIcon style={{ color: 'white' }} />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            { role === 'ADMIN' && adminSection }
            { (role === 'SUPER_ADMIN' || role === 'MANAGEMENT-SERVICE-ROLE') && superAdminSection }
            { role === 'EXPERTO_EN_EL_AREA' && expertoSection }
            <Divider sx={{ my: 1 }} />
            { role !== 'EXPERTO_EN_EL_AREA' && secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,            
            minHeight: '100vh',
            overflow: 'auto'
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 4, mb: 4 , maxWidth : '100vw!important' }}>
            <Grid container spacing={3}>
              {/* start Body pintar las rutas hijas aqui */}
              <Outlet />
              {/* <Dashboard/> */}
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus={false}
      >
        <Button onClick={handleLogout} style={{ textTransform: 'none' }}>
          Salir
        </Button>
      </Popover>
      {/* notifications pop over */}
      <Popover
        open={notificationsOpen}
        anchorEl={notificationsAnchorEl}
        onClose={handleNotificationsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableRestoreFocus={false}
        sx={{
          minWidth: '100px',
          overflowY: 'auto',
        }}
      >
        <Box paddingX='20px' paddingTop='10px'>
          <Title>Notificaciones</Title>
        </Box>
        {/* Renderiza las notificaciones en el contenido del Popover */}
        { notificationsSocket?.length === 0 && (
          <Box padding='20px'>
            No hay notificaciones
          </Box>
        )}
        { sortedNotifications?.map((notification, index) => (
          <Box key={index}>
            <Divider />
            <Box
              key={ index }
              display='flex'
              flexDirection='column'
              gap='10px'
              padding='20px'
              sx={{'&:hover': { backgroundColor: defaultTheme.palette.grey[200], cursor: 'default'}}}
            >
              <Typography variant='body1'>
                { notification?.message }
              </Typography>
              <span style={{ color: defaultTheme.palette.primary.main }}>{ DateTime.fromISO(notification?.date).toRelative() }</span>
            </Box>
          </Box>
        ))}
      </Popover>
    </ThemeProvider>
  );
}
