import { createSlice } from '@reduxjs/toolkit';



export const updateExpenseSlice = createSlice({
    name : "update-expense",
    initialState : {
        status : 'initial-state',
        msg : null,
        expensiveName : null,
        description : null,
        registerDate : null,
        total : null,
        img : null,
        category : null,
        BackendErrorMessage: null
    },
    reducers : {
        failedUpdateExpense: ( state , { payload }) => {
            state.status = 'failed'; 
            state.msg = null;
            state.expensiveName = null;
            state.category = null;
            state.description = null;
            state.registerDate = null;
            state.total = null;
            state.img = null;
            state.BackendErrorMessage = payload
        },
        expenseUpdated: ( state, { payload } ) => {
            state.status = 'success'; 
            state.msg = payload.message;
            state.expensiveName = payload.expensiveName;
            state.category = payload.category;
            state.description = payload.description;
            state.registerDate = payload.registerDate;
            state.total = payload.total;
            state.img = payload.img
        },
        updatingExpense : ( state )=>{
            state.status = 'in-progress';
        }
    }
});

export const { failedUpdateExpense,expenseUpdated,updatingExpense } = updateExpenseSlice.actions;