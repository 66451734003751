import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/notifications";
import socketIOClient from "socket.io-client";

import { config } from "../config";
import { developmentConfig } from "../developmentConfig";
import { stagingController } from "../utils/stagingController";
import { EVENTS, GET_PRODUCT_BY_ID, SALES_RECORDS } from "../interfaces/interfaces";

interface SocketData {
  message: string;
  content: GET_PRODUCT_BY_ID | SALES_RECORDS | EVENTS
}

export const useSocketIo = () => {
  const host = stagingController();
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    status: false,
    type: ''
  });

  const eventsByType = [
    {
      type: 'product',
      events: ['created_product', 'removed_product', 'updated_product']
    },
    {
      type: 'client',
      events: ['created_client', 'removed_user', 'updated_client']
    },
    {
      type: 'admin',
      events: ['created_admin', 'removed_user', 'updated_admin']
    },
    {
      type: 'event',
      events: ['created_event', 'removed_event', 'event_updated']
    },
    {
      type: 'category',
      events: ['created_category', 'removed_category', 'updated_category']
    },
    {
      type: 'sale',
      events: ['created_sale']
    }
  ];

  useEffect(() => {
    const socket = socketIOClient(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}`);

    const handleSocketEvent = (data: SocketData, type: string) => {
      if (type === 'sale' || type === 'product' || type === 'event' || type === 'category' || type === 'client' || type === 'admin') {
        const newNotification = {
          id: Date.now(), // Generar un ID único
          message: data.message,
          date: new Date().toISOString(),
          content: data.content,
          status: 'unread'
        };
        dispatch(addNotification(newNotification));
      }

      setResponse(prevResponse => ({
        ...prevResponse,
        status: true,
        type: type
      }));
    };

    eventsByType.forEach(item => {
      item.events.forEach(event => {
        socket.on(event, data => {
          handleSocketEvent(data, item.type);
        });
      });
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch ]);

  return response;
};
