import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { DateTime } from 'luxon';
import { extendedPromotions } from "../../interfaces/interfaces";
import { usePromotion } from "../../customhooks/usePromotion";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ChangeEvent, useEffect, useState } from "react";
import { selectStyle } from "../../constants/ConstantsFlexSolutionManager";

export const PromotionForm = ({ promotionState , planMensualMontoSate , setFormState , setTmpPlanMonto }: extendedPromotions) => {
    const now = DateTime.now();
    const [applyPromotion, setApplyPromotion] = useState(false);
    const promotions = useSelector((state: RootState) => state?.getPromotion?.promotions);
    
    const {
        totalConDescuento,
        applyPromotionOnUserRegistration,
        handlerSetPromotion,
        tmpPlanMonto
    } = usePromotion();
    
    const handlePromotionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setApplyPromotion(event?.target?.checked);
    };
    const handlerSelectedPromotion = (event: SelectChangeEvent<string>) => {
        handlerSetPromotion(event.target.value, promotions, setFormState);
    }

    useEffect(() => {
        applyPromotionOnUserRegistration(
            applyPromotion,
            promotionState,
            promotions,
            0,
            planMensualMontoSate
        );
        setTmpPlanMonto( tmpPlanMonto );
    }, [ applyPromotion, promotionState, planMensualMontoSate , tmpPlanMonto , setTmpPlanMonto]);

    return (
        <>
            <FormControlLabel
                control={<Checkbox checked={applyPromotion} onChange={handlePromotionChange} />}
                label="Aplicar promoción"
                style={{ marginTop: '20px' }}
            />
            {applyPromotion && (
                <FormControl fullWidth required style={selectStyle}>
                    <InputLabel id="promotion-label">Promoción</InputLabel>
                    <Select
                        labelId="promotion-label"
                        name="promotion"
                        value={promotionState as any}
                        onChange={handlerSelectedPromotion}
                    >
                        {promotions
                            .filter((promotion) => {
                                const expirationDate = DateTime.fromISO(promotion?.expirationDate as string);
                                return expirationDate > now;
                            })
                            .map((promotion) => (
                                <MenuItem key={promotion._id} value={promotion._id}>
                                    {promotion.name} ({promotion.discount}%)
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            )}
            <Typography variant="h6" align="center" style={{ marginTop: '20px' }}>
                Total: ${totalConDescuento.toFixed(2)}
            </Typography>
        </>

    )
}