import { FormEvent, useEffect } from 'react';

import { FormProductUpdateProps } from '../interfaces/interfaces';
import { useForm } from '../customhooks/useForm';

import Title from '../atoms/Title';
import { SnackBar } from '../atoms/SnackBar';
import { useFormStateValidation } from '../customhooks/useFormStateValidation';
import { Box, Button, Modal, TextField, Select, FormControl, InputLabel, MenuItem } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { startProductUpdateThunk } from '../store/productos/index';
import { AppDispatch, RootState } from '../store/store';
import { closeModal } from '../store/modal';
import { formsStyles , formContainerStyles , textFieldStyle} from '../constants/ConstantsFlexSolutionManager';



export const FormProductUpdate = ({ uidProduct, modalType }: FormProductUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const open = useSelector((state: RootState) => state?.modal?.openModal);
  const currentModalType = useSelector((state: RootState) => state?.modal?.modalType)
  const producById = useSelector((state: RootState) => state?.productById?.producto);
  const categories = useSelector((state: RootState) => state?.categories?.categorias);

  const { onInputChange, formState, onResetForm } = useForm({
    nombre: producById?.nombre,
    categoria: producById?.categoria ? producById?.categoria._id : '',
    descripcion: producById?.descripcion,
    precio: producById?.precio,
    stock: producById?.stock,
  });

  const { error, errorField, errorNumber, validateNumber, gralError, validateField } = useFormStateValidation();

  useEffect(() => {
    if (producById) {
      onResetForm();
    }
    validateNumber(formState?.stock as string, 'stock');
    validateField('nombre', formState?.nombre as string);
    validateField('categoria', formState?.categoria as string);
    validateField('descripcion', formState?.descripcion as string);
  }, [
    producById
  ]);

  const handleClose = () => {
    dispatch(closeModal());
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const useToResetForm = await dispatch(startProductUpdateThunk(formState, uidProduct));
    if (useToResetForm?.ok === true) {
      onResetForm();
      handleClose();
    }
  };

  return (
    <>
      {
        modalType === currentModalType && (
          <Modal open={open} onClose={handleClose} aria-labelledby="Actualizar producto" aria-describedby="edita un producto">
            <Box component='form' onSubmit={handleSubmit} sx={{ formContainerStyles }}>
              <Box sx={ formsStyles }>
                <Title>Actualizar datos de un producto</Title>

                <TextField
                  fullWidth
                  label="Nombre del Producto"
                  name='nombre'
                  value={formState?.nombre}
                  onChange={onInputChange}
                  variant="outlined"
                  style={textFieldStyle}
                  required
                  error={errorField?.nombre?.error}
                  helperText={errorField?.nombre?.textMessage}
                />
                <FormControl fullWidth>
                  <InputLabel id="categoria">Categoría</InputLabel>
                  <Select
                    labelId="categoria"
                    id="categoria"
                    defaultValue={formState?.categoria}
                    value={formState?.categoria}
                    label="Categoria"
                    name="categoria"
                    onChange={onInputChange as any}
                    required
                    error={errorField?.categoria?.error}
                    helperText={errorField?.categoria?.textMessage}
                  >
                    {categories.map(category => (
                      <MenuItem key={category._id} value={category._id}>{category.nombre}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Descripción"
                  name="descripcion"
                  value={formState?.descripcion}
                  onChange={onInputChange}
                  variant="outlined"
                  multiline
                  rows={4}
                  style={textFieldStyle}
                  required
                  error={errorField?.descripcion?.error}
                  helperText={errorField?.descripcion?.textMessage}
                />
                <TextField
                  fullWidth
                  label="Precio"
                  name="precio"
                  value={formState?.precio}
                  onChange={onInputChange}
                  variant="outlined"
                  type="number"
                  style={textFieldStyle}
                  required
                />
                <TextField
                  fullWidth
                  label="Stock"
                  name="stock"
                  value={formState?.stock}
                  onChange={onInputChange}
                  variant="outlined"
                  type="number"
                  style={textFieldStyle}
                  required
                  error={errorNumber?.stock?.error}
                  helperText={errorNumber?.stock?.textMessage}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={textFieldStyle}
                  fullWidth
                  disabled={gralError.includes(true)}
                >
                  ACTUALIZAR
                </Button>
              </Box>
            </Box>
          </Modal>
        )
      }
    </>
  );
}
