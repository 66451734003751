import React, { useState, MouseEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PRODUCTOS_MENU_PROPS } from '../interfaces/interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { config } from '../config';


function ProductosActions({
  onEdit,
  onDelete,
  onSell,
  canSell
}: PRODUCTOS_MENU_PROPS) {
  const auth = useSelector((state : RootState)=> state?.auth?.role); 
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="User Actions"
        aria-controls="user-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="user-actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onEdit}>Actualizar</MenuItem>
        {auth !== config?.CONST_ADMIN && <MenuItem onClick={onDelete}>Borrar</MenuItem>}
        <MenuItem disabled={canSell} onClick={onSell}>Vender</MenuItem>
      </Menu>
    </>
  );
}

export default ProductosActions;
