import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { addNotification } from '../store/notifications';
import { NOTIFICATIONS } from '../interfaces/interfaces';

/**
 * @description Used to dispatch all the initial notification
 * Content all initial requirements 
 * @returns Initial notifications 
 */
export const InitialConfigNotifications = React.memo(
  () => {
    const dispatch = useDispatch();
  
    const inscription = useSelector((state: RootState) => state?.getInscriptionRecords?.inscriptions);
    const promotions = useSelector((state: RootState) => state?.getPromotion?.promotions);
    const customMembership = useSelector((state: RootState) => state?.customMembership?.customMembership);
    const notificationsSocket: NOTIFICATIONS[] = useSelector((state: RootState) => state?.notification);
    const tenantNotifications = notificationsSocket.filter(notification => !notification.content?.tenant );

    const handleNotification = (message: string) => {
      const notification = {
        id: Date.now(),
        message,
        date: new Date().toISOString(),
        status: "unread",
      };
      const notificationExists = tenantNotifications.find(
        (element) => element?.message === notification?.message
      );
      if (!notificationExists) {
        dispatch(addNotification(notification));
      }
    };
  
    useEffect(() => {
      if (!inscription || inscription?.length === 0) {
        handleNotification(
          'Hola bienvenido a Volcano flex admin, Antes de empezar es necesario hacer algunas configuraciones iniciales sobre el apartado Mi empresa>Definición de precios.'
        )
      }
      if (!inscription || inscription?.length === 0) {
        handleNotification(
          'Es necesario definir los costos de inscripción de su gimnasio o servicio antes de registrar cualquier usuario.'
        )
      }
      handleNotification(
        'Para brindarte una mayor experiencia. Consulta nuestro canal de YouTube "Volcano Flex Admin" para aprender más sobre el sistema.'
      );
  
      handleNotification(
        "Cualquier duda o consulta, contáctenos en info@volcanoc.com. donde con gusto te atenderemos"
      );
  
    }, [dispatch, inscription, promotions, customMembership]);
  
    return null;
  }
);
