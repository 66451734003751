import { createSlice } from '@reduxjs/toolkit';
import { EXPENSE_RECORDS } from '../../../interfaces/interfaces';

export const getExpensiveRecordsSlide = createSlice({
    name : "get-expenses",
    initialState : {
        status : 'initial-state',
        msg : null,
        total : 0,
        expense :  [] as EXPENSE_RECORDS[],
        BackendErrorMessage : null
    },
    reducers : {
        failedGetRecord: ( state, { payload } ) => {
            state.status = 'failed'; 
            state.msg = null;
            state.total = 0;
            state.expense = [];
            state.BackendErrorMessage = payload;
        },
        getExpensiveRecords: ( state, { payload } ) => {
            state.status = 'success';
            state.msg = payload.msg;
            state.total = payload.total;
            state.expense = payload.expenses;
            state.BackendErrorMessage = null;
        },
        gettingRecords : ( state )=>{
            state.status = 'in-progress';
        }
    }
});

export const { failedGetRecord, getExpensiveRecords , gettingRecords } = getExpensiveRecordsSlide.actions;