import { ADMIN, CLIENTS, RENEW_MEMBERSHIP , POST_EDIT_USER, CHANGE_PASSWORD, CHANGE_LICENSE, RENEW_PLAN} from "../../interfaces/interfaces";
import { ApiResponse } from "../../types/types";
import { AppDispatch } from "../store";
import {
  registering,
  adminRegistered,
  failedRegister,
  failedClientRegister,
  clientRegistering,
  clientRegistered,
  adminRegisterProvider,
  createClientProvider,
  renewMembershipProvider,
  renewingMembership,
  renewMembership,
  failedRenewMembership,
  EditingUser,
  userEdited,
  failedEditUser,
  updateUserProvider,
  changePasswordProvider,
  changePassword,
  changingPassword,
  failedChangePassword,
  changeLicenseProvider,
  changingLicense,
  changeLicense,
  failedChangeLicense,
  renewTenantPlanProvider,
  updatePlanFailed,
  updatePlanSuccess,
  updatingPlan,
  failedGettingTrafficData,
  getTrafficData,
  gettingTrafficData,
  getAdminTrafficProvider,
  getAllCustomMembershipProvider,
  createCustomMembershipProvider,
  creatingCustomMembership,
  createCustomMembership,
  failedCreateCustomMembership,
  editingCustomMembership,
  editCustomMembershipProvider,
  editCustomMembership,
  failedEditCustomMembership,
    createPromotion, 
    creatingPromotion, 
    failedCreatePromotion, 
    editPromotion, 
    editingPromotion, 
    failedEditPromotion, 
    getAllPromotions, 
    gettingPromotions, 
    failedGetPromotions, 
    deletePromotion, 
    deletingPromotion, 
    failedDeletePromotion,
    createPromotionProvider, 
    editPromotionProvider, 
    getAllPromotionsProvider, 
    deletePromotionProvider, 
    creatingInscription,
    createdInscriptionRecord,
    failedCreateInscription,
    createTenantInscriptionProvider,
    deletingInscription,
    deleteInscription,
    failedDeleteDefinition,
    deleteInscriptionProvider,
    getAllInscriptionsProvider,
    gettingInscriptions,
    getAllInscriptions,
    failedGetInscriptions,
    updatingInscription,
    updateInscription,
    failedUpdateInscription,
    updateTenantInscriptionProvider
} from "./index";
import { failedCustomMembership, getAllCustomMembership, gettingCustomMembership } from "./slides/customMembershipSlice";

//formState type ADMIN interface
export const startRegisterAdminThunk = ( formState : any, navigate: any) => {
    return async (dispatch: AppDispatch) => {
      dispatch(registering())
      try {
        const res = await adminRegisterProvider(formState, navigate);
  
        if (res?.ok) {
          const { responsee } = res;
          dispatch({
            type: adminRegistered,
            payload: responsee
          })
          navigate('/user/registro');
          return {
            ok:true
          }
        } else {
          dispatch(failedRegister(res?.error))          
          return {
            ok:false
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
} 
//formState type CLIENTS interface
export const startRegisterClientThunk = ( formState : any, navigate: any) => {
    return async (dispatch: AppDispatch) => {
      dispatch(clientRegistering())
      try {
        const res = await createClientProvider(formState, navigate);
  
        if (res?.ok) {
          const { responsee } = res;
          dispatch({
            type: clientRegistered?.type,
            payload: responsee
          })
          navigate('/user/registro');
          return {
            ok : true
          }
        } else {
          dispatch(failedClientRegister(res?.error))          
          return {
            ok : false
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
} 

export const renewMembershipThunk = (membershipInfo: RENEW_MEMBERSHIP, userId: string) => {
    return async (dispatch: AppDispatch) => {
      dispatch(renewingMembership())
      try {
        const res = await renewMembershipProvider(membershipInfo, userId)

        if (res?.ok) {
          const { responsee } = res;
          dispatch({
            type: renewMembership?.type,
            payload: responsee
          })
          return {
            ok:true
          }
        } else {
          dispatch(failedRenewMembership(res?.error))          
          return {
            ok:false
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
}

export const startUserEditThunk = ( formState : POST_EDIT_USER , navigate: any , id : string) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type : EditingUser.type,
      payload : null
    })
    try {
      const res = await updateUserProvider( formState , navigate , id);

      if (res?.ok) {
        const { responsee } = res;
        dispatch({
          type: userEdited.type,
          payload: responsee
        })
        window.location.pathname !== '/user/configuraciones' && navigate('/user/clientes');
        return {
          ok:true
        }
      } else {
        dispatch({
          type: failedEditUser.type,
          payload: res?.error
        })        
        return {
          ok:false
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
} 

export const changePasswordThunk = ( formState: CHANGE_PASSWORD, userId: string ) => {
  return async (dispatch: AppDispatch) => {
    dispatch(changingPassword())
    try {
      const res = await changePasswordProvider(formState, userId);

      if (res?.ok) {
        const { responsee } = res;
        dispatch({
          type: changePassword?.type,
          payload: responsee
        })
        return {
          ok:true
        }
      } else {
        dispatch(failedChangePassword(res?.error))
        return {
          ok:false
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}

export const changeLicenseThunk = ( formState: CHANGE_LICENSE ) => {
  return async (dispatch: AppDispatch) => {
    dispatch(changingLicense())
    try {
      const res = await changeLicenseProvider(formState);

      if (res?.ok) {
        const { responsee } = res;
        dispatch({
          type: changeLicense?.type,
          payload: responsee
        })
        return {
          ok:true
        }
      } else {
        dispatch(failedChangeLicense(res?.error))
        return {
          ok:false
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}

export const renewTenantPlanThunk = ( formState : any ) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type : updatingPlan?.type,
      payload : null
    })
    try {
      const res = await renewTenantPlanProvider( formState );

      if (res?.ok) {
        const { responsee } = res;
        dispatch({
          type: updatePlanSuccess?.type,
          payload: responsee
        })
        return {
          ok:true
        }
      } else {
        dispatch({
          type : updatePlanFailed?.type,
          payload : res?.error
        })
        return {
          ok:false
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}



/* failedGettingTrafficData,
  getTrafficData,
  gettingTrafficData */


export const getAdminTrafficReportsThunk =(timeStamp:String)=>{
  return async (dispatch: AppDispatch) => {
      dispatch({
        type:gettingTrafficData.type,
        payload:null,
      })
      try {
        const res : ApiResponse = await getAdminTrafficProvider(timeStamp);
  
        if (res?.ok) {
          const { data } = res;
          dispatch({
            type:getTrafficData.type,
            payload:data,
          })
        }else{
          dispatch({
            type:failedGettingTrafficData.type,
            payload:res?.error,
          })          
        }
      } catch (error) {
        console.error(error);
        dispatch(dispatch({
          type:failedGettingTrafficData.type,
          payload:error,
        }))        
      }
      
    }
}

export const getAllCustomMembershipThunk = () => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type : gettingCustomMembership.type,
      payload : null
    })
    try {
      const res: ApiResponse = await getAllCustomMembershipProvider();

      if (res?.ok) {
        const { data } = res;
        dispatch({
          type: getAllCustomMembership.type,
          payload: data
        })
      } else {
        dispatch({
          type : failedCustomMembership.type,
          payload : res?.error
        })
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}

export const createCustomMembershipThunk = ( formState : any ) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type : creatingCustomMembership.type,
      payload : null
    })
    try {
      const res: ApiResponse = await createCustomMembershipProvider( formState );

      if (res?.ok) {
        const { data } = res;
        dispatch({
          type: createCustomMembership.type,
          payload: data
        })
        return {
          ok:true
        }
      } else {
        dispatch({
          type : failedCreateCustomMembership.type,
          payload : res?.error
        })
        return {
          ok:false
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}

export const editCustomMembershipThunk = ( formState : any, idEdit: string ) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type : editingCustomMembership.type,
      payload : null
    })
    
    try {
      const res: ApiResponse = await editCustomMembershipProvider( formState, idEdit );

      if (res?.ok) {
        const { data } = res;
        dispatch({
          type: editCustomMembership.type,
          payload: data
        })
        return {
          ok:true
        }
      } else {
        dispatch({
          type : failedEditCustomMembership.type,
          payload : res?.error
        })
        return {
          ok:false
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}

export const getAllPromotionsThunk = () => {
  return async (dispatch: AppDispatch) => {
      dispatch({
          type: gettingPromotions.type,
          payload: null
      });
      try {
          const res : ApiResponse= await getAllPromotionsProvider();

          if (res?.ok) {
              const { data } = res;
              dispatch({
                  type: getAllPromotions.type,
                  payload: data
              });
          } else {
              dispatch({
                  type: failedGetPromotions.type,
                  payload: res?.error
              });
          }
      } catch (error) {
          console.error('Error:', error);
      }
  }
}

export const createPromotionThunk = (formState: any) => {
  return async (dispatch: AppDispatch) => {
      dispatch({
          type: creatingPromotion.type,
          payload: null
      });
      try {
          const res : ApiResponse = await createPromotionProvider(formState);

          if (res?.ok) {
              const { data } = res;
              dispatch({
                  type: createPromotion.type,
                  payload: data
              });
              return {
                  ok: true
              };
          } else {
              dispatch({
                  type: failedCreatePromotion.type,
                  payload: res?.error
              });
              return {
                  ok: false
              };
          }
      } catch (error) {
          console.error('Error:', error);
      }
  }
}

export const editPromotionThunk = (formState: any, idEdit: string) => {
  return async (dispatch: AppDispatch) => {
      dispatch({
          type: editingPromotion.type,
          payload: null
      });

      try {
          const res : ApiResponse = await editPromotionProvider(formState, idEdit);

          if (res?.ok) {
              const { data } = res;
              dispatch({
                  type: editPromotion.type,
                  payload: data
              });
              return {
                  ok: true
              };
          } else {
              dispatch({
                  type: failedEditPromotion.type,
                  payload: res?.error
              });
              return {
                  ok: false
              };
          }
      } catch (error) {
          console.error('Error:', error);
      }
  }
}

export const deletePromotionThunk = (idDelete: string) => {
  return async (dispatch: AppDispatch) => {
      dispatch({
          type: deletingPromotion.type,
          payload: null
      });

      try {
          const res : ApiResponse = await deletePromotionProvider(idDelete);

          if (res?.ok) {
              const { data } = res;
              dispatch({
                  type: deletePromotion.type,
                  payload: data
              });
              return {
                  ok: true
              };
          } else {
              dispatch({
                  type: failedDeletePromotion.type,
                  payload: res?.error
              });
              return {
                  ok: false
              };
          }
      } catch (error) {
          console.error('Error:', error);
      }
  }
}

export const createTenantInscriptionPriceThunk = (formState: any) => {
  return async (dispatch: AppDispatch) => {
      dispatch({
          type: creatingInscription?.type,
          payload: null
      });
      try {
          const res : ApiResponse = await createTenantInscriptionProvider(formState);

          if (res?.ok) {
              const { data } = res;
              dispatch({
                  type: createdInscriptionRecord?.type,
                  payload: data
              });
              return {
                  ok: true
              };
          } else {
              dispatch({
                  type: failedCreateInscription?.type,
                  payload: res?.error
              });
              return {
                  ok: false
              };
          }
      } catch (error) {
          console.error('Error:', error);
      }
  }
}

export const deleteTenantInscriptionPaymentThunk = (id : string) => {
  return async (dispatch: AppDispatch) => {
      dispatch({
          type: deletingInscription?.type,
          payload: null
      });

      try {
          const res : ApiResponse = await deleteInscriptionProvider( id );

          if (res?.ok) {
              const { data } = res;
              dispatch({
                  type: deleteInscription?.type,
                  payload: data
              });
              return {
                  ok: true
              };
          } else {
              dispatch({
                  type: failedDeleteDefinition?.type,
                  payload: res?.error
              });
              return {
                  ok: false
              };
          }
      } catch (error) {
          console.error('Error:', error);
      }
  }
}

export const getAllTenantInscriptionThunk = () => {
  return async (dispatch: AppDispatch) => {
      dispatch({
          type: gettingInscriptions?.type,
          payload: null
      });
      try {
          const res : ApiResponse= await getAllInscriptionsProvider();

          if (res?.ok) {
              const { data } = res;
              dispatch({
                  type: getAllInscriptions?.type,
                  payload: data
              });
          } else {
              dispatch({
                  type: failedGetInscriptions?.type,
                  payload: res?.error
              });
          }
      } catch (error) {
          console.error('Error:', error);
      }
  }
}

export const updateTenantInscriptionThunk = ( formState: any ) => {
  return async (dispatch: AppDispatch) => {
      dispatch({
          type: updatingInscription?.type,
          payload: null
      });

      try {
          const res : ApiResponse = await updateTenantInscriptionProvider( formState );

          if (res?.ok) {
              const { data } = res;
              dispatch({
                  type: updateInscription?.type,
                  payload: data
              });
              return {
                  ok: true
              };
          } else {
              dispatch({
                  type: failedUpdateInscription?.type,
                  payload: res?.error
              });
              return {
                  ok: false
              };
          }
      } catch (error) {
          console.error('Error:', error);
      }
  }
}