import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../store"
import { SALES_RECORDS } from "../../interfaces/interfaces";
import { useEffect, useState } from "react";
import { getSalesRecordsThunk } from "../../store/sales";

type bestSale = {
  [product_id: string]: {
    quantity: number;
    amount: number
  };
}

/**
 * @description This algorith pretend to return the most seller product. The stepts to do that
 * are the next ones :
 *  1. DO objects hash to save products
 *  2. save and sum quantity and amount value if the hash model already include some
 * specific product
 *  3. filter and return the most seller product from the hash
 *  4. find througth product id the most seller product from sales search.
 * */

export const useAnalysis = () => {

  const sales = useSelector((state: RootState) => state?.getSalesRecords.sales);
  const dispatch = useDispatch<AppDispatch>();
  const [mostSeller, setMostSeller] = useState<{} | null>(null);
  const [bestSale, setBestSale] = useState<bestSale>({
    '0': {
      quantity: 0,
      amount: 0
    }
  })
  useEffect(() => {
    dispatch(getSalesRecordsThunk('semestral'));
  }, [dispatch])

  const calculateMostSeller = (sales: SALES_RECORDS[]) => {
    if (!sales) { return }

    const productSales: bestSale = {};

    for (let sale of sales) {
      let productId ;
      if (!sale || !sale?.product) { continue; }
      
      productId = sale?.product?._id

      if (!productSales[productId]) {
        productSales[productId] = { quantity: 0, amount: 0 };
      }
      productSales[productId].quantity += sale.quantity;
      productSales[productId].amount += sale.amount;
    }

    const bestProduct = Object.entries(productSales).reduce(
      (best, [productId, data]) => {
        if (data?.quantity > (best?.data?.quantity || 0)) {
          return { productId, data };
        }
        return best;
      },
      { productId: '', data: { quantity: 0, amount: 0 } }
    );

    setBestSale({ [bestProduct?.productId]: bestProduct?.data });
    const bestSaleProduct = sales?.find(sale => sale?.product?._id === bestProduct?.productId);
    if (!bestSaleProduct) {
      console.error("Best sale product not found for productId", bestProduct?.productId);
    }
    setMostSeller({ product: bestSaleProduct?.product, quantity: bestProduct?.data?.quantity });
  }
  useEffect(() => {
    calculateMostSeller(sales);
  }, [sales]);

  return { bestSale, mostSeller };
}