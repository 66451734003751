import { createSlice } from "@reduxjs/toolkit";

export const editPromotionSlice = createSlice({
    name: "editPromotion",
    initialState: {
        status: "initial",
        msg: null,
        promotion: null,
        BackendErrorMessage: null,
    },
    reducers: {
    failedEditPromotion: (state, { payload }) => {
        state.status = "failed";
        state.promotion = null;
        state.BackendErrorMessage = payload;
    },
    editPromotion: (state, { payload }) => {
        state.status = "success";
        state.msg = payload.msg;
        state.promotion = payload.promotion;
        state.BackendErrorMessage = null;
    },
    editingPromotion: (state) => {
        state.status = "in-progress";
    },
    },
});

export const { failedEditPromotion, editPromotion, editingPromotion } = editPromotionSlice.actions;