import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

import { config } from '../config';

import { CustomMembershipEditModal } from './CustomMembershipEditModal';
import { CustomMembershipModal } from './CustomMembershipModal';
import { CustomMembershipCard } from './CustomMembershipCard';

import { getAllCustomMembershipThunk } from '../store/usuarios';
import { Notifications } from '../atoms/Notifications';

export const CustomMembership = () => {
  const [idEdit, setIdEdit] = useState<string>('');

  const dispatch = useDispatch<AppDispatch>();

  const customMembership = useSelector((state: RootState) => state?.customMembership?.customMembership);
  const editCustomMembership = useSelector((state: RootState) => state?.editCustomMembership);
  const createCustomMembership = useSelector((state: RootState) => state?.createCustomMembership);

  useEffect(() => {
    customMembership?.length === 0 && dispatch(getAllCustomMembershipThunk());
  }, [dispatch, customMembership?.length]);

  useEffect(() => {
    if (editCustomMembership?.status === 'success' || createCustomMembership?.status === 'success') {
      dispatch(getAllCustomMembershipThunk());
    }
  }, [editCustomMembership, createCustomMembership, dispatch]);

  return (
    <>
      <CustomMembershipModal modalType={ config.MODAL_CREATE_CUSTOM_MEMBERSHIP } />
      <CustomMembershipEditModal modalType={ config.MODAL_EDIT_CUSTOM_MEMBERSHIP } idEdit={ idEdit } />

      <CustomMembershipCard customMembership={ customMembership } setIdEdit={ setIdEdit } />

      <Notifications handler={ createCustomMembership } actionName='categoría' actionType='Creando' />
      <Notifications handler={ editCustomMembership } actionName='categoría' actionType='Actualizando' />
    </>
  )
}