import { DateTime } from 'luxon';
import { Box, Button, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { formatText } from '../utils/formatText';
import { CustomPromotionsDefinitioonsProps } from '../interfaces/interfaces';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { openModal } from '../store/modal';
import { config } from '../config';
import { Edit as EditIcon } from '@mui/icons-material';
import { useUsersActions } from '../customhooks/useUsersActions';

export const CustomPromotionsCard = ({ custompromotions, setIdEdit }: CustomPromotionsDefinitioonsProps) => {
  const now = DateTime.now();
  const dispatch = useDispatch<AppDispatch>();
  const { handlePromotionDelete } = useUsersActions()

  const handleEdit = (id: string) => {
    setIdEdit(id);
    dispatch({
      type: openModal?.type,
      payload: config?.MODAL_EDIT_PROMOTION_DEFINITION
    })
  }
  const handleDelete = (_id: string) => {
    return handlePromotionDelete(_id)
  }
  return (
    <>
      {custompromotions?.length > 0 ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {custompromotions.map((promotion, index) => {
            const expirationDate = DateTime.fromISO(promotion?.expirationDate as string);
            const isExpired = expirationDate <= now;
            return (
              <Card
                key={index}
                sx={{
                  width: { xs: '100%', sm: 'calc(50% - 16px)', md: 'calc(33.333% - 16px)' },
                  boxShadow: 1,
                  borderRadius: 2,
                  backgroundColor: isExpired ? '#f8d7da' : 'white',
                  border: isExpired ? '1px solid #f5c6cb' : 'none',
                  position: 'relative', 
                }}
              >
                <CardContent>
                  <CardHeader
                    title={
                      <Typography
                        variant="h5"
                        color="primary"
                        sx={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          mb: 2
                        }}
                      >
                        {promotion?.name}
                      </Typography>
                    }
                    subheader={
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          color: 'black',
                        }}
                      >
                        {promotion?.discount}%
                      </Typography>
                    }
                    sx={{ textAlign: 'center' }}
                    action={
                      <>
                        <IconButton
                          onClick={() => handleEdit(promotion?._id as string)}
                          sx={{ color: '#8BC34A' }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(promotion?._id as string)}
                          sx={{ color: '#FF6F6F' }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </>
                    }
                  />
                  {isExpired && (
                    <Typography
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: '#f5c6cb',
                        color: '#721c24',
                        padding: '2px 4px',
                        borderRadius: 1,
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Expirado
                    </Typography>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Box>
      ) : (
        <Typography>No hay promociones definidas</Typography>
      )}
    </>
  )
}