import { createSlice } from "@reduxjs/toolkit"

export const editCustomMembershipSlice = createSlice({
  name: 'editCustomMembership',
  initialState: {
    status: 'initial',
    msg: null,
    price: null,
    typePlan: null,
    BackendErrorMessage: null
  },
  reducers: {
    failedEditCustomMembership: (state, { payload }) => {
      state.status = 'failed'
      state.price = null
      state.typePlan = null
      state.BackendErrorMessage = payload
    },
    editCustomMembership: (state, { payload }) => {
      state.status = 'success'
      state.msg = payload.msg
      state.price = payload.price
      state.typePlan = payload.typePlan
      state.BackendErrorMessage = null
    },
    editingCustomMembership: (state) => {
      state.status = 'in-progress'
    }
  }
})

export const { failedEditCustomMembership, editCustomMembership, editingCustomMembership } = editCustomMembershipSlice.actions