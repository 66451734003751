import React, { useEffect } from 'react';
import { Outlet } from "react-router-dom"
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';

import { SnackBar } from '../../atoms/SnackBar';
import { WelcomeAtom } from '../../atoms/WelcomeAtom';
import { MutipleDashboards } from './MutipleDashboards'
import { ChartsScreen } from '../ChartsScreen';
import { SalesScreen } from '../SalesScreen';
import { getAllCustomMembershipThunk, getAllPromotionsThunk, getAllTenantInscriptionThunk } from '../../store/usuarios';


export default function Dashboard() {
  const dispatch = useDispatch<AppDispatch>();
  const customMembership = useSelector((state: RootState) => state?.customMembership?.customMembership);
  const promotions = useSelector((state: RootState) => state?.getPromotion?.promotions);
  const inscription = useSelector((state: RootState) => state?.getInscriptionRecords?.inscriptions);

  const loged = useSelector((state: RootState) => state.auth?.status);
  const name = useSelector((state: RootState) => state.auth?.email);
  const [semostro, setMostrar] = React.useState(false);
  const tenantHandler = useSelector((state: RootState) => state?.register);
  
  useEffect(() => {
    dispatch( getAllTenantInscriptionThunk() );
    dispatch(getAllCustomMembershipThunk());
    dispatch(getAllPromotionsThunk());
  }, [ dispatch , inscription?.length === 0 , customMembership?.length === 0 , promotions?.length === 0]);

  useEffect(() => {
    const hasShownAlert = localStorage.getItem('hasShownAlert');
    if (!semostro && loged === 'authenticated' && !hasShownAlert) {
      setMostrar(true);
      localStorage.setItem('hasShownAlert', 'true');
    }
  }, [semostro, loged]);

  return (
    <>
      <Outlet />
      {/* Chart */}
      <WelcomeAtom severity='success' msg={`Es un placer tenerte de regreso ${name}, ten una excelente jornada :)`} />
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <ChartsScreen />
        </Paper>
      </Grid>
      {/* Total de ventas por mes*/}
      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <SalesScreen />
        </Paper>
      </Grid>
      {/* Users table */}
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
          <MutipleDashboards />
        </Paper>
      </Grid>
      {
        (tenantHandler?.status === 'usuario-registrado') && <SnackBar severity='success' errorMsg='Usuario registrado con éxito, visible en sección de búsqueda'></SnackBar>
      }
    </>
  );
}
