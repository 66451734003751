import devicesImageHome from '../img/Conjunto.svg';
import  '../styles/components/luzPalpitanteEfecto.css';

export const HomeMainSection = ()=>{
    return (
        <div>
            <div className='w-full flex justify-between mt-28 md:items-center'>
                <article className='w-6/12 sm:w-full'>
                    <p className='text-lightGray font-semibold'>SOFTWARE PARA GIMNASIO Y VENTAS</p>
                    <p className='text-7xl font-extrabold bg-gradient-to-r from-mainBlue to-blue-500 text-transparent bg-clip-text mt-5 sm:text-6xl md:text-5xl leading-tight'>Toma el control de tu negocio</p>
                    <p className='text-lightGray mt-10 text-xl font-light mb-5'>Administrar tu negocio nunca fue tan fácil y rápido!<br /> Ahorra tiempo y dinero. Observa todo lo que pasa en tu negocio desde un solo lugar.</p>
                    <a href='/registrar' className='text-white text-lg font-bold px-4 py-2 bg-mainBlue relative'>
                        <span className="glow"></span>Comienza ahora
                    </a>
                    <p className='mt-3 text-lightGray font-light'>¿Ya tienes una cuenta? <a href='/login' className='text-mainBlue font-semibold'>Inicia sesión</a></p>
                </article>
                <div className='w-6/12 -mr-20 sm:bg-mainBlue sm:hidden md:w-8/12'>
                    <img className='w-full -mr-20' src={devicesImageHome} alt="" /> {/* Agrega tu imagen aquí */}
                </div>
            </div>
            <div className='flex items-center mt-10'> 
                <div className='bg-mainBlue w-2 h-2 rounded-full mr-2'></div>
                <p className='font-semibold text-lightGray'>Conoce más sobre lo que <span className='font-bold text-mainBlue'>VOLCANO ADMIN</span> puede hacer por ti</p>
            </div>
        </div>
    );
}