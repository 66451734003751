import { useState } from 'react';
import { jsPDF } from 'jspdf';

interface TicketData {
  productName: string;
  unitPrice: number;
  quantity: number;
  totalAmount: number;
  discount?: number;
  finalAmount: number;
  pay: number;
  change: number;
}

export const useTicket = () => {
  const [ticketData, setTicketData] = useState<TicketData | null>(null);

  const generateTicket = (
    productName: string,
    unitPrice: number,
    quantity: number,
    discount: number | undefined,
    finalAmount: number,
    pay: number,
    change: number
  ) => {
    const totalAmount = unitPrice * quantity;
    const data: TicketData = {
      productName,
      unitPrice,
      quantity,
      totalAmount,
      discount,
      finalAmount,
      pay,
      change
    };

    setTicketData(data);

    const doc = new jsPDF();
    doc.text(`Ticket de Venta`, 10, 10);
    doc.text(`Producto: ${data.productName}`, 10, 20);
    doc.text(`Precio unitario: $${data.unitPrice.toFixed(2)}`, 10, 30);
    doc.text(`Cantidad: ${data.quantity}`, 10, 40);
    doc.text(`Total sin descuento: $${data.totalAmount.toFixed(2)}`, 10, 50);

    if (data.discount) {
      doc.text(`Descuento: ${data.discount}%`, 10, 60);
    }

    doc.text(`Total a pagar: $${data.finalAmount.toFixed(2)}`, 10, 70);
    doc.text(`Pago recibido: $${data.pay.toFixed(2)}`, 10, 80);
    doc.text(`Cambio: $${data.change.toFixed(2)}`, 10, 90);

    doc.save('ticket.pdf');
  };

  return { ticketData, generateTicket };
};
