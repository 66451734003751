import { Box, Grid, Paper, Typography } from "@mui/material";
import { useRevenueProyection } from "../customHook/useRevenueProyecton";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

import { basicColorsPalette } from '../../constants/ConstantsFlexSolutionManager';
export const RevenueProyection = () => {
    const { employeeCost, stockRevenue, totalRevenue, totalStock, expenseRecordCost } = useRevenueProyection();

    return (
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
            }}
          >
            <Typography
              sx={{
                fontSize: basicColorsPalette?.titleSize,
                color: basicColorsPalette?.color,
                fontFamily: basicColorsPalette.fontFamily,
                mb: 2,
              }}
            >
              Proyección de ingresos al vender actual stock
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <InventoryOutlinedIcon sx={{ mr: 1, color: basicColorsPalette?.colorChanged }} />
              <Typography
                sx={{
                  fontFamily: basicColorsPalette?.fontFamily,
                  fontSize: basicColorsPalette?.subTitle,
                }}
              >
                Cantidad de productos en inventario: <strong>{totalStock}</strong>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <AttachMoneyIcon sx={{ mr: 1, color: basicColorsPalette?.colorChanged }} />
              <Typography
                sx={{
                  fontFamily: basicColorsPalette?.fontFamily,
                  fontSize: basicColorsPalette?.subTitle,
                }}
              >
                Actualmente se tiene: <strong>${stockRevenue}</strong> en valor del stock
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <MoneyOffIcon sx={{ mr: 1, color: basicColorsPalette?.costColor }} />
              <Typography
                sx={{
                  fontFamily: basicColorsPalette?.fontFamily,
                  fontSize: basicColorsPalette?.subTitle,
                  color: basicColorsPalette["redColor"],
                }}
              >
                - <span style={{ fontWeight: 'bold', fontSize: '1.2em', color: basicColorsPalette?.highlightColor as string}}>
                  ${employeeCost}
                </span> de total de gastos en sueldos y salarios mensuales
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <MoneyOffIcon sx={{ mr: 1, color: basicColorsPalette?.costColor }} />
              <Typography
                sx={{
                  fontFamily: basicColorsPalette?.fontFamily,
                  fontSize: basicColorsPalette?.subTitle,
                  color: basicColorsPalette["redColor"],
                }}
              >
                - <span style={{ fontWeight: 'bold', fontSize: '1.2em', color: basicColorsPalette?.highlightColor as string }}>
                  ${expenseRecordCost}
                </span> en registros de gastos en la empresa
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AttachMoneyIcon sx={{ mr: 1, color: basicColorsPalette?.colorChanged }} />
              <Typography
                sx={{
                  fontFamily: basicColorsPalette?.fontFamily,
                  fontSize: basicColorsPalette?.subTitle,
                }}
              >
                Los ingresos esperados al vender stock serán de: <strong>${totalRevenue}</strong>
              </Typography>
            </Box>
          </Paper>
        </Grid>
      );
}