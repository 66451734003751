import { TextField, Button, Box, FormControl, InputLabel, Select, MenuItem, Typography, Modal } from '@mui/material';
import { useForm } from '../../customhooks';
import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useHandlerModal } from '../../customhooks/useHandlerModal';
import { useFormStateValidation } from '../../customhooks/useFormStateValidation';
import { EXPENSE_PROPS, EXPENSE_RECORDS } from '../../interfaces/interfaces';
import { useHandlerExpense } from '../customHook/useHandlerExpense';
import { startExpenseUpdate } from '../../store/expenses';
import { formContainerStyles, formsStyles, textFieldStyle } from '../../constants/ConstantsFlexSolutionManager'
import Title from '../../atoms/Title';
import { LoadFile } from '../atoms/LoadFile';
import { config } from '../../config';

export const EditExpenseModal = ({ modalType, expenseId }: EXPENSE_PROPS) => {
    const dispatch = useDispatch<AppDispatch>();
    const { findById } = useHandlerExpense();
    const { handleClose } = useHandlerModal();
    const [expenseById, setExpenseById] = useState<EXPENSE_RECORDS | null>(null);

    const currentModalType = useSelector((state: RootState) => state?.modal?.modalType);
    const open = useSelector((state: RootState) => state?.modal?.openModal);
    const categories = useSelector((state: RootState) => state?.categories?.categorias);
    const privilegies = useSelector((state: RootState) => state?.auth)
    useEffect(() => {
        if (expenseId) {            
            const fetchedExpense = findById(expenseId);
            setExpenseById(fetchedExpense);
        }
    }, [expenseId, findById]);

    const { formState, onInputChange, onResetForm } = useForm(
        {
            expensiveName: expenseById?.expensiveName || '',
            description: expenseById?.description || '',
            registerDate: expenseById?.registerDate ? new Date(expenseById.registerDate).toISOString().substr(0, 10) : '',
            total: expenseById?.total as number || '',
            img: expenseById?.img || '',
            category: expenseById?.category?.nombre || ''
        }
    )

    const { error, errorField, errorNumber, validateNumber, gralError, validateField } = useFormStateValidation();

    useEffect(() => {
        if (expenseById) { //prob reset after updating
            onResetForm();
        }
        validateNumber(formState?.total as string, 'total');
        validateField('expensiveName', formState?.nombre as string);
        validateField('description', formState?.categoria as string);
        validateField('registerDate', formState?.descripcion as string);
    }, [
        expenseById
    ]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const useToResetForm = await dispatch(startExpenseUpdate(formState, expenseId));
        if (useToResetForm?.ok === true) {
            onResetForm();
            handleClose();
        }
    };

    return (
        <>
            {
                modalType === currentModalType && (
                    <Modal open={open} onClose={handleClose} aria-labelledby="Actualizar producto" aria-describedby="editar un registro de gasto">
                        <Box component='form' onSubmit={handleSubmit} sx={{ formContainerStyles }}>
                            <Box sx={formsStyles}>
                                <Title>Hacer modificaciones sobre registro de gastos</Title>
                                <TextField
                                    label="Nombre del gasto"
                                    name="expensiveName"
                                    value={formState.expensiveName}
                                    onChange={onInputChange}
                                    fullWidth
                                    required
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    label="Descripción"
                                    name="description"
                                    value={formState.description}
                                    onChange={onInputChange}
                                    fullWidth
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    label="Fecha de registro"
                                    name="registerDate"
                                    type="date"
                                    value={formState.registerDate}
                                    onChange={onInputChange}
                                    fullWidth
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginBottom: 2 }}
                                />
                                {
                                    privilegies?.licencia === config?.PLAN_BASICO ? <Typography sx={{ marginTop : 2, marginBottom : 2 , fontWeight : 600}}> La carga de imágenes está disponible en planes mayores al básico</Typography> : <LoadFile />
                                }                                            
                                <FormControl fullWidth required sx={{ marginBottom: 2 }}>
                                    <InputLabel>Categoría a la que pertenece</InputLabel>
                                    <Select
                                        name="category"
                                        value={formState.category}
                                        onChange={onInputChange as any}
                                    >
                                        {categories.map((cat) => (
                                            <MenuItem key={cat?._id} value={cat?._id}>
                                                {cat?.nombre}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography variant="caption" color="error">
                                        {/* {errors.category} */}
                                    </Typography>
                                </FormControl>
                                <Button type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={textFieldStyle}
                                    fullWidth
                                >
                                    Modificar
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                )
            }
        </>
    );
}