import React, { FormEvent, useState, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import _ from 'lodash';

import { useForm } from '../customhooks/useForm';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Switch } from '@mui/material';
import { POST_EDIT_USER } from '../interfaces/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../store/store';

import Title from '../atoms/Title';
import { SnackBar } from '../atoms/SnackBar';
import { getTimeZoneFromUser } from '../utils/timeZoneParser';
import { startUserEditThunk } from '../store/usuarios/index';
import { useFormStateValidation } from '../customhooks/useFormStateValidation';
import { useRefinery } from '../customhooks/useRefinery';
import { getClients, getDetailsInfo, getManagementService } from '../store/slides/Auth';


const paperStyle = {
  margin: 'auto auto auto 30px',
  borderRadius: '10px',
  width: '100%',
  marginTop: '25px',
};
const textFieldStyle = {
  marginBottom: '20px',
  marginTop: '10px',
  width: '100%'
};

const selectStyle = {
  marginBottom: '20px',
  marginTop: '40px'
};

export const FormUserEdit = React.memo(() => {
  //toggle 
  const [personalInfoEnabled, setPersonalInfoEnabled] = useState(false);
  const [contactInfoEnabled, setContactInfoEnabled] = useState(false);
  const [planDetailsEnabled, setPlanDetailsEnabled] = useState(false);

  const handleTogglePersonalInfo = () => {
    setPersonalInfoEnabled(!personalInfoEnabled);
  };

  const handleToggleContactInfo = () => {
    setContactInfoEnabled(!contactInfoEnabled);
  };

  const handleTogglePlanDetails = () => {
    setPlanDetailsEnabled(!planDetailsEnabled);
  };


  const timeZoneData = getTimeZoneFromUser();
  const { id, role } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const handleBackendErrorsAboutUserEdit = useSelector((state: RootState) => state?.editUser);
  const usrRole = useSelector((state: RootState) => state?.auth?.role);

  const usrData = useSelector((state: RootState) => state?.detailsinfo?.usuarios);
  const clientUsers = useSelector((state: RootState) => state?.getClients.usuarios);
  const mgmtServiceRole = useSelector((state: RootState) => state?.managementService?.usuarios);

  const { initialFormConfig } = useRefinery();

  useEffect(()=>{
    if( usrData?.length === 0 ){
      dispatch( getDetailsInfo() );
    }
    if( clientUsers?.length === 0 ){
      dispatch( getClients() );
    }
    if( mgmtServiceRole?.length === 0 ){
      dispatch(getManagementService());
    }
  },[ usrData?.length , dispatch , clientUsers?.length , mgmtServiceRole?.length ])

  //initial form config
  let userRoleToEdit: keyof typeof initialFormConfig | undefined | string;
  if (!role) {
    alert('No se pudo encontrar el rol del usuario que intentas editar. Vuelve a intentarlo más tarde');

  }
  userRoleToEdit = role;
  const initialFormData: any = userRoleToEdit
    ? { ...initialFormConfig[userRoleToEdit] }
    : {};

  if (timeZoneData) {
    initialFormData.timeZone = {
      identifier: timeZoneData.userTimeZone,
      offset: timeZoneData.userOffset
    };
  }

  const formatDateString = (dateString: string) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  };

  const initialFormtState = usrRole === 'SUPER_ADMIN' ? usrData.find((user) => user.uid === id) : clientUsers.find((user) => user.uid === id) || mgmtServiceRole.find((user) => user.uid === id) || initialFormData;
  const { onInputChange, formState, onResetForm , handleSelectChange} = useForm(initialFormtState);
  const { error, fieldFormatError, dateBorn, dateBornError, errorNumber, handleEmail, dateValidation, validateNumber, gralError , validateField , errorField} = useFormStateValidation();

  useEffect(() => {
    const email = formState?.email
    const dateBorn = formState?.fechaNacimiento
    validateNumber(formState?.planMensualMonto as string, 'planMensualMonto');
    validateNumber(formState?.pagoDeInscripcion as string, 'pagoDeInscripcion');
    validateNumber(formState?.salarioMensual as string, 'salarioMensual');
    validateNumber(formState?.phoneNumber as any, 'phoneNumber');
    validateField('nombreEmpresa', formState?.nombreEmpresa as string);
    validateField('nombre', formState?.nombre as string);
    validateField('apellidoPaterno', formState?.apellidoPaterno as string);
    validateField('apellidoMaterno', formState?.apellidoMaterno as string);
    validateField('direccion', formState?.direccion as string);

    handleEmail(email as string)
    dateValidation(dateBorn as string)
  }, [
    formState.email,
    formState.fechaNacimiento,
    formState?.planMensualMonto,
    formState?.pagoDeInscripcion,
    formState?.salarioMensual,
    formState?.phoneNumber,
    formState?.nombreEmpresa,
    formState?.nombre,
    formState?.apellidoPaterno,
    formState?.apellidoMaterno,
    formState?.direccion
  ]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    // Filtra los campos de acuerdo a los toggles activados
    const formDataToSend = { ...formState };
    if (usrRole === 'MANAGEMENT-SERVICE-ROLE' && contactInfoEnabled) {
      delete formDataToSend?.nombreEmpresa;
      //delete formDataToSend?.fechaNacimiento;
    }

    if (!personalInfoEnabled) {
      delete formDataToSend.nombre;
      delete formDataToSend.apellidoPaterno;
      delete formDataToSend.apellidoMaterno;
    }
    if (!contactInfoEnabled) {
      delete formDataToSend.direccion;
      delete formDataToSend.fechaNacimiento;
      delete formDataToSend.email;
      delete formDataToSend.phoneNumber;
    }
    if (!planDetailsEnabled) {
      delete formDataToSend.pagoDeInscripcion;
      delete formDataToSend.planMensualMonto;
      delete formDataToSend.salarioMensual;
      delete formDataToSend.fechaInicioPlan;
      delete formDataToSend.planMensualTipo;
    }

    // Envía los datos actualizados al backend    
    handleEmail(formDataToSend.email as string);
    const useToResetForm = await dispatch(startUserEditThunk(formDataToSend, navigate, id as string));
    if (useToResetForm?.ok === true) {
      onResetForm();
    }
  };

  return (
    <Paper style={paperStyle}>
      <Container maxWidth="lg">
        <Box mt={3}>
          <Title>Seleccione sobre que apartado desea editar</Title>
          <form onSubmit={(e) => handleSubmit(e)} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '100%' }}>
            <FormControlLabel
              control={<Switch checked={personalInfoEnabled} onChange={handleTogglePersonalInfo} />}
              label="Información Personal"
            />
            {personalInfoEnabled && (
              <>
                {/* Campos de información personal */}
                <TextField
                  fullWidth
                  label="usuario uid"
                  name="_id"
                  value={id}
                  onChange={onInputChange}
                  variant="outlined"                  
                  placeholder={id}
                  style={textFieldStyle}
                  required
                />
                {
                  (role === "SUPER_ADMIN") && <>
                    <TextField
                      fullWidth
                      label="Nombre de la Empresa"
                      name="nombreEmpresa"
                      value={formState?.nombreEmpresa}
                      onChange={onInputChange}
                      variant="outlined"
                      type="text"
                      style={textFieldStyle}
                      InputLabelProps={{
                        shrink: true,
                      }}                      
                      error={errorField?.nombreEmpresa?.error}
                      helperText={errorField?.nombreEmpresa?.textMessage}
                    />
                  </>
                }
                {
                  (role === "CLIENTE" || role === "ADMIN") && <>
                    <TextField
                      fullWidth
                      label={role === 'CLIENTE' ? "Nombre del usuario" : "Nombre del empleado"}
                      name='nombre'
                      value={formState?.nombre}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}                      
                      error={errorField?.nombre?.error}
                      helperText={errorField?.nombre?.textMessage}
                    />
                    <TextField
                      fullWidth
                      label="Apellido paterno"
                      name='apellidoPaterno'
                      value={formState?.apellidoPaterno}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}                      
                      error={errorField?.apellidoPaterno?.error}
                      helperText={errorField?.apellidoPaterno?.textMessage}
                    />
                    <TextField
                      fullWidth
                      label="Apellido materno"
                      name='apellidoMaterno'
                      value={formState?.apellidoMaterno}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}                      
                      error={errorField?.apellidoMaterno?.error}
                      helperText={errorField?.apellidoMaterno?.textMessage}
                    />
                  </>
                }
              </>
            )}

            <FormControlLabel
              control={<Switch checked={contactInfoEnabled} onChange={handleToggleContactInfo} />}
              label="Datos de Contacto"
            />
            {contactInfoEnabled && (
              <>
                {/* Campos de datos de contacto */}
                <TextField
                  fullWidth
                  label="Direcciòn"
                  name="direccion"
                  value={formState.direccion}
                  onChange={onInputChange}
                  variant="outlined"
                  type="text"
                  multiline
                  rows={2}
                  style={textFieldStyle}                  
                  error={errorField?.direccion?.error}
                  helperText={errorField?.direccion?.textMessage}
                />
                <TextField
                  fullWidth
                  label="Fecha de nacimiento"
                  name="fechaNacimiento"
                  value={formatDateString(formState?.fechaNacimiento as string)}
                  onChange={onInputChange}
                  variant="outlined"
                  type="date"
                  style={textFieldStyle}
                  error={dateBornError}
                  helperText={
                    dateBornError && dateBorn
                  }
                  InputLabelProps={{
                    shrink: true, // Ajusta la posición de la etiqueta
                  }}
                />
                <TextField
                  fullWidth
                  label="email"
                  name="email"
                  value={formState?.email}
                  onChange={onInputChange}
                  variant="outlined"
                  style={textFieldStyle}
                  error={fieldFormatError}
                  helperText={
                    fieldFormatError && error
                  }
                />
                <TextField
                  fullWidth
                  label="Número de teléfono"
                  name='phoneNumber'
                  value={formState?.phoneNumber}
                  onChange={onInputChange}
                  variant="outlined"
                  style={textFieldStyle}
                  type="number"
                  error={errorNumber?.phoneNumber?.error}
                  helperText={errorNumber?.phoneNumber?.textMessage}
                />
              </>
            )}

            <FormControlLabel
              control={<Switch checked={planDetailsEnabled} onChange={handleTogglePlanDetails} />}
              label="Detalles del Plan"
            />
            {planDetailsEnabled && (
              <>
                {/* Campos de detalles del plan */}
                {
                  (role === "CLIENTE") && <>
                    <TextField
                      fullWidth
                      label="Pago De Inscripción"
                      name='pagoDeInscripcion'
                      value={formState?.pagoDeInscripcion}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}
                      type="number"
                      error={errorNumber?.pagoDeInscripcion?.error}
                      helperText={errorNumber?.pagoDeInscripcion?.textMessage}
                      required
                    />
                    <TextField
                      fullWidth
                      label=" $ Monto de plan"
                      name='planMensualMonto'
                      value={formState?.planMensualMonto}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}
                      type="number"
                      error={errorNumber?.planMensualMonto?.error}
                      helperText={errorNumber?.planMensualMonto?.textMessage}
                      required
                    />              
                    <FormControl style={selectStyle} fullWidth>
                      <InputLabel id="planMensual-label">Tipo de plan</InputLabel>
                      <Select
                        labelId="planMensual-label"
                        name="planMensualTipo"
                        value={formState?.planMensualTipo}
                        onChange={handleSelectChange}
                      >
                        <MenuItem value="SEMANAL">Semanal</MenuItem>
                        <MenuItem value="QUINCENAL">Quincenal</MenuItem>
                        <MenuItem value="MENSUAL">Mensual</MenuItem>
                        <MenuItem value="BIMESTRAL">Bimestral</MenuItem>
                        <MenuItem value="SEMESTRAL">Semestral</MenuItem>
                        <MenuItem value="CUATRIMESTRAL">Cuatrimestral</MenuItem>                        
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label="Fecha de inicio del plan"
                      name='fechaInicioPlan'
                      value={formState?.fechaInicioPlan}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}
                      type="date"
                      required
                    />
                  </>
                }
                {
                  (role === "ADMIN" || role === "EXPERTO_EN_EL_AREA") && <>
                    <TextField
                      fullWidth
                      label=" Salario Mensual"
                      name='salarioMensual'
                      value={formState?.salarioMensual}
                      onChange={onInputChange}
                      variant="outlined"
                      style={textFieldStyle}
                      type="number"
                      error={errorNumber?.salarioMensual?.error}
                      helperText={errorNumber?.salarioMensual?.textMessage}
                    />
                  </>
                }
                {/* {
                  (role === "SUPER_ADMIN") && <>
                    <TextField
                      fullWidth
                      label="Fecha de Inicio del plan"
                      name="fechaInicioPlan"
                      value={formState?.fechaInicioPlan}
                      onChange={onInputChange}
                      variant="outlined"
                      type="date"
                      style={textFieldStyle}
                      InputLabelProps={{
                        shrink: true, // Ajusta la posición de la etiqueta
                      }}                      
                    />
                  </>
                } */}
              </>
            )}

            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              size="large" 
              fullWidth 
              style={textFieldStyle} 
              disabled = { gralError.includes(true) }
            >
                Actualizar
            </Button>
          </form>
        </Box>
      </Container>
      {
        (handleBackendErrorsAboutUserEdit.status === 'in-progress') && <SnackBar severity='info' errorMsg='Actualizando usuario'></SnackBar>
      }
      {
        (handleBackendErrorsAboutUserEdit.status === 'failed') && <SnackBar severity='error' errorMsg={handleBackendErrorsAboutUserEdit?.BackendErrorMessage}></SnackBar>
      }
    </Paper>
  );
})

