import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DateTime } from 'luxon';

import { usePagination } from '../customhooks/usePagination';
import { useUsersActions } from '../customhooks/useUsersActions';
import { SnackBar } from '../atoms/SnackBar';
import Title from '../atoms/Title';
import UserActionsMenu from '../atoms/UserActionsMenu';
import { PaginationAtom } from '../atoms/PaginationAtom';
import { LoadingAtom } from '../atoms/LoadingAtom';
import SearchBar from '../atoms/SearchBar';
import { Chip, Grid, Paper } from '@mui/material';
import { searchThunk } from '../store/search/thunk';
import { Notifications } from '../atoms/Notifications';
import { FormReNewMembership } from './FormReNewMembership';
import { SEARCH } from '../interfaces/interfaces';
import { config } from '../config';


function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
}
const headerCellStyle = {
    fontWeight: 'bold',
    minWidth: 180,
}

export const SearchUser = React.memo(() => {
    //pagination
    const { page, usersPerPage, handleChangePage, startIndex, endIndex } = usePagination()
    const { handlUserEdit, handleConfirmDelete, handleOpenModal } = useUsersActions()
    //globalState
    const renewMembershipHandler = useSelector((state: RootState) => state?.renewMembership)
    const searchResults = useSelector((state: RootState) => state?.searchResults);
    const backendError = useSelector((state: RootState) => state?.searchResults?.BackendErrorMessage);
    const usersHandler = useSelector((state: RootState) => state?.deletedUsers);
    const { term, section }: SEARCH = useSelector((state: RootState) => state?.saveSearchTerm);
    //response failed
    let res = (searchResults.status === 'failed') ? <SnackBar status={searchResults?.status} severity='error' errorMsg={backendError} /> : (<TableRow><TableCell colSpan={10} align="center"><LoadingAtom /></TableCell></TableRow>)
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (term?.length > 2 && section) {
            dispatch(searchThunk(section, term || ''));
        }
    }, [term, section])

    return (
        <React.Fragment>
            <FormReNewMembership />
            <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto', borderRadius: '0', marginBottom: '8px' }}>
                    <Title>Buscando sobre clientes</Title>
                    <SearchBar />
                    {/* <div style={{width:'100%'}}>Total de resultados : {searchResults.totalResults}</div> */}
                </Paper>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={headerCellStyle}>Uid</TableCell>
                                <TableCell sx={headerCellStyle}>Nombre</TableCell>
                                <TableCell sx={headerCellStyle}>Apellido materno</TableCell>
                                <TableCell sx={headerCellStyle}>Apellido paterno</TableCell>
                                <TableCell sx={headerCellStyle}>Estado del plan</TableCell>
                                <TableCell sx={headerCellStyle}>Fecha de ingreso</TableCell>
                                <TableCell sx={headerCellStyle}>Fecha expiración del plan</TableCell>
                                <TableCell sx={headerCellStyle}>Fecha de nacimiento</TableCell>
                                <TableCell sx={headerCellStyle}>Rol de usuario</TableCell>
                                <TableCell sx={headerCellStyle}> Tipo de plan </TableCell>
                                <TableCell sx={headerCellStyle}>Monto del plan </TableCell>
                                <TableCell sx={headerCellStyle}> Email </TableCell>
                                <TableCell sx={headerCellStyle}> Teléfono </TableCell>
                                <TableCell sx={headerCellStyle} align="right">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults.status === 'success' ? (
                                searchResults.results.filter((results) => results?.role !== "SUPER_ADMIN").slice(startIndex, endIndex).map((user) => (
                                    <TableRow key={user?.uid}>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label={user?.uid} color="error" variant="outlined" /> : <Chip label={user?.uid} color="primary" variant="outlined" />}</TableCell>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label={user?.nombre} color="error" variant="outlined" /> : user?.nombre}</TableCell>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label={user?.apellidoMaterno} color="error" variant="outlined" /> : user?.apellidoMaterno}</TableCell>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label={user?.apellidoPaterno} color="error" variant="outlined" /> : user?.apellidoPaterno}</TableCell>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label='PENDIENTE DE PAGO' color="error" variant="outlined" /> : <Chip label='PLAN ACTIVO' color="success" variant="outlined" />}</TableCell>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label={
                                            DateTime.fromISO(user?.fechaInicioPlan).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                                                year: 'numeric',
                                                month: 'long',
                                                day: '2-digit'
                                            })
                                        } color="error" variant="outlined" /> : `${DateTime.fromISO(user?.fechaInicioPlan).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                                            year: 'numeric',
                                            month: 'long',
                                            day: '2-digit'
                                        })
                                        }`}</TableCell>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label={
                                            DateTime.fromISO(user?.fechaExpiracion).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                                                year: 'numeric',
                                                month: 'long',
                                                day: '2-digit'
                                            })
                                        } color="error" variant="outlined" /> : `${DateTime.fromISO(user?.fechaExpiracion).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                                            year: 'numeric',
                                            month: 'long',
                                            day: '2-digit'
                                        })
                                        }`}</TableCell>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label={
                                            DateTime.fromISO(user?.fechaNacimiento).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                                                year: 'numeric',
                                                month: 'long',
                                                day: '2-digit'
                                            })
                                        } color="error" variant="outlined" /> : `${DateTime.fromISO(user?.fechaNacimiento).setZone(user?.timeZone?.identifier).setLocale('es').toLocaleString({
                                            year: 'numeric',
                                            month: 'long',
                                            day: '2-digit'
                                        })
                                        }`} </TableCell>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label={user?.role} color="error" variant="outlined" /> : user?.role}</TableCell>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label={user?.planMensualTipo} color="error" variant="outlined" /> : user?.planMensualTipo}</TableCell>
                                        <TableCell>{`${(!user?.planStatusActivo) ? <Chip label={user?.planMensualMonto} color="error" variant="outlined" /> : user?.planMensualMonto}`}</TableCell>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label={user?.email} color="error" variant="outlined" /> : user?.email}</TableCell>
                                        <TableCell>{(!user?.planStatusActivo) ? <Chip label={user?.phoneNumber} color="error" variant="outlined" /> : user?.phoneNumber}</TableCell>                                        
                                        <TableCell align="right">
                                            <UserActionsMenu
                                                onEdit={() => handlUserEdit(user?.uid as string, user?.role as string)}
                                                onDelete={() => handleConfirmDelete(user?.uid as string)}
                                                onRenewMembership={ user?.role !== config?.CONST_ADMIN ? () => handleOpenModal(user?.uid as string) : undefined}
                                            />
                                        </TableCell>

                                    </TableRow>
                                ))
                            ) : res}
                        </TableBody>
                    </Table>
                    {/* <Pagination count={Math.ceil(searchResults.results.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px'}}/> */}
                    <PaginationAtom count={Math.ceil(searchResults?.results?.length / usersPerPage)} page={page} onChange={handleChangePage} color="primary" shape="rounded" style={{ marginTop: '10px' }} />


                    {(searchResults?.totalResults === 0) && <SnackBar severity='warning' errorMsg='No se econtraron resultados para esta búsqueda !'></SnackBar>}

                    <Notifications handler={usersHandler} actionName='usuario' actionType='Borrando' />
                    <Notifications handler={renewMembershipHandler} actionName='membresía' actionType='Renovando' />
                    <Notifications handler={searchResults} actionName='usuario' actionType='Buscando' />

                </Paper>
            </Grid>
        </React.Fragment >
    );
})