import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer';
import { Box, Button, Typography } from '@mui/material';

import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';

import { useSocketIo } from '../../customhooks/useSocketIo';
import { AppDispatch, RootState } from '../../store';
import Title from '../../atoms/Title';
import { Notifications } from '../../atoms/Notifications';
import { PaginationAtom } from '../../atoms/PaginationAtom';
import { usePagination } from '../../customhooks/usePagination';
import { basicColorsPalette } from '../../constants/ConstantsFlexSolutionManager';
import { getSalesRecordsThunk } from '../../store/sales';
import { Boxx } from './Boxx';
import { RevenueProyection } from './RevenueProyection';
import { getExpenseRecordsThunk } from '../../store/expenses';
import { useUsersActions } from '../../customhooks/useUsersActions';
import ExpensesActions from './ExpensesActions';

import { config } from '../../config';
import { AddExpenseForm } from './AddExpenseForm';
import { EditExpenseModal } from './EditExpenseModal';
import { getAllcategoriasThunk } from '../../store/categorias';
import { getAllProductosThunk } from '../../store/productos';

import { useHandlerExport } from '../customHook/useHandlerExport';

const headerCellStyle = {

    minWidth: 180,
    fontSize: basicColorsPalette['fontSize'],
    fontFamily: basicColorsPalette['fontFamily']
}
const tableCellSyles = {
    fontFamily: basicColorsPalette['fontFamily'],
    fontWeight: basicColorsPalette['tableHeafontWeight'],
}

export const AnalysisTable = () => {
    const now = new Date().toISOString();
    const [expenseId, setExpenseId] = useState<string>('');

    const { handleOpenModalType } = useUsersActions();
    const { exportXlsx , expenseXlsx } = useHandlerExport()
    // Pagination for sales table
    const { page: salesPage, usersPerPage: salesPerPage, handleChangePage: handleSalesPageChange, startIndex: salesStartIndex, endIndex: salesEndIndex } = usePagination();
    // Pagination for categories table
    const { page: expensesPage, usersPerPage: expensesPerPage, handleChangePage: handleCategoriesPageChange, startIndex: expensesStartIndex, endIndex: expensesEndIndex } = usePagination();

    //globalState
    const socketResponse = useSocketIo();
    const dispatch = useDispatch<AppDispatch>();
    const sales = useSelector((state: RootState) => state?.getSalesRecords);
    const expenses = useSelector((state: RootState) => state?.getExpenses);
    const updated = useSelector((state: RootState) => state?.updateExpense);
    const created = useSelector((state: RootState) => state?.createExpenseRecord);
    const categories = useSelector((state: RootState) => state?.categories?.categorias);
    const categoryUpdate = useSelector((state: RootState) => state?.updateCategory);
    const deleteCategory = useSelector((state: RootState) => state?.updateCategory);
    const postCategory = useSelector((state: RootState) => state?.updateCategory);
    const products = useSelector((state: RootState) => state?.productos?.productos);

    const paginatedSales = sales?.sales.slice(salesStartIndex, salesEndIndex);
    const paginatedExpenses = expenses?.expense?.slice(expensesStartIndex, expensesEndIndex);
    
    const handleExport = () => {        
        exportXlsx(sales?.sales , `${now}Reporte de ventas`, `Listado de ventas`);
    };
    const handlerExpenseExport =()=>{
        expenseXlsx(expenses?.expense , `${now} Analítica sobre gastos de la empresa`,'Lista de gastos')
    }
    useEffect(() => {
        dispatch(getAllcategoriasThunk())
    }, [
        dispatch,
        (categories && categories?.length === 0),
        categoryUpdate?.status === "success",
        deleteCategory?.status === "success",
        postCategory?.status === "success"
    ]);

    useEffect(() => {
        socketResponse?.status && (socketResponse?.type === 'sale' || socketResponse?.type === 'product') && dispatch(getSalesRecordsThunk('semestral'));

    }, [socketResponse, dispatch])

    useEffect(() => {
        dispatch(getExpenseRecordsThunk())
    }, [dispatch, updated?.status === "success", created?.status === "success", (expenses.expense && expenses.expense.length === 0)])

    useEffect(() => {
        dispatch(getAllProductosThunk());
    }, [
        dispatch,
        (products && products?.length === 0)
    ])
    const handlerAction = (expenseId: string) => {
        handleOpenModalType(config?.MODAL_EDIT_EXPENSE);
        setExpenseId(expenseId)
    }
    return (
        <>
            <EditExpenseModal modalType={config?.MODAL_EDIT_EXPENSE} expenseId={expenseId} />
            <RevenueProyection />
            <Boxx />
            <Grid item xs={12} md={12} lg={12} sx={{ width: '100%!important' }}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        overflowX: 'auto',
                        overflowY: 'auto',
                        boxShadow: 2
                    }}
                >   <Box>
                        <AddExpenseForm modalType={config?.MODAL_ADD_NEW_EXPENSE} expenseId={expenseId} />
                        <TableContainer>
                            <Box sx={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1100,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 2,
                                padding: 2,
                                boxShadow: 0
                            }}>
                                <Title>
                                    Analítica sobre productos vendidos {/** n días */}
                                </Title>
                                <Box>
                                    <Button sx={{ textTransform: 'none' }} onClick={ handleExport }>Exportar excel</Button>                                    
                                </Box>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={headerCellStyle}>Fecha de venta</TableCell>
                                        <TableCell sx={headerCellStyle}>Nombre del producto</TableCell>
                                        <TableCell sx={headerCellStyle}>Precio por unidad</TableCell>
                                        <TableCell sx={headerCellStyle}>Cantidad de productos vendidos</TableCell>
                                        <TableCell sx={headerCellStyle}>Tipo de venta</TableCell>
                                        <TableCell sx={headerCellStyle}>Vendido por</TableCell>
                                        <TableCell sx={headerCellStyle}>Se aplicó promoción ?</TableCell>
                                        <TableCell sx={headerCellStyle}>% descuento aplicado</TableCell>
                                        <TableCell sx={headerCellStyle}>$ Ingresos</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(sales && sales?.status === 'success') ? (
                                        paginatedSales.map((sale) => (
                                            <TableRow key={sale?._id}>
                                                <TableCell>{
                                                    DateTime.fromISO(sale?.saleDate).setLocale('es').toLocaleString({
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: '2-digit'
                                                    })}
                                                </TableCell>
                                                <TableCell>{sale?.product?.nombre?.toLocaleLowerCase()}</TableCell>
                                                <TableCell>
                                                    {sale?.unitPrice}
                                                </TableCell>
                                                <TableCell>{sale?.quantity}</TableCell>
                                                <TableCell>{sale?.serviceIdentifier}</TableCell>
                                                <TableCell>{(sale?.user?.nombre) ? sale?.user?.nombre : sale?.user?.email}</TableCell>
                                                {/* <TableCell
                                                    sx={{
                                                        color: 'green',
                                                        fontFamily: basicColorsPalette['fontFamily'],
                                                        fontWeight: basicColorsPalette['tableHeafontWeight'],
                                                    }}> ${sale?.amount}</TableCell> */}
                                                {
                                                    ( sale?.promotionStatus === true ) ? <TableCell sx={{ color : 'blue',fontWeight : 500 , fontFamily : basicColorsPalette['fontFamily']}}> Promoción aplicada </TableCell> 
                                                        : <TableCell sx={{ color: basicColorsPalette['blueColor'] , fontWeight : 500 , fontFamily : basicColorsPalette['fontFamily']} }> Venta normal </TableCell>  
                                                }
                                                {
                                                    (!sale?.promotionStatus) ? <TableCell sx={{ color : 'blue'}}> { sale?.discountCaptured } </TableCell> 
                                                        : <TableCell sx={{ color: basicColorsPalette['blueColor'] , fontWeight : basicColorsPalette['weigth'] , fontFamily : basicColorsPalette['fontFamily'] }}> -{ sale?.discountCaptured }% </TableCell>  
                                                }
                                                <TableCell sx={tableCellSyles}>
                                                    <KeyboardDoubleArrowUpOutlinedIcon sx={{ marginRight: '5px', color: basicColorsPalette['revenueColor'] }} />
                                                    ${sale?.amount}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">
                                                Error al cargar los datos
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <PaginationAtom
                                count={Math.ceil(sales?.sales?.length / salesPerPage)}
                                page={salesPage}
                                onChange={handleSalesPageChange}
                                color="primary"
                                shape="rounded"
                                style={{ marginTop: '10px' }}
                            />
                        </TableContainer>
                    </Box>
                    <Box sx={{ m: 4 }}>
                        <TableContainer>
                            <Box sx={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1100,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 2,
                                padding: 2,
                                boxShadow: 0
                            }}>
                                <Title>Analítica sobre gastos</Title>
                                <Box>
                                    <Button sx={{ textTransform: 'none' }} onClick={ handlerExpenseExport }>Exportar excel</Button>
                                    <Button sx={{ textTransform: 'none', ml: 1 }} onClick={() => handleOpenModalType(config?.MODAL_ADD_NEW_EXPENSE)}>Agregar gasto</Button>
                                </Box>
                            </Box>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={headerCellStyle}>ID</TableCell>
                                        <TableCell sx={headerCellStyle}>Identificador del gasto</TableCell>
                                        <TableCell sx={headerCellStyle}>Descripción</TableCell>
                                        <TableCell sx={headerCellStyle}> Fecha de captura</TableCell>
                                        <TableCell sx={headerCellStyle}>Categoría</TableCell>
                                        <TableCell sx={headerCellStyle}>Quién registró?</TableCell>
                                        <TableCell sx={headerCellStyle}>Fecha de última modificación</TableCell>
                                        <TableCell sx={headerCellStyle}>Quíen modificó el registro ?</TableCell>
                                        <TableCell sx={headerCellStyle}>$Total</TableCell>
                                        <TableCell sx={headerCellStyle} align="right">Opciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(expenses && expenses?.status === 'success') ? (
                                        paginatedExpenses?.map((expense) => (
                                            <TableRow key={expense?._id}>
                                                <TableCell>{expense?._id}</TableCell>
                                                <TableCell>{expense?.expensiveName}</TableCell>
                                                <TableCell>
                                                    {!expense?.prevFieldsValues?.description && !expense?.description && (
                                                        <Typography sx={{ color: '#888', textAlign: 'center' }}>
                                                            No hay descripción disponible
                                                        </Typography>
                                                    )}
                                                    {expense?.prevFieldsValues?.description ? (
                                                        <>
                                                            <Typography sx={{ color: '#ef5350', textDecorationLine: 'line-through' }}>
                                                                &laquo;{expense.prevFieldsValues.description}
                                                            </Typography>
                                                            <Typography sx={{ color: '#66bb6a' }}>
                                                                &raquo;{expense.description}
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <Typography>
                                                            {expense.description}
                                                        </Typography>
                                                    )}

                                                </TableCell>
                                                <TableCell>
                                                    {!expense?.prevFieldsValues?.registerDate && !expense?.registerDate && (
                                                        <Typography sx={{ color: '#888', textAlign: 'center' }}>
                                                            No hay fecha de registro
                                                        </Typography>
                                                    )}

                                                    {expense?.prevFieldsValues?.registerDate && (<>
                                                        <Typography sx={{ color: '#ef5350', textDecorationLine: 'line-through' }}>
                                                            &laquo;{
                                                                DateTime.fromISO(expense.prevFieldsValues.registerDate).setLocale('es').toLocaleString({
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                    day: '2-digit'
                                                                })
                                                            }
                                                        </Typography>
                                                        <Typography sx={{ color: '#66bb6a' }}>
                                                            &raquo;{
                                                                DateTime.fromISO(expense?.registerDate).setLocale('es').toLocaleString({
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                    day: '2-digit'
                                                                })
                                                            }
                                                        </Typography>
                                                    </>
                                                    )}
                                                    {!expense?.prevFieldsValues?.registerDate && (
                                                        <Typography>
                                                            {
                                                                DateTime.fromISO(expense?.registerDate).setLocale('es').toLocaleString({
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                    day: '2-digit'
                                                                })
                                                            }
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                {/* <TableCell> { expense?.description ? expense.description : 'No hay descripción disponible' } </TableCell> */}
                                                <TableCell>{expense?.category?.nombre ? expense?.category?.nombre : 'No está asociado a una categoría'}</TableCell>
                                                <TableCell>
                                                    {!expense?.prevFieldsValues?.registeredBy && !expense?.registeredBy && (
                                                        <Typography sx={{ color: '#888', textAlign: 'center' }}>
                                                            No hay dato de quíen capturó el gasto
                                                        </Typography>
                                                    )}
                                                    {expense?.prevFieldsValues?.registeredBy ? (<>
                                                        <Typography sx={{ color: '#ef5350', textDecorationLine: 'line-through' }}>
                                                            &laquo; {expense?.prevFieldsValues?.registeredBy?.nombreEmpresa || expense?.prevFieldsValues?.registeredBy?.nombre || expense?.prevFieldsValues?.registeredBy?.email}
                                                        </Typography>
                                                        <Typography sx={{ color: '#66bb6a' }}>
                                                            &raquo;{expense?.registeredBy?.nombreEmpresa || expense?.registeredBy?.nombre || expense?.registeredBy?.email}
                                                        </Typography>
                                                    </>
                                                    ) : <Typography>
                                                        {expense?.registeredBy?.phoneNumber ? expense?.registeredBy?.phoneNumber : expense?.registeredBy?.nombre}
                                                    </Typography>
                                                    }
                                                </TableCell>
                                                {
                                                    expense?.lastEditDate ?
                                                        <TableCell
                                                            sx={{
                                                                color: basicColorsPalette?.colorChanged
                                                            }}
                                                        >
                                                            {
                                                                DateTime.fromISO(expense?.lastEditDate).setLocale('es').toLocaleString({
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                    day: '2-digit'
                                                                })
                                                            }
                                                        </TableCell> : <TableCell sx={{
                                                            color: basicColorsPalette?.colorNotChanged
                                                        }}>
                                                            No ha sido modificado
                                                        </TableCell>
                                                }
                                                <TableCell>{expense?.editedBy?.nombre || expense?.editedBy?.nombreEmpresa || 'No ha sido modificado'}</TableCell>
                                                <TableCell sx={tableCellSyles}>
                                                    {
                                                        expense?.prevFieldsValues?.total && (
                                                            <>
                                                                <KeyboardDoubleArrowDownOutlinedIcon sx={{ marginRight: '5px', color: basicColorsPalette['costColor'] }} />
                                                                <Typography sx={{ color: '#ef5350', textDecorationLine: 'line-through' }}> &laquo; ${expense?.prevFieldsValues?.total}</Typography>
                                                                <Typography sx={{ color: '#66bb6a' }}>
                                                                    &raquo; ${expense?.total}
                                                                </Typography>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        !expense?.prevFieldsValues?.total && (
                                                            <>
                                                                <KeyboardDoubleArrowDownOutlinedIcon sx={{ marginRight: '5px', color: basicColorsPalette['costColor'] }} />
                                                                <Typography>
                                                                    ${expense?.total}
                                                                </Typography>
                                                            </>
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell align="right">
                                                    <ExpensesActions
                                                        onEdit={() => handlerAction(expense?._id)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">
                                                Error al cargar los datos
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <PaginationAtom
                                count={Math.ceil(expenses.expense.length / expensesPerPage)}
                                page={expensesPage}
                                onChange={handleCategoriesPageChange}
                                color="primary"
                                shape="rounded"
                                style={{ marginTop: '10px' }}
                            />
                        </TableContainer>
                    </Box>
                </Paper>
                <Notifications handler={created} actionType='Create' actionName='crete-expense' />
                <Notifications handler={updated} actionType='Edit' actionName='update-records' />
            </Grid>
        </>
    );
}
