import { useEffect, useState } from "react";
import { config } from "../../config";
import { openModal } from "../../store/modal";
import { Box, Button, Typography } from "@mui/material";
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { getAllTenantInscriptionThunk } from "../../store/usuarios";
import { InscriptionModal } from "../atoms/InscriptionModal";
import { InscriptionCard } from "../components/InscriptionCard";

export const InscriptionPayment = () => {
    const dispatch = useDispatch<AppDispatch>();
    const incriptions = useSelector((state: RootState) => state?.getInscriptionRecords?.inscriptions )
    const deleteIt = useSelector((state: RootState) => state?.deleteInscriptionPayment?.status )
    const createIt = useSelector((state: RootState) => state?.postInscriptionPayment?.status );
    const updateIt = useSelector((state: RootState) => state?.updateTenantInscription?.status );
    
    const handleAdd = () => {
      dispatch({
        type: openModal?.type,
        payload: config?.MODAL_CREATE_INSCRIPTION_PAYMENT
      })
    }
  
    const [idEdit, setIdEdit ] = useState<string>('')
    
    useEffect(()=>{
      dispatch( getAllTenantInscriptionThunk() );
    },[ incriptions?.length === 0 , dispatch ,createIt === 'success', deleteIt === 'success',updateIt ==='success'])
    
    return (
      <Box sx={ { display: 'flex', flexDirection: 'column', p: 3 }}>
        <Box sx={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 } }>
          <Typography variant="h5" component="div" color="primary">Pago de Inscripción</Typography>
          <Button
            variant="outlined"
            color="primary"
            startIcon={ <AddIcon/> }
            onClick={ handleAdd }
          >
            Añadir
          </Button>
        </Box>

        <InscriptionModal modalType={ config?.MODAL_CREATE_INSCRIPTION_PAYMENT } action="crear"/>
        <InscriptionModal modalType={ config?.MODAL_UPDATE_INSCRIPTION_PAYMENT } action="editar" idEdit={ idEdit }/>
  
        <InscriptionCard inscriptionsList = { incriptions } setIdEdit ={ setIdEdit } />
        
      </Box>
    )
  }