import axios, { AxiosResponse } from "axios"
import { stagingController } from "../../utils/stagingController";
import { developmentConfig } from "../../developmentConfig";
import { config } from "../../config";


let host = stagingController();

export const getExpensesRecords = async () => {
    try {
        let xToken = localStorage.getItem('token');
        const headers = {
            'x-token': xToken,
            'Content-Type': 'application/json',
        };
        const response = await axios.get(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/gastos/records`, { headers })
        
        if (response.status === 200 || response.status === 201) {
            const data = response.data;
            return {
                ok: true,
                data
            };
        }
    } catch (error) {
        console.error("expense error", error)
        if (error?.response?.data?.msg) {
            return {
                ok: false,
                error: error?.response?.data?.msg
            };
        } else if (error?.response?.data?.error) {
            return {
                ok: false,
                error: error?.response?.data?.error
            };
        } else if (error?.response?.data?.errors) {
            const errors = error?.response?.data?.errors;
            if (Array.isArray(errors)) {
                errors.forEach(err => {
                    return {
                        ok: false,
                        error: err?.msg
                    }
                });
            } else {
                return {
                    ok: false,
                    error: 'Errors is not an array'
                }
            }
        }

    }
}

export const updateExpenseProvider = async (formState : any, uidExpense :string|null) => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json'
    };

    const data = {
        ...formState
    }
    return await axios.patch(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/gastos/expense/${ uidExpense }`, JSON.stringify(data), {
        headers
    })
    .then((response: AxiosResponse) => {        
        const data = response?.data;
        return {
            ok: true,
            data
        };
    })
    .catch((error) => {        
        let e = error?.response?.data
        if(e.msg){
            return {
                ok: false,
                error: e?.msg
            };
        }else if( e?.error){
            return {
                ok: false,
                error: e?.error
            };
        }else if( e?.errors){
            const validationErrors = error?.response?.data?.errors;
                const categoryError = validationErrors.find((error : any) => error );
    
                if (categoryError) {
                    return {
                        ok: false,
                        error: categoryError?.msg
                    };
                }
        }
    })
}

export const createExpenseProvider = async (formState : any)=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    const data = {
        ...formState
    }
    return await axios.post(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/gastos/expense`, JSON.stringify(data),{ headers })
        .then((response:AxiosResponse) => {            
            const data = response.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{
            if (error?.response && error?.response?.data && error?.response?.data?.errors) {
                const validationErrors = error?.response?.data?.errors;
                const categoryError = validationErrors.find((error : any) => error.path === 'categoria');
    
                if (categoryError) {
                    return {
                        ok: false,
                        error: categoryError?.msg
                    };
                }
            }
            return {
                ok: false,
                error : error?.response?.data?.msg
            };
            
        }) 
}