import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
//import {ErrorPage} from '../atoms/ErrorRouteMessage'
import ErrorRouterMessage from '../atoms/ErrorRouteMessage';

import Navbar from "../components/Navbar";

import { RegisterFormSuper_admin } from '../components/RegisterFormSuper_admin';
import Dashboard from "../screens/admin/Dashboard";
import { Clientes } from "../screens/Clientes";
import { Inactivos } from "../screens/Inactivos";
import { Eventos } from "../components/Eventos";
import { Categorias } from "../components/Categorias";
import { Productos } from "../components/Productos";
import { Configuraciones } from "../components/Configuraciones";
import { RegisterScreen } from "../screens/RegisterScreen";
import { FormEventos } from "../components/FormEventos";
import { FormCategorias } from "../components/FormCategorias";
import { FormProductos } from "../components/FormProductos";
import { FormEventosUpdate } from "../components/FormEventosUpdate";
import { FormCategoriasUpdate } from "../components/FormCategoriasUpdate";
import { FormProductosVender } from '../components/FormProductosVender';
import { FormEventosAddAnUser } from "../components/FormEventosAddAnUser";
import { FormDetallesDelEvento } from "../components/FormDetallesDelEvento";
import { SearchScreens } from '../screens/SearchScreens';
import { SaleDetails } from "../atoms/SaleDetails";
import { FormUserEdit } from "../components/FormUserEdit";
import { FormProductUpdate } from "../components/FormProductUpdate";
import CommingSoon from "../atoms/CommingSoon";
import SalesDetailsAdmin from "../atoms/SalesDetailsAdmin";
import { Analitica } from "../screens/Analitica";
import { PriceDefinitions } from "../components/PriceDefinitions";

// define rutas de la aplicaciòn con createBrowserRouter


export const privateRoutes = createBrowserRouter(
  // if(isAuthenticated){
  //   return <Checking
  // }
  [
    {
        path : '/', //controlar que cuando le manden solo app, los redirija a dashboard
        element: <Navbar/>,
        errorElement: <ErrorRouterMessage/>,
        children : [
          {
            path: "/*", 
            element: <Dashboard/>,
          },
          {
            path : "/dashboard",
            element :<Dashboard/>
          },
          {
            path : "/user/registro",
            element :<RegisterScreen/>
          },
          {
            path : "/user/eventos",
            element :<Eventos/> // agregar eventos aun falta aqui
          },
          {
            path : "/user/clientes",
            element :<Clientes/>
          },
          {
            path : "/user/inactivos",
            element :<Inactivos/>
          },{
            path : "/user/categorias",
            element :<Categorias/>
          },
          {
            path : "/user/productos",
            element :<Productos/>
          },
          {
            path : "/user/eventos/crear",
            element :<FormEventos/>
          },
          {
            path : "/user/eventos/actualizar",
            element :<FormEventosUpdate/>
          },
          {
            path : "/user/eventos/addAnUser",
            element :<FormEventosAddAnUser/>
          },
          {
            path : "/user/eventos/:id/detalles",
            element :<FormDetallesDelEvento/>
          },
          {
            path : "/user/categorias/crear",
            element :<FormCategorias/>
          },
          {
            path : "/user/categorias/actualizar",
            element :<FormCategoriasUpdate/>
          },
          {
            path : "/user/productos/crear",
            element :<FormProductos/>
          },
          {
            path : "/user/productos/actualizar",
            element :<FormProductos/>
          },
          {
            path : "/user/productos/vender",
            element :<FormProductosVender/>
          },
          {
            path : "/user/configuraciones",
            element :<Configuraciones/>
          },
          {
            path : "/search",
            element :<SearchScreens/>
          },
          {
            path : "/detalles",
            element : <SaleDetails/> 
          },
          {
            path: "/detallesadmin",
            element: <SalesDetailsAdmin />
          },
          {
            path : "/user/edit/:id/:role",
            element :<FormUserEdit/>
          },
          // rutas para las subsecciones de la seccion de Empresa
          {
            path : "/contratos",
            element : <CommingSoon />
          },
          {
            path: "/priceDefinitions",
            element : <PriceDefinitions />
          },
          {
            path : "/security",
            element : <CommingSoon />
          },
          {
            path : "/general",
            element : <CommingSoon />
          },
          {
            path: "/employees",
            element : <CommingSoon />
          },
          {
            path: "/assistant",
            element : <CommingSoon />
          },
          // nuevas rutas para experto en el area
          {
            path: "/analytics",
            element : <CommingSoon />
          },
          {
            path: '/multimedia',
            element: <CommingSoon />
          },
          {
            path: '/analitica',
            element: <Analitica />
          }
        ],
    }
]); 

  
