import { createSlice } from "@reduxjs/toolkit";

export const updateTenantInscriptionSlice = createSlice({
    name: "editTenantInscriptionPrice",
    initialState: {
        status: "initial",
        msg: null,
        inscription: null,
        BackendErrorMessage: null,
    },
    reducers: {
    failedUpdateInscription: (state, { payload }) => {
        state.status = "failed";
        state.inscription = null;
        state.BackendErrorMessage = payload;
    },
    updateInscription: (state, { payload }) => {
        state.status = "success";
        state.msg = payload.msg;
        state.inscription = payload.inscription;
        state.BackendErrorMessage = null;
    },
    updatingInscription: (state) => {
        state.status = "in-progress";
    },
    },
});

export const { failedUpdateInscription, updateInscription, updatingInscription } = updateTenantInscriptionSlice.actions;