import { EXPENSE_RECORDS } from '../../interfaces/interfaces';
import { ApiResponse } from '../../types/types';
import { AppDispatch } from '../store';
import { failedGetRecord, gettingRecords, getExpensiveRecords, getExpensesRecords, updateExpenseProvider , updatingExpense, expenseUpdated, failedUpdateExpense, failedCreateExpense, expenseCreated, createExpenseProvider, creatingExpense} from './index'
//import { updatingExpense } from './slides/updateExpenseSlide';
export const getExpenseRecordsThunk = () => {
    return async (dispatch: AppDispatch) => {
        dispatch({
            type: gettingRecords.type,
            payload: null
        })
        try {
            const res: ApiResponse = await getExpensesRecords();

            if (res?.ok) {
                const { data } = res;
                dispatch({
                    type: getExpensiveRecords.type,
                    payload: data
                })
            } else {
                dispatch({
                    type: failedGetRecord.type,
                    payload: res?.error
                })
            }
        } catch (error) {
            dispatch({
                type: failedGetRecord.type,
                payload: error
            })
        }
    }
}


export const startExpenseUpdate = (formState: any, id: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch({
            type: updatingExpense?.type,
            payload: null
        });

        try {
            const res: ApiResponse = await updateExpenseProvider(formState, id);

            if (res?.ok) {
                const { data } = res;
                dispatch({
                    type: expenseUpdated?.type,
                    payload: data
                });
                return {
                    ok: true
                };
            } else {
                dispatch({
                    type: failedUpdateExpense?.type,
                    payload: res?.error
                });
                return {
                    ok: false
                };
            }
        } catch (error) {
            console.error(error);
            dispatch({
                type: failedUpdateExpense?.type,
                payload: error
            });
            return {
                ok: false
            };
        }
    };
};


export const createExpenseRecordThunk = (formState: any) => {
    return async (dispatch: AppDispatch) => {
        dispatch({
            type: creatingExpense?.type,
            payload : null
        })

        try {
            const res: ApiResponse = await createExpenseProvider(formState);

            if (res?.ok) {
                const { data } = res;
                dispatch({
                    type: expenseCreated?.type,
                    payload: data
                })
                return {
                    ok: true
                }
            } else {
                dispatch({
                    type: failedCreateExpense?.type,
                    payload: res?.error
                })
                return {
                    ok: false
                }
            }
        }
        catch (error) {
            console.error(error);
            dispatch({
                type: failedCreateExpense?.type,
                payload: error
            })
        }
    }
}