import { Box, Typography } from '@mui/material';
import { useLoadFile } from '../customHook/useLoadFile';

export const LoadFile = () => {
    const { file, fileType, onInputChange } = useLoadFile();
    const imagePreview = file ? URL.createObjectURL(file) : '';

    const isImage = fileType?.startsWith('image/');

    return (
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <input
                accept="image/*"
                type="file"
                name="img"
                onChange={onInputChange}
                style={{ marginBottom: '16px', display: 'block' }}
            />
            {file && (
                <>
                    {isImage ? (
                        <>
                            <p>Imagen seleccionada: {file.name}</p>
                            <img src={imagePreview} alt="Vista previa" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </>
                    ) : (
                        <Typography color="error">El archivo seleccionado no es una imagen.</Typography>
                    )}
                </>
            )}
        </Box>
    );
};

