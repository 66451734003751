import { TextField, Button, Box, FormControl, InputLabel, Select, MenuItem, Typography, Modal } from '@mui/material';
import { useForm } from '../../customhooks';
import { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useHandlerModal } from '../../customhooks/useHandlerModal';
import { useFormStateValidation } from '../../customhooks/useFormStateValidation';
import { EXPENSE_PROPS } from '../../interfaces/interfaces';

import { formContainerStyles, formsStyles, btnGralStyles } from '../../constants/ConstantsFlexSolutionManager'
import Title from '../../atoms/Title';
import { createExpenseRecordThunk } from '../../store/expenses';
import { LoadFile } from '../atoms/LoadFile';
import { config } from '../../config';
export const AddExpenseForm = ({ modalType, expenseId }: EXPENSE_PROPS) => {
    const dispatch = useDispatch<AppDispatch>();
    
    const { handleClose } = useHandlerModal();
    

    const currentModalType = useSelector((state: RootState) => state?.modal?.modalType);
    const open = useSelector((state: RootState) => state?.modal?.openModal);
    const categories = useSelector((state: RootState) => state?.categories?.categorias);
    const privilegies = useSelector((state: RootState) => state?.auth)
    
    const { formState, onInputChange, onResetForm } = useForm(
        {
            expensiveName: '',
            description: '',
            registerDate: '',
            total: '',
            img: '',
            category: '',
        }
    )

    const { 
        errorField, 
        errorNumber, 
        validateNumber, 
        gralError, 
        validateField 
    } = useFormStateValidation();

    useEffect(() => {
        validateNumber(formState?.total as string, 'total');
        validateField('expensiveName', formState?.expensiveName as string);
        validateField('description', formState?.description as string);
    }, [
        formState?.total,
        formState?.expensiveName,
        formState?.description,
    ]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const useToResetForm = await dispatch(createExpenseRecordThunk(formState));
        if (useToResetForm?.ok === true) {
            onResetForm();
            handleClose();
        }
    };

    return (
        <>
            {
                modalType === currentModalType && (
                    <Modal open={open} onClose={handleClose} aria-labelledby="Actualizar producto" aria-describedby="editar un registro de gasto">
                        <Box component='form' onSubmit={handleSubmit} sx={{ formContainerStyles }}>
                            <Box sx={formsStyles}>
                                <Title>Crear registro de gasto</Title>
                                <TextField
                                    label="Nombre del gasto"
                                    name="expensiveName"
                                    value={ formState?.expensiveName }
                                    onChange={onInputChange}
                                    error = { errorField?.expensiveName?.error }
                                    helperText = { errorField?.expensiveName?.textMessage }
                                    fullWidth
                                    required
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    label="Descripción"
                                    name="description"
                                    value={formState.description}
                                    onChange={onInputChange}
                                    error = { errorField?.description?.error }
                                    helperText = { errorField?.description?.textMessage }
                                    fullWidth
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    label="Fecha de registro"
                                    name="registerDate"
                                    type="date"
                                    value={formState.registerDate}
                                    onChange={onInputChange}
                                    fullWidth
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginBottom: 2 }}
                                />
                                <TextField
                                    label="Total"
                                    name="total"
                                    type="number"
                                    value={formState.total}
                                    onChange={onInputChange}
                                    error = { errorNumber?.total?.error }
                                    helperText = { errorNumber?.total?.textMessage }
                                    fullWidth
                                    required
                                    sx={{ marginBottom: 2 }}
                                />
                                {
                                    privilegies?.licencia === config?.PLAN_BASICO ? <Typography sx={{ marginTop : 2, marginBottom : 2 , fontWeight : 600}}> La carga de imágenes está disponible en planes mayores al básico</Typography> : <LoadFile />
                                }   
                                <FormControl fullWidth required sx={{ marginBottom: 2 }}>
                                    <InputLabel>Categoría a la que pertenece</InputLabel>
                                    <Select
                                        name="category"
                                        value={formState.category}
                                        onChange={onInputChange as any}
                                    >
                                        {categories.map((cat) => (
                                            <MenuItem key={cat?._id} value={cat?._id}>
                                                {cat?.nombre}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography variant="caption" color="error">
                                        {/* {errors.category} */}
                                    </Typography>
                                </FormControl>
                                <Button type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={btnGralStyles}
                                    disabled = { gralError.includes(true) }
                                    fullWidth
                                >
                                    Crear registro
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                )
            }
        </>
    );
}