import './App.css';
import {
  RouterProvider,
} from "react-router-dom";
import { Provider, useSelector } from 'react-redux';
import { router } from './routes/Routes';
import { privateRoutes } from './routes/PrivateRouter'
import { RootState, store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { InitialConfigNotifications } from './components/InitialConfigNotifications';
import { Chatwoot } from './Chatwoot';

function App() {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.status === 'authenticated'
  );

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {(isAuthenticated) ? <> <RouterProvider router={privateRoutes} /> <InitialConfigNotifications /></> : <>  <RouterProvider router={router} /> <Chatwoot></Chatwoot></>}
      </PersistGate>
    </Provider>
  );
}

export default App;
