import { createSlice } from "@reduxjs/toolkit";

export const deletePromotionSlice = createSlice({
    name: "deletePromotion",
    initialState: {
        status: "initial",
        msg: null,
        BackendErrorMessage: null,
    },
    reducers: {
    failedDeletePromotion: (state, { payload }) => {
        state.status = "failed";
        state.msg = null;
        state.BackendErrorMessage = payload;
    },
    deletePromotion: (state, { payload }) => {
        state.status = "success";
        state.msg = payload.msg;
        state.BackendErrorMessage = null;
    },
    deletingPromotion: (state) => {
        state.status = "in-progress";
    },
},
});

export const { failedDeletePromotion, deletePromotion, deletingPromotion } = deletePromotionSlice.actions;