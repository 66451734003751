import { useAnalysis } from '../customHook/useAnalisys'

import { Grid, Paper, Typography, Box } from '@mui/material';

import SellIcon from '@mui/icons-material/Sell';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { basicColorsPalette } from "../../constants/ConstantsFlexSolutionManager";

export const Boxx = () => {
    const { mostSeller, bestSale } = useAnalysis();
    const gettingBestSaleProduct = Object.keys(bestSale)[0];
    const productAnalysis = bestSale[gettingBestSaleProduct];
    
    return (
        <Grid item xs={12} md={4} lg={3}>
            <Paper
                sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 250,
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 3,
                }}
            >
                {mostSeller ? (
                    <>
                        <Typography variant="h5" sx={{
                            color : basicColorsPalette?.color,
                            fontFamily : basicColorsPalette?.fontFamily,
                            fontSize : basicColorsPalette?.titleSize
                        }} gutterBottom>
                            Producto más vendido
                        </Typography>
                        <Box display="flex" alignItems="center" mb={1}>
                            <ShowChartIcon color="info" sx={{ mr: 1 }} />
                            <Typography variant="body1" gutterBottom sx={{
                                color : basicColorsPalette?.revenueColor,
                                fontSize : basicColorsPalette?.titleSize,
                                fontWeight : basicColorsPalette?.weigth
                            }}>
                                {mostSeller?.product?.nombre.toLocaleLowerCase()}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={1}>
                            <SellIcon color="primary" sx={{ mr: 1 }} />
                            <Typography variant="body1">
                                Número de unidades vendidas: <strong>{productAnalysis?.quantity}</strong>
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <PointOfSaleIcon color="primary" sx={{ mr: 1 }} />
                            <Typography variant="body1">
                                Este producto generó: <strong>${productAnalysis?.amount}</strong>
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Typography variant="body1">No hay datos de ventas disponibles</Typography>
                )}
            </Paper>
        </Grid>
    )
}