import React, { useState, ChangeEvent, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Chip, Grid, Paper, Typography } from '@mui/material';
import { useForm } from '../customhooks/useForm';
import { getAllCustomMembershipThunk, getAllPromotionsThunk, getAllTenantInscriptionThunk, startRegisterClientThunk } from '../store/usuarios';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import { SnackBar } from '../atoms/SnackBar';
import { useFormStateValidation } from '../customhooks/useFormStateValidation';
import { getTimeZoneFromUser } from '../utils/timeZoneParser';
import { TimeZone } from '../interfaces/interfaces';
import { usePriceDefination } from '../customhooks/usePriceDefination';
import { usePromotion } from '../customhooks/usePromotion';
import { config } from '../config';


const paperStyle = {
  padding: '20px',
  margin: 'auto auto auto 30px',
  borderRadius: '10px',
  width: '100%',
  marginTop: '25px',
  //maxWidth: '500px',
};
const textFieldStyle = {
  marginBottom: '20px',
  marginTop: '10px',
  width: '100%'
};

const selectStyle = {
  marginBottom: '20px',
  marginTop: '40px'
};

const buttonStyle = {
  marginTop: '20px',
  width: '40%'
};

export const RegisterClients: React.FC = () => {

  const now = DateTime.now();
  const [activeStep, setActiveStep] = useState(0);
  const [errorCustomMembership, setErrorCustomMembership] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const clientsHandler = useSelector((state: RootState) => state?.clientsRegister);
  const customMembership = useSelector((state: RootState) => state?.customMembership?.customMembership);
  const promotions = useSelector((state: RootState) => state?.getPromotion?.promotions);
  const inscription = useSelector((state: RootState) => state?.getInscriptionRecords?.inscriptions);
  const inscriptionPrice = inscription?.[0];

  const timeZoneData = getTimeZoneFromUser();
  const [applyPromotion, setApplyPromotion] = useState(false);
  const {
    totalConDescuento,
    tmpPagoInscripcion,
    tmpPlanMonto,
    applyPromotionOnUserRegistration,
    handlerSetPromotion
  } = usePromotion();

  const { handleSelectChange, onInputChange, formState, onResetForm, setFormState } = useForm({
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    email: '',
    fechaNacimiento: '',
    direccion: '',
    fechaIngreso: '',
    promotionStatus: applyPromotion,
    planMensualTipo: '',
    planStatusActivo: 'true',
    planMensualMonto: '',
    phoneNumber: '',
    fechaInicioPlan: '',
    pagoDeInscripcion: inscriptionPrice?.price || 0,
    timeZone: {
      identifier: timeZoneData.userTimeZone,
      offset: timeZoneData.userOffset
    },
    role: config?.CONST_CLIENTE,
  })
  const {
    error,
    errorField,
    fieldFormatError,
    handleEmail,
    dateBorn,
    dateBornError,
    gralError,
    dateValidation,
    validateField,
    inscriptionPaymentValidator
  } = useFormStateValidation();

  const { selectPlan } = usePriceDefination();
  
  useEffect(() => {
    dispatch( getAllTenantInscriptionThunk() );
    dispatch(getAllCustomMembershipThunk());
    dispatch(getAllPromotionsThunk());
  }, [ dispatch , inscription?.length === 0 , customMembership?.length === 0 , promotions?.length === 0]);

  const handleChangeSelectorPlan = (event: SelectChangeEvent<string | number | Function | Boolean | TimeZone | null>) => {
    selectPlan(event, customMembership, setFormState, setErrorCustomMembership);
  }

  /* Srepper */
  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBackStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    const email = formState?.email
    const dateBorn = formState?.fechaNacimiento
    handleEmail(email as string)
    dateValidation(dateBorn as string)
    validateField('nombre', formState?.nombre as string);
    validateField('apellidoPaterno', formState?.apellidoPaterno as string);
    validateField('apellidoMaterno', formState?.apellidoMaterno as string);
    validateField('direccion', formState?.direccion as string);
    inscriptionPaymentValidator('pagoDeInscripcion', parseFloat(formState?.pagoDeInscripcion as string))
  }, [
    formState.email,
    formState.fechaNacimiento,
    formState?.nombre,
    formState?.apellidoPaterno,
    formState?.apellidoMaterno,
    formState?.direccion
  ]);

  const handlePromotionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setApplyPromotion(event?.target?.checked);
  };

  const handlerSelectedPromotion = (event: SelectChangeEvent<string>) => {
    handlerSetPromotion(event.target.value, promotions, setFormState);
  }

  useEffect(() => {
    applyPromotionOnUserRegistration(
      applyPromotion,
      formState?.promotion,
      promotions,
      formState?.pagoDeInscripcion as number,
      formState?.planMensualMonto as number
    );
  }, [applyPromotion, formState.promotion, formState.pagoDeInscripcion, formState.planMensualMonto]);

  const handleSubmit = async () => {
    const updatedFormState = {
      ...formState,
      pagoDeInscripcion: tmpPagoInscripcion,
      planMensualMonto: tmpPlanMonto,
    };
    const success = await dispatch(startRegisterClientThunk(updatedFormState, navigate));
    if (success?.ok === true) {
      onResetForm()
    }
  };

  //const steps = ['Datos Personales', 'Dirección', 'Detalles del Plan'];
  const steps = [
    'Datos Personales',
    'Datos de la empresa',
    'Datos bancarios',
    'Revisar y Registrar',
  ];

  return (
    <Paper style={paperStyle}>
      <Typography variant="h6" align="center" mb={3}>
        Registro de Clientes
      </Typography>
      <Stepper activeStep={activeStep} sx={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', marginBottom: '3px' }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }} noValidate autoComplete="off">
        {activeStep === 0 && (
          <div>
            <TextField
              name="nombre"
              label="Nombre"
              value={formState?.nombre}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              required
              error={errorField?.nombre?.error}
              helperText={errorField?.nombre?.textMessage}
            />
            <TextField
              name="apellidoPaterno"
              label="Apellido Paterno"
              value={formState?.apellidoPaterno}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              error={errorField?.apellidoPaterno?.error}
              helperText={errorField?.apellidoPaterno?.textMessage}
            />
            <TextField
              name="apellidoMaterno"
              label="Apellido Materno"
              value={formState?.apellidoMaterno}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              error={errorField?.apellidoMaterno?.error}
              helperText={errorField?.apellidoMaterno?.textMessage}
            />
            <TextField
              name="email"
              label="Email"
              value={formState?.email}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              required
              error={fieldFormatError}
              helperText={
                fieldFormatError ? error : ''
              }
            />
            <TextField
              name="fechaNacimiento"
              label="Fecha de Nacimiento"
              value={formState?.fechaNacimiento}
              onChange={onInputChange}
              type="date"
              style={textFieldStyle}
              fullWidth
              error={dateBornError}
              helperText={
                dateBornError && dateBorn
              }
              InputLabelProps={{
                shrink: true, // Ajusta la posición de la etiqueta
              }}
            />
          </div>
        )}
        {activeStep === 1 && (
          <div>
            <TextField
              name="direccion"
              label="Dirección"
              value={formState?.direccion}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              required
              error={errorField?.direccion?.error}
              helperText={errorField?.direccion?.textMessage}
            />
            <TextField
              name="phoneNumber"
              label="Número de teléfono"
              value={formState?.phoneNumber}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              required
            />
          </div>
        )}
        {activeStep === 2 && (
          <div>
            <FormControl style={selectStyle} fullWidth>
              <InputLabel id="planMensual-label">Tipo de plan</InputLabel>
              <Select
                label="Tipo de plan"
                labelId="planMensual-label"
                name="planMensualTipo"
                value={formState?.planMensualTipo}
                onChange={handleChangeSelectorPlan}
              >
                <MenuItem value="mensual">Mensual</MenuItem>
                <MenuItem value="bimestral">Bimestral</MenuItem>
                <MenuItem value="semanal">Semanal</MenuItem>
                <MenuItem value="semestral">Semestral</MenuItem>
                <MenuItem value="quincenal">Quincenal</MenuItem>
                <MenuItem value="anual">Anual</MenuItem>
                <MenuItem value="cuatrimestral">Cuatrimestral</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="fechaInicioPlan"
              label="Fecha de inicio del plan"
              value={formState?.fechaInicioPlan}
              type="date"
              onChange={onInputChange}
              style={textFieldStyle}
              InputLabelProps={{
                shrink: true, // Ajusta la posición de la etiqueta
              }}
              fullWidth
            />
            <TextField
              error={errorCustomMembership}
              name="planMensualMonto"
              label="Monto del Plan"
              value={formState?.planMensualMonto}
              onChange={onInputChange}
              style={textFieldStyle}
              fullWidth
              type='number'
              helperText={errorCustomMembership ? 'No se encontró un plan de membresía personalizado. Por favor, cree uno en "Mi empresa" -> "Definiciones de precios".' : ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              error={errorField?.pagoDeInscripcion?.error === true}
              name="pagoDeInscripcion"
              label="Pago de inscripción"
              value={formState?.pagoDeInscripcion}
              onChange={onInputChange}
              style={textFieldStyle}
              helperText={errorField?.pagoDeInscripcion?.textMessage}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
            <FormControl style={selectStyle} fullWidth>
              <InputLabel id="activarPlan-label">Activar Plan de suscripción</InputLabel>
              <Select
                labelId="activarPlan-label"
                name="planStatusActivo"
                value={formState?.planStatusActivo}
                onChange={handleSelectChange}
              >
                <MenuItem value="true">Si</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={<Checkbox checked={applyPromotion} onChange={handlePromotionChange} />}
              label="Aplicar promoción"
              style={{ marginTop: '20px' }}
            />
            {applyPromotion && (
              <FormControl fullWidth required style={selectStyle}>
                <InputLabel id="promotion-label">Promoción</InputLabel>
                <Select
                  labelId="promotion-label"
                  name="promotion"
                  value={formState.promotion as any}
                  onChange={handlerSelectedPromotion}
                >
                  {promotions
                    .filter((promotion) => {
                      const expirationDate = DateTime.fromISO(promotion?.expirationDate as string);
                      return expirationDate > now;
                    })
                    .map((promotion) => (
                      <MenuItem key={promotion._id} value={promotion._id}>
                        {promotion.name} ({promotion.discount}%)
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            <Typography variant="h6" align="center" style={{ marginTop: '20px' }}>
              Total: ${totalConDescuento.toFixed(2)}
            </Typography>
          </div>
        )}
        {activeStep === 3 && (
          <div style={{ marginTop: '10px' }}>
            <Typography variant="h6">Resumen del Formulario</Typography>
            <div style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px', marginBottom: '20px', width: '100%' }}>
              <Typography key="nombre" variant="body1">
                {`Nombre: ${formState?.nombre}`}
              </Typography>
              <Typography key="apellidoPaterno" variant="body1">
                {`Apellido Paterno: ${formState?.apellidoPaterno}`}
              </Typography>
              <Typography key="apellidoMaterno" variant="body1">
                {`Apellido Materno: ${formState?.apellidoMaterno}`}
              </Typography>
              <Typography key="email" variant="body1">
                {`Email: ${(fieldFormatError) ? <Chip color='error' label={error} /> : formState?.email}`}
              </Typography>
              <Typography key="fechaNacimiento" variant="body1">
                {`Fecha de Nacimiento: ${formState?.fechaNacimiento}`}
              </Typography>
              <Typography key="direccion" variant="body1">
                {`Dirección: ${formState?.direccion}`}
              </Typography>
              <Typography key="planMensualTipo" variant="body1">
                {`Tipo de plan: ${formState?.planMensualTipo}`}
              </Typography>
              <Typography key="planStatusActivo" variant="body1">
                {`Estado del plan: ${(formState?.planStatusActivo !== 'false') ? 'ACTIVO' : <Chip color='error' label='ATENCIÓN , FALTA ACTIVAR EL PLAN EN CAMPO ACTIVAR PLAN DE SUSCRIPCIÓN!!!' />}`}
              </Typography>
              <Typography key="planMensualMonto" variant="body1">
                {`Monto del Plan: ${formState?.planMensualMonto}`}
              </Typography>
              <Typography key="pagoDeInscripcion" variant="body1">
                {`Pago único de inscripción: ${formState?.pagoDeInscripcion}`}
              </Typography>
              <Typography key="role" variant="body1">
                {`Role: ${formState?.role}`}
              </Typography>
              <Typography key="phoneNumber" variant="body1">
                {`Número de teléfono: ${formState?.phoneNumber}`}
              </Typography>
              <Typography key="fechaInicioPlan" variant="body1">
                {`Fecha de inicio del plan: ${formState?.fechaInicioPlan}`}
              </Typography>
            </div>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {activeStep !== 0 && (
            <Button
              variant="contained"
              color="inherit"
              style={buttonStyle}
              onClick={handleBackStep}
            >
              Atrás
            </Button>
          )}
          {activeStep < steps.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={handleNextStep}
              disabled={gralError.includes(true)}
            >
              Siguiente
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={handleSubmit}
              // disabled={(formState.planStatusActivo==='false' || fieldFormatError || error) && true}
              disabled={`${formState?.planStatusActivo === 'false' || fieldFormatError || error}`}
            >
              Enviar
            </Button>)
          }
        </div>
        {
          /** cliente */
          (clientsHandler.status === 'un-register-user') && <SnackBar severity='error' errorMsg={clientsHandler?.BackendErrorMessage}></SnackBar>
        }
        {
          /** cliente */
          (clientsHandler.status === 'registering') && <SnackBar severity='info' errorMsg='Registrando cliente...'></SnackBar>
        }
        {
          /** cliente */
          (clientsHandler.status === 'user-registered') && <SnackBar severity='success' errorMsg='Cliente registrado éxitosamente !!!'></SnackBar>
        }
      </form>
    </Paper>
  );
};
