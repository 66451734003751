import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { useEffect } from "react";
import { getExpenseRecordsThunk } from "../../store/expenses";

export const useHandlerExpense =()=>{
    const expenseList = useSelector( ( state : RootState)=> state?.getExpenses?.expense);
    
    useEffect(()=>{        
        expenseList?.length === 0 && getExpenseRecordsThunk()  
    },[ expenseList?.length ]);

    const findById =( expenseId : string )=>{
        if(!expenseId)return null;

        return expenseList.find((expense) => expense?._id === expenseId) || null;
    }
    return {
        findById
    }
}