export const developmentConfig = {
    REACT_APP_PUBLISHABLE_STRIPE_KEY : process.env.REACT_APP_PUBLISHABLE_STRIPE_KEY,
    REACT_APP_DEVELOPMENT_SERVER : process.env.REACT_APP_DEVELOPMENT_SERVER,
    REACT_APP_DEV_VOLCANOTECH_URL : process.env.REACT_APP_DEV_VOLCANOTECH_URL,
    MODAL_TYPE_ADD_USER : 'addUser',
    MODAL_TYPE_CREATE_EVENT : 'createEvent',
    MODAL_TYPE_CHANGED_PASSWORD : 'changePassword',
    MODAL_TYPE_EDIT_USER : 'editUser',
    MODAL_TYPE_EDIT_PRODUCT: 'editProduct',
    MODAL_TYPE_CREATE_PRODUCT: 'createProduct',
    MODAL_CREATE_CATEGORY : 'createCategory',
    AllOWED_MODAL_TYPE : ['createEvent','addUser' ,'createCategory'],
    INSCRIPTION_PAYMENT : 0 ,
    CURRENCY : "MXN",
    MONTHLY_PLAN : "MENSUAL",
    SIX_MONTHLY_PLAN : "SEMESTRAL",
    ANUAL_PLAN : "ANUAL",
    PLAN_BASICO : "BASICO",
    PLAN_ESENCIAL : "ESENCIAL",
    PLAN_ELITE : "ELITE",
    USER_BASICO_COST : 199,
    USER_ESENCIAL_COST : 770,
    USER_ELITE_COST : 1400,
    REQUEST_ORIGIN_REGISTER : 'registrar',
    REQUEST_ORIGIN_RENEW : "renewPlan"
}