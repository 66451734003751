import axios, { AxiosResponse } from 'axios';
import { config } from '../../config';
import { POST_PRODUCT } from '../../interfaces/interfaces';
import { stagingController } from '../../utils/stagingController';
import { developmentConfig } from '../../developmentConfig';

let host = stagingController()

export const getProductosProvider = async ()=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost)? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/producto/all`, { headers })
        .then((response:AxiosResponse) => {            
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{                    
            return {
                ok: false,
                error : error?.response?.data?.msg
            };
            
        }) 
}

export const getProductByIdProvider = async (uid: string) => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.get(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/producto/${uid}`, { headers })
        .then((response: AxiosResponse) => {
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) => {                        
            return {
                ok: false,
                error: error?.response?.data?.msg
            };

        })

}

export const createProductProvider = async (formState : POST_PRODUCT)=>{
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    const data = {
        ...formState
    }
    return await axios.post(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/producto/create`, JSON.stringify(data),{ headers })
        .then((response:AxiosResponse) => {            
            const data = response.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) =>{
            if (error?.response && error?.response?.data && error?.response?.data?.errors) {
                const validationErrors = error?.response?.data?.errors;
                const categoryError = validationErrors.find((error : any) => error.path === 'categoria');
    
                if (categoryError) {
                    return {
                        ok: false,
                        error: categoryError?.msg
                    };
                }
            }
            return {
                ok: false,
                error : error?.response?.data?.msg
            };
            
        }) 
}

export const deleteProductsProvider = async (uid: string) => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json',
    };
    return await axios.delete(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/producto/${uid}`, { headers })
        .then((response: AxiosResponse) => {            
            const data = response?.data;
            return {
                ok: true,
                data
            };
        })
        .catch((error) => {                        
            return {
                ok: false,
                error: error?.response?.data?.error
            };

        })
}
export const updateProductProvider = async (formState :any, uidProduct :string|null) => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json'
    };

    const data = {
        ...formState
    }
    return await axios.put(`${(host?.localhost)?developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER}/api/producto/${uidProduct}`, JSON.stringify(data), {
        headers
    })
    .then((response: AxiosResponse) => {        
        const data = response?.data;
        return {
            ok: true,
            data
        };
    })
    .catch((error) => {        
        let msg = error?.response?.data
        if( msg.msg ){
            return {
                ok: false,
                error: msg.msg
            };
        }else if( msg.error ){
            return {
                ok: false,
                error : msg.error
            }
        }
    })
}

export const sellProductProvider = async (formState: any, amount: number) => {
    let xToken = localStorage.getItem('token');
    const headers = {
        'x-token': xToken,
        'Content-Type': 'application/json'
    };

    const data = {
        ...formState,
        amount
    }

    return await axios.post(`${(host?.localhost) ? developmentConfig?.REACT_APP_DEVELOPMENT_SERVER : config?.REACT_APP_PRODUCTIONSERVER }/billing/salesRegister`, JSON.stringify(data), {
        headers
    })
    .then((response: AxiosResponse) => {        
        const data = response?.data;
        return {
            ok: true,
            data
        };
    })
    .catch((error) => {        
        return {
            ok: false,
            error: error?.response?.data?.msg
        };
    })
}