import { Box, Button, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';

import type { CustomPromotionsDefinitioonsProps } from '../interfaces/interfaces';

import { formatText } from '../utils/formatText';
import { getAllProductosThunk } from '../store/productos';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../store/modal';
import { config } from '../config';
import { useEffect, useState } from 'react';
import { getAllPromotionsThunk } from '../store/usuarios';
import { CustomPromotionsCard } from './CustomPromotionsCard';
import { CustomPromotionModal } from './CustomPromotionsModal';
import { CustomPromotionEditModal } from './CustomPromotionEditModal';


export const CustomPromotions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const promotions = useSelector((state: RootState) => state?.getPromotion?.promotions)
  const deleteIt = useSelector((state: RootState) => state?.deletePromotion?.status)
  const createIt = useSelector((state: RootState) => state?.createPromotion?.status);
  const updateIt = useSelector((state: RootState) => state?.editPromotion?.status);

  const handleAdd = () => {
    dispatch({
      type: openModal.type,
      payload: config?.MODAL_PROMOTION_DEFINITION
    })
  }

  const [idEdit, setIdEdit ] = useState<string>('')
  
  useEffect(() =>{
    dispatch(getAllPromotionsThunk());
  }, [promotions?.length === 0, dispatch , deleteIt === 'success', createIt === 'success',updateIt === 'success'])

  
  return (
    <Box sx={ { display: 'flex', flexDirection: 'column', p: 3 }}>
      <Box sx={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 } }>
        <Typography variant="h5" component="div" color="primary">Definicion de promociones</Typography>
        <Button
          variant="outlined"
          color="primary"
          startIcon={ <AddIcon /> }
          onClick={ handleAdd }
        >
          Añadir
        </Button>
      </Box>
      <CustomPromotionModal modalType={ config?.MODAL_PROMOTION_DEFINITION } />
      <CustomPromotionEditModal modalType={ config?.MODAL_EDIT_PROMOTION_DEFINITION } idEdit={ idEdit } />

      <CustomPromotionsCard custompromotions = { promotions } setIdEdit ={setIdEdit} />
      
    </Box>
  )
}
