import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Box, TextField, Button, Modal } from '@mui/material';

import { useForm } from '../customhooks';
import { AppDispatch, RootState } from '../store';
import { closeModal } from '../store/modal';
import { SnackBar } from '../atoms/SnackBar';
import { getTimeZoneFromUser } from '../utils/timeZoneParser';
import { POST_EVENTO_BASICO } from '../interfaces/interfaces';
import { createEventThunk } from '../store/eventos/thunk';
import { config } from '../config';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

const btnStyle = {
  width: '100%'
}

export const FormEventos = React.memo(() => {
  //global store 
  const open = useSelector((state: RootState) => state.modal.openModal);
  const modaltype = useSelector((state: RootState) => state.modal.modalType);
  const eventHandler = useSelector((state: RootState) => state.postEvent );
  const dispatch = useDispatch<AppDispatch>();
  //usr time zone util 
  const timeZoneData = getTimeZoneFromUser()
  useEffect(() => {

  }, [open]);

  const handleClose = () => {
    dispatch(closeModal());
  };
  const { onInputChange, formState, onResetForm } = useForm({
    nombre: "",
    descripcion: "",
    fecha_inicio: "",
    fecha_fin: "",
    cupo_maximo: "",
    timeZone: {
      identifier: timeZoneData.userTimeZone,
      offset: timeZoneData.userOffset
    }
  })

  const handleSubmit = async () => {
    const productData: POST_EVENTO_BASICO = {
      nombre: formState.nombre as string,
      descripcion: formState.descripcion as string,
      fecha_inicio: formState.fecha_inicio as string,
      fecha_fin: formState.fecha_fin as string,
      cupo_maximo: formState.cupo_maximo as number | any,
      timeZone: formState.timeZone as number | string | any
    };
    const success = await dispatch(createEventThunk(productData));
    if (success?.ok === true) {
      onResetForm()
      handleClose();
    }
  };
  return (
    <>
      {
        (modaltype === config.MODAL_TYPE_CREATE_EVENT && config.AllOWED_MODAL_TYPE.includes(config.MODAL_TYPE_CREATE_EVENT)) &&
        <Modal open={open} onClose={handleClose} aria-labelledby="Crear evento" aria-describedby="añade un evento ">
          <Box sx={style}>
            <TextField
              label="Nombre del evento"
              name="nombre"
              value={formState.nombre}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Descripción"
              name="descripcion"
              value={formState.descripcion}
              onChange={onInputChange}
              rows={4}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Fecha de inicio"
              name="fecha_inicio"
              value={formState.fecha_inicio}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              type="date"
              required
              InputLabelProps={{
                shrink: true, // Ajusta la posición de la etiqueta
              }}
            />
            <TextField
              label="Fecha fin"
              name="fecha_fin"
              value={formState.fecha_fin}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              type="date"
              required
              InputLabelProps={{
                shrink: true, // Ajusta la posición de la etiqueta
              }}
            />
            <TextField
              label="Cupo máximo"
              name="cupo_maximo"
              type="number"
              value={formState.cupo_maximo}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              required
            />
            <Button variant="contained" color="primary" onClick={handleSubmit} sx={btnStyle}>
              Crear evento
            </Button>
          </Box>
        </Modal>
      }
      {
        (eventHandler.status === 'in-progress') && <SnackBar severity='info' errorMsg='Creando evento , apreciamos tu paciencia...'></SnackBar>
      }
      {
        /** products messages */
        (eventHandler.status === 'failed') && <SnackBar severity='error' errorMsg={eventHandler.BackendErrorMessage}></SnackBar>
      }
    </>
  )
})
